import React, { PureComponent } from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import LocalizedString from 'components/LocalizedString';
import localize from 'components/LocalizedString/Localize';

import * as cssVariable from 'cssVariables';
import formatNumber from 'lib/formatNumber';

import Slider from './Slider';
import Histogram from './Histogram';

const OBJECTS_PER_BLOCK = 60;
const BLOCK_SIZE = 7;
const BLOCK_MARGIN = 3;
const THUMB_WIDTH = 20;
const TRACK_WIDTH = 4;

const SLIDER_SIZES = {
  thumb: {
    height: THUMB_WIDTH,
    width: THUMB_WIDTH,
    margin: BLOCK_MARGIN,
  },
  track: {
    width: TRACK_WIDTH,
    step: BLOCK_SIZE,
  },
};

const BLOCK_SIZES = {
  height: BLOCK_SIZE,
  margin: BLOCK_MARGIN,
};

export default class HistogramFilter extends PureComponent {
  static contextTypes = { lang: PropTypes.string };
  onSliderChange = ({ top, bottom, isPreviewState }) => {
    const { periods, setDateFilter } = this.props;
    const to = periods[top].to;
    const from = periods[bottom].from;

    setDateFilter({ isPreviewState, from, to });
  };

  render() {
    const {
      periods,
      from,
      to,
      fromYear,
      toYear,
      isEmptyHistogram,
    } = this.props;

    if (isEmptyHistogram) {
      return null;
    }

    return (
      <Container>
        <Title className="h2">
          <LocalizedString string="filterPeriod" />
        </Title>
        <Period>{formatNumber(toYear)}</Period>
        <Wrapper>
          <Slider
            top={to}
            bottom={from}
            periods={periods}
            onChange={this.onSliderChange}
            sizes={SLIDER_SIZES}
          />
          <HistogramContainer>
            <Histogram
              from={fromYear}
              to={toYear}
              periods={periods}
              blockSize={BLOCK_SIZES}
              objectsPerBlock={OBJECTS_PER_BLOCK}
            />
          </HistogramContainer>
        </Wrapper>
        <Period>
          {formatNumber(fromYear, localize('beforeChrist', this.context.lang))}
        </Period>
      </Container>
    );
  }
}

const CONTAINER_MARGIN = 36;

const Container = glamorous.section({
  margin: '20px 0',
});

const Wrapper = glamorous.div({
  display: 'flex',
});

const HistogramContainer = glamorous.div({
  marginTop: BLOCK_SIZE + BLOCK_MARGIN,
  marginBottom: BLOCK_SIZE + BLOCK_MARGIN,
  marginLeft: CONTAINER_MARGIN,
});

const Title = glamorous.h3({
  fontFamily: cssVariable.fontFamilies.maisonMono,
  marginLeft: CONTAINER_MARGIN,
  color: 'white',
  fontWeight: cssVariable.fontWeights.bold,
  textTransform: 'uppercase',
});

const Period = glamorous.p({
  margin: 0,
  padding: 0,
  fontFamily: cssVariable.fontFamilies.maisonMono,
  color: 'white',
  marginLeft: CONTAINER_MARGIN,
  position: 'relative',
  fontSize: 15,
});
