import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LogoSVG from 'components/Header/logo.svg';
import Container from 'components/util/Container';
import Announcements from 'components/util/Announcements';
import LocalizedString from 'components/LocalizedString';
import localize from 'components/LocalizedString/Localize';
import Icon from 'components/Icon';
import * as cssVariable from 'cssVariables';
import responsive from 'components/hoc/responsive';
import history, { routeUtils } from 'lib/history';

const BASE_STATICS_URL = process.env.REACT_APP_BASE_STATICS_URL;
const MARGIN_DESKTOP = 40;
const MARGIN_MOBILE = 20;

export default class ErrorBoundary extends Component {
  static contextTypes = { lang: PropTypes.string };

  state = { hasError: false, announcementMessage: null };

  componentDidCatch(error: Error, info: Object) {
    console.error('Boundary error', error);
    console.error('Boundary info', info);
    this.setState({ hasError: true });

    const message = localize('somethingWentWrong', this.context.lang);
    this.setState({ announcementMessage: message });
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <Container id="content-wrapper" role="main">
        <LogoContainer href="/" id="main-logo">
          <img src={LogoSVG} alt="Below The Surface" />
        </LogoContainer>
        <Announcements
          level="assertive"
          message={this.state.announcementMessage}
        />
        <Payload>
          <Text>
            <Title>
              <LocalizedString string="somethingWentWrong" />
            </Title>
            <div>
              <BlackButton
                onClick={() => window.location.reload(true)}
                className="icon-hover-right"
              >
                <span>
                  <LocalizedString string="reloadPage" />
                </span>
                <Icon iconName="arrowRight" />
              </BlackButton>
              <WhiteButton
                onClick={() => history.push(routeUtils.homepage())}
                className="icon-hover-right"
              >
                <span>
                  <LocalizedString string="gotoHomepage" />
                </span>
                <Icon iconName="arrowRight" color="black" />
              </WhiteButton>
            </div>
          </Text>
          <ImageBox>
            <Image
              src={`${BASE_STATICS_URL}/vondst/600/NZD1.00048FAU017-02(01).png`}
            />
          </ImageBox>
        </Payload>
      </Container>
    );
  }
}

const LogoContainer = responsive.a(
  {
    display: 'block',
    width: 360,
    marginTop: MARGIN_DESKTOP,
    marginBottom: MARGIN_DESKTOP,
    position: 'relative',
    zIndex: 9,
    '& > img': {
      width: '100%',
      height: 'auto,',
    },
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      width: 191,
      marginTop: MARGIN_MOBILE,
      marginBottom: MARGIN_MOBILE,
    },
  ({ browser }) =>
    browser.fits.medium && {
      zIndex: 3,
    },
  ({ hide }) =>
    hide && {
      opacity: 0,
      transition: 'opacity .2s ease-out, width 0s .2s, height 0s .2s',
      width: 0,
      height: 0,
      pointerEvents: 'none',
    },
);

const Title = responsive.h1(
  {
    ...cssVariable.title,
    lineHeight: 1.15,
    fontFamily: cssVariable.fontFamilies.maisonNeue,
    maxWidth: 660,
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      fontSize: 42,
    },
  ({ browser }) =>
    browser.fits.smallMedium && {
      maxWidth: 'auto',
    },
);

const Payload = responsive.div(
  {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  ({ browser }) =>
    browser.fits.smallMedium && {
      flexDirection: 'column',
    },
);

const Text = responsive.div(
  {
    flex: '0 1 auto',
  },
  ({ browser }) =>
    browser.fits.smallMedium && {
      order: 1,
    },
);

const ImageBox = responsive.div(
  {
    flex: '1 1 auto',
    maxWidth: 660,
    marginLeft: 30,
  },
  ({ browser }) =>
    browser.fits.smallMedium && {
      maxWidth: 'auto',
    },
);

const Image = responsive.img({
  width: '100%',
});

const ButtonStyle = [
  ({ background, color }) => ({
    background,
    color,
    border: 'none',
    fontWeight: cssVariable.fontWeights.bold,
    pointerEvents: 'all',
    height: 50,
    fontSize: 18,
    padding: '0 24px',
    marginRight: 24,
    '> span': {
      display: 'inline-block',
      paddingRight: 10,
    },
    '> svg': {
      height: 18,
      verticalAlign: 'middle',
    },
  }),
  ({ browser }) =>
    browser.fits.extraSmall && {
      padding: '0 12px',
    },
];

const BlackButton = responsive.button(...ButtonStyle, {
  background: 'black',
  color: 'white',
});

const WhiteButton = responsive.button(...ButtonStyle, {
  background: 'white',
  color: 'black',
});
