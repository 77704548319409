import React, { Component } from 'react';
import glamorous from 'glamorous';

import Icon from 'components/Icon';

import history, { routeUtils, stringifyQueryParams } from 'lib/history';
import * as cssVariable from 'cssVariables';

export default class ObjectNavigator extends Component {
  linkToObject = index => {
    const vondstnummer = this.props.objects.hits[index].vondstnummer;
    const query = this.props.query;
    query.index = index;

    history.replace({
      pathname: routeUtils.objectDetail({ vondstnummer }),
      search: stringifyQueryParams(query),
    });
  };

  isFirstObject() {
    return this.props.objects.hits[this.props.index - 1] === undefined;
  }

  isLastObject() {
    return this.props.objects.hits[this.props.index + 1] === undefined;
  }

  nextObject = () => {
    const newIndex = this.props.index + 1;
    if (!this.isLastObject()) {
      this.linkToObject(newIndex);
    }
  };

  previousObject = () => {
    const newIndex = this.props.index - 1;
    if (!this.isFirstObject()) {
      this.linkToObject(newIndex);
    }
  };

  render() {
    return (
      <Container>
        <Button
          disabled={this.isFirstObject()}
          onClick={this.previousObject}
          className="icon-hover-left"
        >
          <Icon
            iconName="arrowLeft"
            title="iconTitlePreviousItem"
            iconSize={cssVariable.iconSizes.medium}
          />
        </Button>
        <Button
          disabled={this.isLastObject()}
          onClick={this.nextObject}
          className="icon-hover-right"
        >
          <Icon
            iconName="arrowRight"
            title="iconTitleNextItem"
            iconSize={cssVariable.iconSizes.medium}
          />
        </Button>
      </Container>
    );
  }
}

const Container = glamorous.div({
  display: 'flex',
  ':last-child > button': {
    marginLeft: 1,
  },
});

const Button = glamorous.button({
  flex: '0 1 auto',
  backgroundColor: cssVariable.colorBlack,
  width: 50,
  height: 50,
  '> svg': {
    verticalAlign: 'middle',
  },
  ':disabled': {
    opacity: '0.3',
  },
});
