import { connect } from 'react-redux';
import { setQuery } from 'actions/search';

import ObjectSpecialDetailList from './ObjectSpecialDetailList';

function mapDispatchToProps(dispatch) {
  return {
    setQuery(q) {
      const action = setQuery(q);
      return dispatch(action);
    },
  };
}

export default connect(null, mapDispatchToProps)(ObjectSpecialDetailList);
