import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import objectFitImages from 'object-fit-images';
import glamorous from 'glamorous';
import _ from 'lodash';

import { routeUtils } from 'lib/history';
import * as cssVariable from 'cssVariables';

import LocalizedString from 'components/LocalizedString';
import Header from 'components/Header';
import Container from 'components/util/Container';
import Icon from 'components/Icon';
import responsive from 'components/hoc/responsive';
import Showcase from 'components/Showcase/Showcase';
import LogoFooter from 'components/LogoFooter';

const BASE_STATICS_URL = process.env.REACT_APP_BASE_STATICS_URL;

export default class CanvasOverview extends Component {
  static contextTypes = { lang: PropTypes.string };

  state = {
    isLoading: false,
  };

  componentDidMount() {
    // This fixes issues for IE11
    // Polyfill needs to be initialized after components are mounted
    objectFitImages();
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentPageToken !== prevProps.currentPageToken) {
      setTimeout(() => {
        this.focusOnH1();
      });
    }
  }

  focusOnH1() {
    const el = document.getElementById('canvasOverviewHeaderAll');
    el.scrollIntoView();
    el.focus();
  }

  isPrevPageAvailable() {
    return this.props.currentPageToken != null;
  }

  loadNextPage = () => {
    const nextPageToken = encodeURIComponent(
      this.props.mostRecent.nextPageToken,
    );
    const limit = 10 /* rows */ * this.props.columnCount;
    this.focusOnH1();
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?pageToken=${nextPageToken}&offset=${this.props.offset +
        limit}&limit=${limit}`,
    });
  };

  loadPrevPage = () => {
    this.focusOnH1();
    this.props.history.goBack();
  };

  renderFeatured() {
    const featuredAmount = this.props.columnCount;
    const featuredItems = this.props.featured;
    const randomFeaturedItems = _.sampleSize(featuredItems, featuredAmount);
    return randomFeaturedItems.map(this.renderItem);
  }

  render() {
    const { isLoading } = this.state;
    const {
      featured,
      mostRecent: { items: mostRecentItems, nextPageToken, count },
      offset,
      columnCount,
    } = this.props;
    const nextLimit = columnCount * 10;
    const currentLimit = this.props.limit || nextLimit;
    const canvasPreviewItem = (featured || mostRecentItems)[0];
    const total = count + offset;

    return (
      <Wrapper>
        <Header />
        <Container role="main">
          <Hero>
            <Showcase
              micrioId="aIvVO"
              style={MicrioStyling}
              disableControl
              autoplayTour
              hideHeader
              disableTour
              inCanvasOverview
            />
            <HeroCallToAction>
              <HeroCallToActionHeading>
                <LocalizedString string="HeaderCallToActionHeading" />
              </HeroCallToActionHeading>
              <HeroCallToActionIntro>
                <LocalizedString string="HeaderCallToActionIntro" />
              </HeroCallToActionIntro>
              <WhiteButton href={routeUtils.showcase()}>
                <LocalizedString string="HeaderCallToActionButton" />
                <Icon color={cssVariable.colorBlack} iconName="arrowRight" />
              </WhiteButton>
            </HeroCallToAction>
          </Hero>
          <InlineTitle>
            <Title>
              <LocalizedString string="canvasOverviewHeaderMostPopulair" />
            </Title>
            <BlackButton href={routeUtils.draftSet()}>
              <LocalizedString string="canvasOverviewButtonMostPopulair" />
              <Icon iconName="arrowRight" />
            </BlackButton>
          </InlineTitle>

          <ListDisplays>{this.renderFeatured()}</ListDisplays>

          <CanvasPreviewWrapper>
            <CanvasPreviewEditor>
              <CanvasPreviewText>
                <LocalizedString string="canvasOverviewHeaderDIY" />
              </CanvasPreviewText>
              {canvasPreviewItem && (
                <div>
                  <CanvasPreviewImage
                    src={imgUrl(canvasPreviewItem.id, canvasPreviewItem.stage)}
                    alt=""
                    role="presentation"
                  />
                </div>
              )}
            </CanvasPreviewEditor>
            <BlackButton href={routeUtils.draftSet()}>
              <LocalizedString string="canvasOverviewButtonDIY" />
              <Icon
                iconName="arrowRight"
                iconSize={cssVariable.iconSizes.small}
              />
            </BlackButton>
          </CanvasPreviewWrapper>

          <InlineTitle>
            <Title id="canvasOverviewHeaderAll" tabIndex="0">
              <LocalizedString string="canvasOverviewHeaderAll" />{' '}
            </Title>
          </InlineTitle>

          <ListDisplays>{mostRecentItems.map(this.renderItem)}</ListDisplays>
          <PageNav>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <div>
                <p aria-live="assertive" aria-atomic="true">
                  <LocalizedString string="displays" /> {offset + 1}{' '}
                  <LocalizedString string="canvasOverviewPagesTill" />{' '}
                  {offset + currentLimit}{' '}
                  <LocalizedString string="canvasOverviewPagesOf" /> {total}
                </p>

                {this.isPrevPageAvailable() && (
                  <RealButton onClick={this.loadPrevPage}>
                    <LocalizedString string="canvasOverviewPrevPage" />{' '}
                    {nextLimit}
                  </RealButton>
                )}
                {nextPageToken && (
                  <RealButton onClick={this.loadNextPage}>
                    <LocalizedString string="canvasOverviewNextPage" />{' '}
                    {nextLimit}
                  </RealButton>
                )}
              </div>
            )}
          </PageNav>
        </Container>
        <LogoFooter />
      </Wrapper>
    );
  }

  renderItem(item) {
    return (
      <ListItem key={item.id}>
        <ListItemLink to={routeUtils.setDetail({ id: item.id })}>
          <ListItemText>{item.title}</ListItemText>
          <ListItemImage
            key={item.id}
            src={imgUrl(item.id, item.stage)}
            alt=""
          />
        </ListItemLink>
      </ListItem>
    );
  }
}

function imgUrl(vondstnummer, stage) {
  return `${BASE_STATICS_URL}/canvas/600/${stage}-${vondstnummer}.png`;
}

const MicrioStyling = {
  width: '100%',
  height: '100%',
  '& menu, & controls': {
    display: 'none !important',
  },
};

const Wrapper = glamorous.div();

const Hero = responsive.div({
  height: 550,
  width: '100%',
  backgroundColor: cssVariable.colorLightGray,
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
});

const HeroCallToAction = responsive.div(
  {
    width: '50vw',
    maxWidth: 750,
    padding: 40,
    backgroundColor: cssVariable.colorBlack,
    color: cssVariable.colorWhite,
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
  ({ browser }) =>
    browser.fits.large && {
      width: '100%',
    },
  ({ browser }) =>
    browser.fits.medium && {
      maxWidth: 628,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      padding: 16,
    },
);

const HeroCallToActionHeading = responsive.h1(
  {
    textTransform: 'uppercase',
    fontSize: 52,
    margin: 0,
    lineHeight: 1.15,
    marginBottom: 16,
    padding: 0,
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      fontSize: 30,
      marginBottom: 8,
    },
);

const HeroCallToActionIntro = responsive.p(
  {
    fontSize: 18,
    fontFamily: cssVariable.fontFamilies.maisonNeue,
    margin: '0 0 25px 0',
    padding: 0,
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      fontSize: 15,
    },
);

const InlineTitle = responsive.div(
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 24,
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
);

const Title = responsive.h1(
  {
    textTransform: 'uppercase',
    fontSize: 26,
    marginBottom: 0,
    lineHeight: '1.35',
    marginTop: 80,
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      fontSize: 30,
      marginBottom: 5,
    },
);

const ListDisplays = responsive.ul(
  {
    listStyleType: 'none',
    margin: '0 0 64px 0',
    padding: 0,
    width: '100%',
    display: 'grid',
    gridAutoColumns: 'minmax(1fr, auto)',
    gridGap: 1,
    columnCount: '3',
    columnGap: 1,
    gridTemplateColumns: `repeat(5, 1fr)`,
  },
  ({ browser }) =>
    browser.greaterThan.extremelyLarge && {
      gridTemplateColumns: `repeat(6, 1fr)`,
    },
  ({ browser }) =>
    browser.fits.extraLarge && {
      gridTemplateColumns: `repeat(4, 1fr)`,
    },
  ({ browser }) =>
    browser.fits.medium && {
      gridTemplateColumns: `repeat(3, 1fr)`,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      gridTemplateColumns: '1fr',
    },
);

const ListItemLink = responsive(NavLink, {
  rootEl: 'a',
  forwardProps: ['to', 'exact'],
})({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'flex-end',
  transform: 'translateX(0%)',
  '&:hover > p': {
    transform: 'translateX(5%)',
  },
});

const ListItemText = responsive.p(
  {
    padding: '16px 20px',
    margin: 0,
    fontSize: 18,
    lineHeight: 1.4,
    fontWeight: cssVariable.fontWeights.bold,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    zIndex: 1,
    transition: 'transform 0.15s linear',
    position: 'relative',
    '&::after': {
      content: '" "',
      display: 'inline-block',
      width: 0,
    },
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      fontSize: 16,
      lineHeight: 1.2,
    },
);

const ListItemImage = glamorous.img({
  width: '100%',
  height: '100%',
  minWidth: '100%',
  minHeight: '100%',
  objectFit: 'contain',
  fontFamily: '"object-fit: contain"', // Dirty IE11 polyfill fix
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const ListItem = glamorous.li({
  // HACK
  // Omdat deze li's een hoogte met `padding-top` hebben en dat blijkt niet te werken icm. flex/grid.
  // `display: table` fixt het
  '@supports (-moz-appearance:none)': {
    display: 'table',
  },
  // END-OF-HACK
  display: 'flex',
  background: cssVariable.colorLightGray,
  margin: 0,
  padding: 0,
  paddingTop: '100%',
  position: 'relative',
  '@media all and (-ms-high-contrast:none)': {
    marginBottom: 1,
  },
});

const CanvasPreviewWrapper = responsive.div(
  {
    background: cssVariable.colorLightGray,
    width: '100%',
    padding: 40,
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      padding: 24,
    },
);

const CanvasPreviewEditor = responsive.div(
  {
    display: 'flex',
    width: '100%',
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      flexDirection: 'column-reverse',
    },
);

const CanvasPreviewImage = glamorous.img({
  width: '100%',
  objectFit: 'contain',
  fontFamily: '"object-fit: contain"', // Dirty IE11 polyfill fix
});

const CanvasPreviewText = responsive.h2(
  {
    fontSize: 52,
    textTransform: 'uppercase',
    width: '50%',
    marginBottom: 160,
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      width: '100%',
      fontSize: 26,
      marginBottom: 24,
    },
);

const buttonStyle = {
  display: 'inline-block',
  border: 'none',
  fontWeight: cssVariable.fontWeights.bold,
  pointerEvents: 'all',
  height: 50,
  fontSize: 18,
  lineHeight: '50px',
  padding: '0 24px',
  '> svg': {
    height: 18,
    marginLeft: 16,
    verticalAlign: 'middle',
  },
};

const Button = glamorous.a(({ background, color }) => ({
  background,
  color,
  ...buttonStyle,
}));

const BlackButton = Button.withProps({
  background: 'black',
  color: 'white',
});

const WhiteButton = Button.withProps({
  background: 'white',
  color: 'black',
});

const RealButton = responsive.button(
  {
    background: 'black',
    color: 'white',
    '& + button': {
      marginLeft: 10,
    },
    ...buttonStyle,
  },
  ({ browser }) =>
    browser.fits.extremelySmall && {
      padding: '0 16px',
    },
);

const PageNav = responsive.div();
