import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import IdleTracker from 'idle-tracker';
import { Helmet } from 'react-helmet';

import Header from 'components/Header';
import Container from 'components/util/Container';
import './BookPage.css';
import responsive from '../hoc/responsive';
import * as cssVariable from '../../cssVariables';
import BookIntro from './BookIntro';
import { getCurrentParams } from '../../lib/history';
import { chapters } from './chapters';

const MICRIO_SCRIPT_URL = process.env.REACT_APP_MICRIO_SCRIPT_URL;

const initialChapter = {
  page: 0,
  number: '',
  title: '',
};

const findChapterByPage = (currentPage = 0, allChapters = chapters) => {
  // multiply by 2 because a photo contains 2 pages
  // subtract by 4 to include the book cover
  const currentBookIndex = currentPage * 2 - 4;
  return allChapters.find((chapter, index, array) => {
    if (index === 0) return currentBookIndex <= array[index + 1].page;
    if (index + 1 === array.length) return currentBookIndex >= chapter.page;
    return (
      currentBookIndex >= chapter.page &&
      currentBookIndex <= array[index + 1].page
    );
  });
};

export default class BookPage extends PureComponent {
  static contextTypes = {
    lang: PropTypes.string,
  };

  lang = getCurrentParams()['lang'] || 'nl';

  state = {
    isZoomedIn: false,
    currentPage: 0,
    currentChapter: initialChapter,
    hoverChapter: null,
    isIdle: false,
  };

  handleIdleStatus = ({ idle: isIdle }) => {
    this.setState({ isIdle });
  };

  idleTracker = new IdleTracker({
    timeout: 3000,
    onIdleCallback: this.handleIdleStatus,
  });

  handleMouseEvents = () => {
    const listItems = document.querySelectorAll('li.gallery');

    if (!listItems) return;

    listItems.forEach((item, index) => {
      const itemChapter = findChapterByPage(index, this.props.content.chapters);

      if (!itemChapter) return;

      // Show chapter on mouseenter
      item.addEventListener('mouseenter', () => {
        this.setState({ hoverChapter: itemChapter });
      });

      // Show active chapter on mouseleave
      item.addEventListener('mouseleave', () => {
        this.setState({ hoverChapter: null });
      });
    });
  };

  handleMicrioEvents = () => {
    const micrio = document.querySelector('micr-io');

    micrio.addEventListener('zoom', () => {
      this.setState({ isIdle: false });

      const scale = micrio.camera.getScale();
      const minScale = micrio.camera.getMinScale();
      // not using `micrio.camera.isZoomedIn()` because this triggers after scale is bigger than 1, and we want to set the state when scale is bigger than the minimum scale
      const isZoomedIn = scale > minScale;

      this.setState({ isZoomedIn });
    });

    micrio.addEventListener('gallery-show', ({ detail: currentPageIndex }) => {
      this.setState({ isIdle: false });

      this.setState({
        currentPage: currentPageIndex,
      });

      const currentChapter = findChapterByPage(
        currentPageIndex,
        this.props.content.chapters,
      );

      if (!currentChapter) return;

      this.setState({ currentChapter });
    });
  };

  async componentWillUnmount() {
    this.idleTracker.end();
  }

  async componentDidMount() {
    this.handleMicrioEvents();
    this.idleTracker.start();

    // wait for the Micrio gallery list items to render
    setTimeout(() => this.handleMouseEvents(), 500);
  }

  render() {
    const {
      content: { introTitle, introText, introButtonText },
    } = this.props;

    const { currentChapter, hoverChapter, isZoomedIn, isIdle } = this.state;

    return (
      <Wrapper>
        <Helmet>
          <script src={MICRIO_SCRIPT_URL} />
        </Helmet>
        <Header hideLogo />
        <ContentWrapper id="content-wrapper" role="main">
          <Container>
            <micr-io
              gallery="yaLPT,4386,3215;zYwPN;uPpOG;FATPH;PqYop;ClMan;oacjL;XCGYT;iXrzU;lnGxp;yKRfI;JkLfP;yssDP;XvExm;UXeZK;qakdS;nwlVH;bfXrl;oLEdq;zIETq;TDhAY;cJkeY;ZCFZa;yTQfc;UrpGA;NlbZA;ntgqI;jZonE;DMeXZ;OvZAY;jXSxk;PrqaR;KpUHO;LIUzg;zWwum;SnQZr;jEvyC;kiKYe;rNoNE;TyKig;EfwzK;GKvqY;fQcZt;qMXZB;AQQjf;FLbzF;uzdhY;VBcoD;SvvSS;YkNbx;JIioX;MeVct;WdZro;KGFGV;uSPak;UvbmA;XDRQy;lspyz;cyNMx;GUAek;aittl;qZRxp;nKXds;ExnkN;iAXCa;lvXqt;cbjrk;VZtyy;treqG;CSARL;Qplop;SBBqt;NxhAq;gkzYN;HLfHT;hpKbq;bHDwX;tipwi;ExXOk;MjZgp;fAvZm;KxkTR;ywfRp;FrhVD;xtZvU;nQrtv;kDKlT;lItUc;Dcgqg;HJNva;cozvu;PZXGw;HeBAA;zvsoc;XIQXa;JbXCI;bYZOE;OSLeD;gzNOe;GkgKt;YljZm;htqcE;ZVTBW;aJTSe;EjkOV;epYhf;OpUYt;FrTEg;efxge;cInue;DZOZd;DzrtF;gqYPY;ewZkK;GCykb;TwpaX;ANxPi;Nkgri;yOcLV;wTkir;nkUoD;nyizF;LnFjK;QPOAK;MPDhK;YscIb;JtFaT;sedgz;YETpH;hRrQU;QOxQK;mtOGV;Ojeqh;jLixd;SnBBX;uBGHk;GYaGg;OCWAF;EpWjH;IDLdm;votbT;vFaTF;QoHNn;lzQcS;hNCoA;RmJPw;OccoJ;GLyHT;PkurL;YmgAH;wBisX;lDoFa;WBLqi;xzMVp;knuXL;LdMRx;EbdYW;wERfe;RKQvs;igBsB;QYkXP;RQfwy;AWtAz;lnPJo;wzgEX;PyUWk;AOKeY;bkNYW;KYuCB;fdcaW;sczqE;Dqrxu;RQNVQ;oXtHZ;NfTiF;mfItv;YjdIo;zKDEF;kJhJw;NwWDF;npxUa;tjkJA;fePhi;zrGRw;FYWqq;juRBQ;ZggCJ;snQSG;xpryc;jVypi;muvYg;BnXxX;RNScb;OhAIg;mDaJg;aVGtB;XcAFD;IOSCH;fUKSi;voyGY;YyZeK;UJJsw;UZFkL;NTVlr;NUrUJ;dyrHy;opfXU;dXboR;agbSj;aePgX;wAioW;ZynSi;MnwUx;gwJGR;DPEzU;jxTkE;rdfYJ;zQnpB;GSyBz;wjzpi;JnMQo;dPhPj;jfrZY;DMerP;OBNxC;XHUYc;HPcSH;fmAfe;affpN;vrQXk;AuIyK;hwOOo;vaySD;uGSai;gHSWQ;Fdobg;iaFIz;uYlqd;bPAwf;sOiRn;jOjQb;VpJmh;EuzEl;kUfGq;eGAJl;tZasJ;ZrnpV;NFaFl;uxUsk;HgKtS;rtWdn;BNwNX;ySFeB;PEwFm;mxTaO;hXnXP;uZwds;dZqbk;GcyfS;DOojA;bqriF;mFGNS;RNOjK;atdDj;kLSKU;AqiDh;SvYmw;rFOEn;qaDWy;cyQdP;UTCOZ;wiLMq;kyMQW;HUirT;TZfID;kslxG;RwtHl;LXwtE;RXcDE;wOHEz;mcmCE;QItJR;OrmsI;bhaSP;klbSm;WgYEI;kNbJW;BLzVs;fDdeR;GGnMV;HJtMV;sSFWG;wWNaW;ZcXdj;jLwRN;nmhdE;Hnyaj;JAjne;onIsS;qszLk;dlAoR;zitPM;xflJm;tZXvO;gWxDo;SroeA;cuYiU"
              archive="eufRj.1665036845"
              switch
              lang={this.lang}
              controls={false}
              logo={false}
              zoom={isZoomedIn ? 'isZoomedIn' : 'isZoomedOut'}
              idle={isIdle ? 'isIdle' : 'isActive'}
            />
            {this.state.currentPage === 0 && (
              <BookIntro
                isVisible={!isZoomedIn}
                heading={introTitle}
                text={introText}
                buttonText={introButtonText}
              />
            )}
            <Chapter isVisible={!isZoomedIn && !isIdle}>
              {hoverChapter ? hoverChapter.number : currentChapter.number}{' '}
              {hoverChapter ? hoverChapter.title : currentChapter.title}
            </Chapter>
          </Container>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = glamorous.section();

const chaptersCssSelector = chapters
  .filter(({ number }) => number !== '')
  .reduce(
    (acc, { page }, index) =>
      `${acc}${index ? ', ' : ''}& micr-io li.gallery:nth-child(${Math.floor(
        // divide by 2 because a photo contains 2 pages
        // add 4 to include the book cover
        page / 2 + 4,
      )})`,
    '',
  );

const ContentWrapper = glamorous.div({
  [chaptersCssSelector]: {
    backgroundColor: 'black',
    height: '24px',
  },
});

const Chapter = responsive.h2(
  {
    fontFamily: cssVariable.fontFamilies.maisonMono,
    transition: 'opacity 0.2s ease-out',
    position: 'absolute',
    left: 70,
    bottom: 60,
  },
  ({ isVisible, browser }) => ({
    fontSize: browser.fits.extraSmall ? 16 : 26,
    opacity: isVisible ? 1 : 0,
  }),
  ({ browser }) =>
    browser.fits.medium && {
      left: 27,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      left: 17,
    },
);
