import { Component } from 'react';
import PropTypes from 'prop-types';

import localize from './Localize';

export default class LocalizedString extends Component {
  render() {
    return localize(
      this.props.string,
      this.context.lang,
      this.props.values || [],
    );
  }
}

LocalizedString.contextTypes = {
  lang: PropTypes.string,
};
