import React, { PureComponent } from 'react';
import glamorous from 'glamorous';

import FacetFilter from 'components/FacetFilter';
import Icon from 'components/Icon';
import LocalizedString from 'components/LocalizedString';

import * as cssVariable from 'cssVariables';
import responsive from 'components/hoc/responsive';

import uniqueId from 'lodash/uniqueId';

export default class Facet extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showFilters: props.filters.length > 1 };
  }

  componentWillMount() {
    const id = uniqueId('facet-filter-');
    this.setState({ id: id });
  }

  renderFilters() {
    const { filters } = this.props;

    return filters.map((filter, index) => (
      <FacetFilter
        key={filter.field}
        hasSelectedFilters={filters.length > 1}
        headerId={this.state.id}
        {...filter}
      />
    ));
  }

  toggleFilters = () => {
    this.setState({ showFilters: !this.state.showFilters });
  };

  handleKeyDown = e => {
    const buttons = [].slice.call(
      e.currentTarget.getElementsByTagName('button'),
    );

    const btnIndex = buttons.indexOf(e.target);

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (btnIndex < buttons.length - 1) buttons[btnIndex + 1].focus();
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (btnIndex > 0) buttons[btnIndex - 1].focus();
        break;
      case 'Escape':
        e.preventDefault();
        if (this.state.showFilters) {
          this.toggleFilters();
          buttons[0].focus();
        }
        break;
      case 'Home':
        e.preventDefault();
        if (this.state.showFilters) buttons[1].focus();
        break;
      case 'End':
        e.preventDefault();
        if (this.state.showFilters) buttons[buttons.length - 1].focus();
        break;
      default:
        break;
    }
  };

  render() {
    const { showFilters, id } = this.state;

    return (
      <Section onKeyDown={this.handleKeyDown}>
        <FacetHeader
          showFilters={showFilters}
          onClick={this.toggleFilters}
          aria-haspopup="listbox"
          aria-expanded={showFilters}
          id={id}
        >
          <Arrow>
            <Icon
              iconName="arrowRight"
              iconSize={cssVariable.iconSizes.medium}
            />
          </Arrow>
          <Title className="h2">
            <LocalizedString string={this.props.name} />
          </Title>
        </FacetHeader>
        <FacetFilters
          showFilters={showFilters}
          role="listbox"
          aria-labelledby={id}
        >
          {this.renderFilters()}
        </FacetFilters>
      </Section>
    );
  }
}

const Section = responsive.section(
  {
    marginTop: 36,
  },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      marginTop: 20,
    },
);

const FacetFilters = glamorous.div(
  {
    paddingLeft: 36,
    display: 'none',
    marginTop: 10,
    marginBottom: 40,
  },
  ({ showFilters }) =>
    showFilters && {
      display: 'block',
    },
);

const FacetHeader = responsive.button(
  {
    backgroundColor: cssVariable.colorBlack,
    background: 'none',
    border: 'none',
    padding: 0,
    '& > div': {
      transition: 'transform 0.2s linear',
      transform: 'rotate(0deg) translateX(0)',
    },
    ':hover> div': {
      transform: 'rotate(0deg) translateX(15%)',
    },
  },
  ({ showFilters }) =>
    showFilters && {
      '& > div': {
        transform: 'rotate(90deg) translateX(-10%)',
      },
      ':hover> div': {
        transform: 'rotate(90deg) translateX(5%)',
      },
    },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      marginBottom: 0,
    },
);

const Arrow = glamorous.div({
  display: 'inline-block',
  marginRight: 15,
});

const Title = glamorous.h3({
  color: 'white',
  display: 'inline-block',
  textTransform: 'uppercase',
  fontWeight: cssVariable.fontWeights.bold,
});
