import promiseContainer from 'components/hoc/promiseContainer';
import { fetchContentPage } from 'api/content';
import withBrowserState from '../hoc/withBrowserState';

import ContentPage from './ContentPage';

function mapPropsToPromises({ match: { params: { slug } }, history }) {
  return {
    history,
    content: fetchContentPage(slug),
  };
}

export default promiseContainer(mapPropsToPromises)(
  withBrowserState(ContentPage),
);
