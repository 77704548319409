import { connect } from 'react-redux';

import { clearFilters } from 'actions/search';
import ActiveFilters from './ActiveFilters';

function mapStateToProps(state, ownProps) {
  const { searchFilters } = state;
  return { searchFilters };
}

function mapDispatchToProps(dispatch) {
  return {
    clearFilters: () => dispatch(clearFilters()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveFilters);
