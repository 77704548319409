import React, { PureComponent } from 'react';
import glamorous from 'glamorous';

import FacetLink from 'components/FacetLink';
import LocalizedString from 'components/LocalizedString';

export default class FacetFilter extends PureComponent {
  render() {
    const { field, value, options, hasSelectedFilters, headerId } = this.props;

    if (value) {
      return (
        <FacetLink
          unset
          field={field}
          role="option"
          selected
          aria-selected="true"
          headerId={headerId}
        >
          {value}
        </FacetLink>
      );
    } else if (options) {
      return (
        <Filters hasSelectedFilters={hasSelectedFilters}>
          {options.map(option => (
            <Option key={option.key} role="option" aria-selected="false">
              <FacetLink
                field={field}
                value={option.key}
                count={option.doc_count}
                headerId={headerId}
              >
                {option.key}
              </FacetLink>
            </Option>
          ))}
        </Filters>
      );
    } else {
      return (
        <Filters hasSelectedFilters={hasSelectedFilters}>
          <Option>
            <LocalizedString string="loading" />
          </Option>
        </Filters>
      );
    }
  }
}

const Filters = glamorous.ul(
  {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  ({ hasSelectedFilters }) =>
    hasSelectedFilters && {
      marginTop: 19,
    },
);

const Option = glamorous.li({ color: 'white', marginBottom: 5 });
