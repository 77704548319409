import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import Facet from 'components/Facet';
import HistogramFilter from 'components/HistogramFilter';
import SearchBar from 'components/SearchBar';
import LocalizedString from 'components/LocalizedString';
import localize from 'components/LocalizedString/Localize';
import ActiveFiltersContainer from './ActiveFiltersContainer';

import Announcements from 'components/util/Announcements';
import LogoFooter from 'components/LogoFooter';

import responsive from 'components/hoc/responsive';
import formatNumber from 'lib/formatNumber';
import * as cssVariable from 'cssVariables';

const TRANSITION_DURATION = 200;
const SHOW_BUTTON_MARGIN = 36;

const FILTERS_OPEN_STORAGE_KEY = 'filtersOpen';

export default class Filters extends PureComponent {
  static contextTypes = {
    lang: PropTypes.string,
  };

  state = {
    open: false,
  };

  componentDidMount() {
    if (
      localStorage.getItem(FILTERS_OPEN_STORAGE_KEY) === 'open' &&
      this.props.browser.greaterThan.extraExtraSmall
    ) {
      this.toggleFilters();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.isMenuOpen !== nextProps.isMenuOpen &&
      this.state.open &&
      nextProps.isMenuOpen
    ) {
      this.setFiltersOpenState(false);
    }
  }

  toggleFilters = e => {
    const open = !this.state.open;
    this.setFiltersOpenState(open);
  };

  setFiltersOpenState(open) {
    const width = this.wrapperElement.getBoundingClientRect().width;

    localStorage.setItem(FILTERS_OPEN_STORAGE_KEY, open ? 'open' : 'closed');

    this.wrapperElement.style.transform = `translateX(${open ? 0 : -width}px)`;

    if (this.props.onToggle instanceof Function) {
      this.props.onToggle(open, width);
    }

    this.setState({
      open,
    });
  }

  wrapperRef = element => {
    this.wrapperElement = element;
  };

  render() {
    const { histogram, filters, total } = this.props;
    const { open } = this.state;

    return (
      <Container role="search">
        <Announcements
          level="assertive"
          message={
            open
              ? localize('searchOpened', this.context.lang)
              : localize('searchClosed', this.context.lang)
          }
        />
        {open && (
          <Announcements
            level="polite"
            message={localize('objectCount', this.context.lang, {
              count: formatNumber(this.props.total),
            })}
          />
        )}
        <Wrapper innerRef={this.wrapperRef}>
          <AccessibilityWrapper aria-hidden={!open} open={open}>
            <SearchBar open={open} />
            <FilterContainer>
              <TotalHitCount>
                <LocalizedString
                  string="objectCount"
                  values={{ count: formatNumber(total) }}
                />
              </TotalHitCount>
              <h2 className="visually-hidden">Filters</h2>
              {histogram.length && <HistogramFilter periods={histogram} />}
              <Facets>
                {filters.map(filter => (
                  <Facet
                    key={filter.name}
                    name={filter.name}
                    filters={filter.facets}
                  />
                ))}
              </Facets>
            </FilterContainer>
            <LogoFooter inSearchPage />
          </AccessibilityWrapper>
        </Wrapper>
        <ActiveFiltersContainer
          onToggle={this.toggleFilters}
          transitionDuration={TRANSITION_DURATION}
          open={open}
        />
        <ShowResultsButton onClick={this.toggleFilters} open={open}>
          <LocalizedString string="showResults" />
          <TotalHits>{total}</TotalHits>
        </ShowResultsButton>
      </Container>
    );
  }
}

const Container = glamorous.div();

const Wrapper = responsive.div(
  {
    background: 'black',
    width: 380,
    padding: 24,
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    transform: 'translateX(-380px)',
    transition: `transform ${TRANSITION_DURATION}ms ease-out`,
    willChange: 'transform',
    zIndex: 10,
    '::-webkit-scrollbar': {
      width: 8,
    },
    '::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: cssVariable.colorGray,
    },
  },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      width: '100vw',
      overflowX: 'hidden',
      transform: 'translateX(-100vw)',
    },
);

const AccessibilityWrapper = responsive.div(
  {
    display: 'none',
  },
  ({ open }) =>
    open && {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100%',
    },
);

const FilterContainer = glamorous.div({
  padding: 12,
  paddingTop: 20,
  paddingBottom: 20,
  marginBottom: 40,
});

const Facets = responsive.div(
  ({ browser }) =>
    browser.fits.extremelySmall && {
      marginBottom: 40,
    },
);

const ShowResultsButton = responsive.button(
  {
    backgroundColor: cssVariable.colorWhite,
    fontSize: 18,
    fontWeight: cssVariable.fontWeights.bold,
    width: `calc(100vw - ${SHOW_BUTTON_MARGIN * 2}px)`,
    padding: '10px 36px',
    height: 50,
    position: 'fixed',
    bottom: 25,
    left: 0,
    margin: `0px ${SHOW_BUTTON_MARGIN}px`,
    display: 'none',
    zIndex: 10,
  },
  ({ open, browser }) =>
    open &&
    browser.fits.extraExtraSmall && {
      display: 'block',
    },
  ({ browser }) =>
    browser.fits.extremelySmall && {
      padding: '10px 18px',
    },
);

const TotalHits = glamorous.span({
  color: cssVariable.colorGray,
  fontFamily: cssVariable.fontFamilies.maisonMono,
  marginLeft: 12,
});

const TotalHitCount = glamorous.h2({
  fontFamily: cssVariable.fontFamilies.maisonMono,
  marginLeft: 36,
  fontWeight: cssVariable.fontWeights.regular,
  marginTop: 0,
  fontSize: 14,
  color: cssVariable.colorGray,
});
