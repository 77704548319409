import React, { PureComponent } from 'react';
import glamorous from 'glamorous';
import _ from 'lodash';

import Icon from 'components/Icon';

import responsive from 'components/hoc/responsive';
import * as cssVariable from 'cssVariables';

const BUTTON_PADDING = 15;
const BUTTON_DIMENS = 50;

export default class ActiveFilters extends PureComponent {
  clearFilters = () => {
    this.props.clearFilters();
  };

  toggleFilters = () => {
    this.props.onToggle();
  };

  formatActiveFilters() {
    const { searchFilters } = this.props;
    const filterArr = _.toPairs(searchFilters);
    const sortedFilters = [];

    if (searchFilters) {
      const { q, from, to } = searchFilters;
      if (q) {
        sortedFilters.push(q);
      }

      if (from && to) {
        sortedFilters.push(`${from}-${to}`);
      }

      filterArr.forEach(filter => {
        const key = filter[0];
        if (key !== 'q' && key !== 'from' && key !== 'to') {
          sortedFilters.push(filter[1]);
        }
      });
    }

    return sortedFilters.join(', ');
  }

  render() {
    const { open } = this.props;
    const activeFilters = this.formatActiveFilters();
    const showActiveFilters = activeFilters && !open;

    return (
      <FlexContainer open={open}>
        <Container>
          <LeftButton
            onClick={this.toggleFilters}
            className={open ? 'icon-hover-left' : 'icon-hover-shrink'}
          >
            <Icon
              iconName={open ? 'arrowLeft' : 'search'}
              iconSize={cssVariable.iconSizes.medium}
              title={open ? 'iconTitleCloseSearch' : 'iconTitleOpenSearch'}
            />
          </LeftButton>
          {showActiveFilters && (
            <ActiveFiltersContainer>
              {showActiveFilters && (
                <ButtonText onClick={this.clearFilters} role="button">
                  {activeFilters}
                </ButtonText>
              )}
              <RightButton
                onClick={this.clearFilters}
                className="icon-hover-shrink"
              >
                <Icon
                  iconName="close"
                  iconSize={cssVariable.iconSizes.medium}
                  title="iconTitleClear"
                />
              </RightButton>
            </ActiveFiltersContainer>
          )}
        </Container>
      </FlexContainer>
    );
  }
}

const button = {
  backgroundColor: cssVariable.colorBlack,
  height: BUTTON_DIMENS,
};

const iconCenteredButton = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: BUTTON_DIMENS,
};

const FlexContainer = responsive.div(
  {
    position: 'fixed',
    height: 50,
    width: 0,
    overflow: 'visible',
    bottom: 50,
    right: 70,
    left: 70,
    transform: 'translateX(0)',
    transition: 'all 200ms ease-out',
  },
  ({ open }) =>
    open && {
      transform: 'translateX(380px)',
      left: 40,
    },
  ({ browser }) =>
    browser.fits.medium && {
      left: 30,
      bottom: 30,
    },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      left: 20,
      bottom: 20,
      right: 20,
    },
  ({ browser, open }) =>
    browser.fits.extraExtraSmall &&
    open && {
      display: 'none',
    },
);

const Container = responsive.div(
  {
    display: 'inline-flex',
    position: 'relative',
    maxWidth: 'calc(100vw - 60px)',
  },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      maxWidth: 'calc(100vw - 40px)',
    },
);

const LeftButton = responsive.button({
  ...button,
  ...iconCenteredButton,
  backgroundColor: cssVariable.colorBlack,
  paddingLeft: BUTTON_PADDING,
  paddingRight: BUTTON_PADDING,
  marginRight: 1,
});

const ActiveFiltersContainer = glamorous.div({
  backgroundColor: cssVariable.colorBlack,
  display: 'flex',
  minWidth: 0,
});

const RightButton = glamorous.button({
  ...button,
  ...iconCenteredButton,
  flex: '1 1 auto',
  padding: `0 ${BUTTON_PADDING}px`,
});

const ButtonText = responsive.div({
  ...button,
  textTransform: 'uppercase',
  fontSize: 18,
  color: cssVariable.colorWhite,
  fontWeight: cssVariable.fontWeights.bold,
  cursor: 'pointer',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  paddingLeft: BUTTON_PADDING,
  flex: [0, 1, 'auto'],
  minWidth: 0,
  textAlign: 'center',
  lineHeight: `${BUTTON_DIMENS}px`,
});
