/**
 * Idea from Webfont Handbook by Bram Stein
 */

import FontFaceObserver from 'fontfaceobserver';

import * as cssVariable from 'cssVariables';

const FONT_LOADING_TIMEOUT = 3000;

if (!sessionStorage.getItem('fontsLoaded')) {
  const fontObservers = Object.values(cssVariable.fontWeights).map(
    weight =>
      new FontFaceObserver(cssVariable.fontFamilies.maisonNeue, { weight }),
    weight =>
      new FontFaceObserver(cssVariable.fontFamilies.maisonMono, { weight }),
  );

  awaitFonts(fontObservers);
}

async function awaitFonts(fontObservers) {
  try {
    await Promise.race([
      Promise.all(fontObservers.map(x => x.load())),
      new Promise((resolve, reject) => {
        setTimeout(reject, FONT_LOADING_TIMEOUT);
      }),
    ]);

    // Used in App.js
    document.documentElement.classList.add('fonts-active');

    // Used in index.html
    sessionStorage.setItem('fontsLoaded', JSON.stringify(true));
  } catch (error) {
    // I don't care.
  }
}
