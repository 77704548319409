import { routeUtils } from 'lib/history';

export default () => ({
  main: [
    {
      path: routeUtils.homepage(),
      name: 'home',
    },
    {
      path: routeUtils.objectOverview(),
      name: 'objects',
    },
    {
      path: routeUtils.setOverview(),
      name: 'displays',
    },
    {
      path: routeUtils.book(),
      name: 'book',
    },
  ],
  sub: [
    {
      path: routeUtils.contentPage({ slug: 'de-opgravingen-index' }),
      name: 'about',
    },
    {
      path: routeUtils.contentPage({ slug: 'de-rokin-vitrines' }),
      name: 'rokin',
    },
    {
      path: routeUtils.contentPage({ slug: 'publicaties-en-datasets' }),
      name: 'data',
    },
    {
      path: routeUtils.storyPage(),
      name: 'stories',
    },
    {
      path: routeUtils.contentPage({ slug: 'documentaire' }),
      name: 'documentaire',
    },
    {
      path: routeUtils.contentPage({ slug: 'disclaimer' }),
      name: 'disclaimer',
    },
    {
      path: routeUtils.contentPage({ slug: 'colofon-contact' }),
      name: 'colofon',
    },
  ],
});
