import { connect } from 'react-redux';
import { setFilters, setQuery } from 'actions/search';

import ObjectDetailList from './ObjectDetailList';

function mapDispatchToProps(dispatch) {
  return {
    setFilters(searchFilters) {
      const action = setFilters(searchFilters);
      return dispatch(action);
    },
    setQuery(q) {
      const action = setQuery(q);
      return dispatch(action);
    },
  };
}

export default connect(null, mapDispatchToProps)(ObjectDetailList);
