import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import CanvasCollectButton from 'components/CanvasCollectButton';
import localize from 'components/LocalizedString/Localize';
import LocalizedString from 'components/LocalizedString';
import Header from 'components/Header';
import Container from 'components/util/Container';
import Icon from 'components/Icon';
import LogoFooter from 'components/LogoFooter';

import * as cssVariable from 'cssVariables';
import history, { routeUtils } from 'lib/history';
import { fetchCanvas } from 'api/canvas';
import responsive from 'components/hoc/responsive';
import WindowOpener from 'components/util/WindowOpener';

const LINK_FONT_SIZE = 18;

export default class CanvasDetail extends Component {
  static contextTypes = { lang: PropTypes.string };

  state = {
    showEditForm: false,
  };

  componentDidMount() {
    window.onBtsCanvasObjectClicked = vondstnummer =>
      history.push(routeUtils.objectDetail({ vondstnummer }));
  }

  editButtonClicked = async () => {
    if (
      !this.props.canvasIsEmpty &&
      !window.confirm(localize('replaceCanvasConfirm', this.context.lang))
    ) {
      return;
    }

    const { id } = this.props.canvasData;

    // TODO: Toon (en hide) spinner!
    try {
      const editableCanvasData = await fetchCanvas(id, true);
      this.props.loadCanvas(editableCanvasData);
      history.push(routeUtils.draftSet());
    } catch (error) {
      console.error(error);
      if (error.statusCode === 404) {
        history.replace(routeUtils.notFound());
        return;
      }
      throw error;
    }
  };

  render() {
    const { title, svg, usedVondstnummers, id } = this.props.canvasData;

    const url =
      process.env.REACT_APP_FRONTEND_URL + routeUtils.setDetail({ id });

    const socialMessage = encodeURIComponent(
      localize('publishShareText', this.context.lang, {
        title: title,
      }),
    );

    return (
      <div>
        <Helmet>
          <title>
            {title} - {localize('userDisplayTitle', this.context.lang)} |{' '}
            {localize('title', this.context.lang)}
          </title>
        </Helmet>
        <Header css={{ backgroundColor: cssVariable.colorLightGray }} />
        <ContentWrapper id="content-wrapper" role="main">
          <CanvasContainer>
            <ButtonContainer>
              <CanvasCollectButton
                text="collectObject"
                vondstnummers={usedVondstnummers}
              />
            </ButtonContainer>
            <Canvas dangerouslySetInnerHTML={{ __html: svg }} />
          </CanvasContainer>
          <Container css={{ backgroundColor: cssVariable.colorLightGray }}>
            <TitleContainer>
              <Title>{title}</Title>
              <ButtonWrapper>
                <ShareButtonsContainer>
                  <ShareText>
                    <LocalizedString string="shareCollection" />
                  </ShareText>
                  <ShareButton
                    href={`https://www.facebook.com/share.php?quote=${socialMessage}&u=${url}`}
                    target="_blank"
                    onClick={WindowOpener.openSocialShare}
                    className="icon-hover-shrink"
                  >
                    <Icon
                      iconName="facebook"
                      iconSize={cssVariable.iconSizes.mediumLarge}
                      color={cssVariable.colorBlack}
                      title="facebook"
                    />
                  </ShareButton>
                  <ShareButton
                    href={`https://twitter.com/intent/tweet?text=${socialMessage +
                      ' ' +
                      url}`}
                    target="_blank"
                    onClick={WindowOpener.openSocialShare}
                    className="icon-hover-shrink"
                  >
                    <Icon
                      iconName="twitter"
                      iconSize={cssVariable.iconSizes.mediumLarge}
                      color={cssVariable.colorBlack}
                      title="twitter"
                    />
                  </ShareButton>
                </ShareButtonsContainer>
                <EditButton
                  onClick={this.editButtonClicked}
                  className="icon-hover-right"
                >
                  <TextWrapper>
                    <LocalizedString string="remixCanvasButtonCaption" />
                  </TextWrapper>
                  <Icon iconName="arrowRight" color={cssVariable.colorBlack} />
                </EditButton>
              </ButtonWrapper>
            </TitleContainer>
          </Container>
          <Backdrop show={this.state.showEditForm} />
        </ContentWrapper>
        <LogoFooter />
      </div>
    );
  }
}

const ContentWrapper = glamorous.div();

const ButtonContainer = responsive.div(
  {
    position: 'absolute',
    bottom: 0,
    left: cssVariable.gridMargins.desktop,
  },
  ({ browser }) =>
    browser.fits.medium && {
      left: cssVariable.gridMargins.tablet,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      left: cssVariable.gridMargins.mobile,
    },
);

const CanvasContainer = glamorous.div({
  backgroundColor: cssVariable.colorLightGray,
  position: 'relative',
});

const Canvas = glamorous.div({
  '> svg': {
    width: '100%',
    height: 'auto',
    maxHeight: '50vh',
  },
});

const TitleContainer = responsive.div(
  {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ({ browser }) =>
    browser.fits.smallMedium && {
      flexDirection: 'column',
    },
);

const Title = responsive.h1(
  {
    textTransform: 'uppercase',
    fontSize: 52,
    marginBottom: 24,
    lineHeight: '1.35',
    flex: 1,
    marginTop: 30,
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      flexDirection: 'column',
      fontSize: 30,
      marginBottom: 5,
    },
);

const TextWrapper = glamorous.span({
  marginRight: 10,
  whiteSpace: 'nowrap',
});

const ShareButtonsContainer = responsive.div(
  {
    marginTop: 'auto',
    marginRight: 60,
    display: 'flex',
  },
  ({ browser }) =>
    browser.fits.medium && {
      marginRight: 20,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      marginBottom: 6,
    },
);

const ShareText = glamorous.span({
  fontSize: LINK_FONT_SIZE,
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  fontWeight: cssVariable.fontWeights.bold,
});

const ShareButton = glamorous.a({
  padding: '0 6px',
  '> svg': {
    verticalAlign: 'middle',
  },
});

const ButtonWrapper = responsive.div(
  {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 24,
  },
  ({ browser }) =>
    browser.fits.smallMedium && {
      display: 'block',
      marginTop: 10,
    },
);

const EditButton = glamorous.button({
  fontSize: LINK_FONT_SIZE,
  background: 'none',
  verticalAlign: 'middle',
  height: 30,
  marginTop: 'auto',
  padding: 0,
  fontWeight: cssVariable.fontWeights.bold,
});

const Backdrop = glamorous.div(
  {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'black',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 200ms linear',
  },
  ({ show }) =>
    show && {
      opacity: 0.6,
      pointerEvents: 'all',
    },
);
