import React, { Component } from 'react';
import objectFitImages from 'object-fit-images';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import history, { routeUtils } from 'lib/history';
import * as cssVariable from 'cssVariables';
import responsive from 'components/hoc/responsive';

import LocalizedString from 'components/LocalizedString';
import localize from 'components/LocalizedString/Localize';
import Icon from 'components/Icon';

import FacebookSVG from './facebook.svg';
import TwitterSVG from './twitter.svg';
import WindowOpener from 'components/util/WindowOpener';

export default class CanvasPublishedPage extends Component {
  static contextTypes = { lang: PropTypes.string };

  componentDidMount() {
    // This fixes issues for IE11
    // Polyfill needs to be initialized after components are mounted
    objectFitImages();
  }

  viewDetails = e => {
    const { canvasData } = this.props;

    history.push(routeUtils.setDetail({ id: canvasData.id }));
  };

  backToCanvas = e => {
    history.push(routeUtils.draftSet());
  };

  render() {
    const { canvasData } = this.props;
    const url =
      process.env.REACT_APP_FRONTEND_URL +
      routeUtils.setDetail({ id: canvasData.id });

    const socialMessage = encodeURIComponent(
      localize('publishShareText', this.context.lang, {
        title: canvasData.title,
      }),
    );

    return (
      <Wrapper>
        <Close onClick={this.backToCanvas} className="icon-hover-shrink">
          <Icon
            iconName="close"
            iconSize={cssVariable.iconSizes.medium}
            title="iconTitleClose"
          />
        </Close>
        <Content role="main">
          <CanvasWrapper>
            <CanvasTitle>{canvasData.title}</CanvasTitle>
            <Canvas dangerouslySetInnerHTML={{ __html: canvasData.svg }} />
          </CanvasWrapper>
          <Share onSubmit={this.publish}>
            <Title>
              <LocalizedString string="publishedTitle" />
            </Title>
            <SocialShare>
              <ShareTitle>
                <LocalizedString string="shareWith" />
              </ShareTitle>
              <SocialButton
                href={`https://www.facebook.com/share.php?quote=${socialMessage}&u=${url}`}
                target="_blank"
                onClick={WindowOpener.openSocialShare}
                className="icon-hover-shrink"
              >
                <SocialIcon
                  src={FacebookSVG}
                  alt={localize('shareBtn', this.context.lang) + 'Facebook'}
                />
              </SocialButton>
              <SocialButton
                href={`https://twitter.com/intent/tweet?text=${socialMessage +
                  ' ' +
                  url}`}
                target="_blank"
                onClick={WindowOpener.openSocialShare}
                className="icon-hover-shrink"
              >
                <SocialIcon
                  src={TwitterSVG}
                  alt={localize('shareBtn', this.context.lang) + 'Twitter'}
                />
              </SocialButton>
            </SocialShare>
            <Button onClick={this.viewDetails} className="icon-hover-right">
              <LocalizedString string="viewPublishedCanvas" />
              <Icon iconName="arrowRight" color="black" />
            </Button>
          </Share>
        </Content>
      </Wrapper>
    );
  }
}

const Wrapper = responsive.section(
  {
    background: 'black',
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    padding: cssVariable.gridMargins.desktop,
  },
  ({ browser }) =>
    browser.fits.medium && {
      padding: cssVariable.gridMargins.tablet,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: cssVariable.gridMargins.mobile,
    },
);

const Close = responsive.button(
  {
    background: 'transparent',
    position: 'absolute',
    top: 40,
    left: cssVariable.gridMargins.desktop,
    padding: 0,
    color: 'white',
    '> svg': {
      verticalAlign: 'middle',
    },
  },
  ({ browser }) =>
    browser.fits.medium && {
      top: cssVariable.gridMargins.tablet,
      left: cssVariable.gridMargins.tablet,
      '> svg': {
        width: 21,
      },
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      position: 'relative',
      top: 'auto',
      left: 'auto',
    },
);

const Content = responsive.div(
  {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      flexDirection: 'column-reverse',
      flexWrap: 'wrap',
    },
);

const Share = responsive.div(
  {
    width: '40%',
    paddingLeft: 24,
  },
  ({ browser }) =>
    browser.fits.medium && {
      width: '50%',
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      width: '100%',
      marginTop: 24,
      padding: 0,
    },
);

const SocialShare = glamorous.div({
  marginBottom: 80,
});

const ShareTitle = glamorous.h2({
  color: 'white',
  fontSize: 18,
  margin: 0,
  marginBottom: 8,
});

const SocialButton = glamorous.a();

const SocialIcon = glamorous.img({
  width: 56,
  objectFit: 'contain',
  fontFamily: '"object-fit: contain"', // Dirty IE11 polyfill fix
});

const Title = responsive.h1({
  color: 'white',
  fontSize: 26,
  lineHeight: 1.35,
  margin: 0,
  marginBottom: 40,
  fontWeight: cssVariable.fontWeights.bold,
  textTransform: 'uppercase',
});

const CanvasWrapper = responsive.div(
  {
    position: 'relative',
    width: '60%',
  },
  ({ browser }) =>
    browser.fits.medium && {
      width: '50%',
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      width: '100%',
      marginTop: 40,
    },
);

const CanvasTitle = glamorous.h2({
  position: 'absolute',
  bottom: 0,
  left: 0,
  margin: 24,
  textTransform: 'uppercase',
});

const Canvas = responsive.div({
  width: '100%',
  '> svg': {
    width: '100%',
    height: 'auto',
    pointerEvents: 'none',
  },
});

const Button = glamorous.button({
  border: 'none',
  padding: '16px 24px',
  background: 'white',
  color: 'black',
  fontSize: 18,
  fontWeight: cssVariable.fontWeights.bold,
  '> svg': {
    marginLeft: 8,
    verticalAlign: 'middle',
  },
});
