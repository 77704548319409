import { connect } from 'react-redux';

import withBrowserState from 'components/hoc/withBrowserState';
import Filters from './Filters';

function mapStateToProps(state) {
  return {
    isMenuOpen: state.menu.isOpen,
  };
}

export default connect(mapStateToProps)(withBrowserState(Filters));
