import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import localize from 'components/LocalizedString/Localize';

type Props = {
  bottom: number,
  top: number,
  periods: Array<{ from: ?number, to: ?number }>,
  sizes: {
    thumb: {
      height: number,
      width: number,
      margin: number,
    },
    track: {
      width: number,
    },
  },
  onChange: Function,
};

const EXTRA_STEPS = 2;
const HIT_SLOP = 10;

export default class Slider extends PureComponent<Props> {
  constructor(props) {
    super(props);
    this.state = {
      ...this.getStateFromProps(props),
      temporaryBottom: null,
      temporaryTop: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.getStateFromProps(nextProps));
  }

  getStateFromProps({ bottom, top, periods, sizes }) {
    // steps + 2, zodat de thumbs boven/onder uitsteken
    const actualSteps = periods.length + EXTRA_STEPS;
    const containerHeight =
      actualSteps * (sizes.track.step + sizes.thumb.margin) -
      sizes.thumb.margin;
    const containerWidth = Math.max(sizes.track.width, sizes.thumb.width);

    return {
      top,
      bottom: bottom + EXTRA_STEPS,
      steps: actualSteps,
      sizes: {
        thumb: sizes.thumb,
        container: { height: containerHeight, width: containerWidth },
        track: {
          step: sizes.track.step,
          width: sizes.track.width,
          height: containerHeight,
        },
      },
    };
  }

  convertStepToPx(step) {
    return step * (this.state.sizes.track.step + this.state.sizes.thumb.margin);
  }

  convertPxToStep(pxPosition) {
    return Math.round(
      pxPosition /
        (this.state.sizes.track.step + this.state.sizes.thumb.margin),
    );
  }

  setTemporaryBottom = temporaryBottom => {
    requestAnimationFrame(() => {
      this.props.onChange({
        isPreviewState: true,
        bottom: this.convertPxToStep(temporaryBottom) - EXTRA_STEPS,
        top: this.props.top,
      });
      this.setState({ temporaryBottom });
    });
  };

  commitTemporaryBottomValue = () => {
    const { temporaryBottom } = this.state;
    if (temporaryBottom == null) {
      return;
    }
    this.commitBottomValue(temporaryBottom);
  };

  commitBottomValue = bottom => {
    this.props.onChange({
      isPreviewState: false,
      bottom: this.convertPxToStep(bottom) - EXTRA_STEPS,
      top: this.props.top,
    });
    this.setState({
      bottom: this.convertPxToStep(bottom),
      temporaryBottom: null,
    });
  };

  setTemporaryTop = temporaryTop => {
    requestAnimationFrame(() => {
      this.props.onChange({
        isPreviewState: true,
        bottom: this.props.bottom,
        top: this.convertPxToStep(temporaryTop),
      });
      this.setState({ temporaryTop });
    });
  };

  commitTemporaryTopValue = () => {
    const { temporaryTop } = this.state;
    if (temporaryTop == null) {
      return;
    }
    this.commitTopValue(temporaryTop);
  };

  commitTopValue = top => {
    this.props.onChange({
      isPreviewState: false,
      bottom: this.props.bottom,
      top: this.convertPxToStep(top),
    });
    this.setState({
      top: this.convertPxToStep(top),
      temporaryTop: null,
    });
  };

  render() {
    const {
      bottom: bottomStep,
      top: topStep,
      temporaryTop,
      temporaryBottom,
      sizes: { thumb, track, container },
    } = this.state;
    const { periods } = this.props;

    const pxBottom =
      temporaryBottom != null
        ? temporaryBottom
        : this.convertStepToPx(bottomStep);
    const pxTop =
      temporaryTop != null ? temporaryTop : this.convertStepToPx(topStep);

    return (
      <Container width={container.width} height={container.height}>
        <BackgroundTrack width={track.width} height={track.height} />
        <ForegroundTrack
          top={pxTop + track.step + thumb.margin}
          width={track.width}
          height={pxBottom - pxTop - track.step - 2 * thumb.margin}
        />
        <ThumbContainer
          value={pxTop - track.step - thumb.margin}
          min={0}
          max={pxBottom - 2 * (thumb.margin + track.step)}
          size={thumb}
          setTemporaryValue={this.setTemporaryTop}
          commitTemporaryValue={this.commitTemporaryTopValue}
          commitValue={this.commitTopValue}
          role="slider"
          tabIndex={0}
          aria-label={localize('toYear', this.context.lang)}
          aria-valuemin={periods[periods.length - 1].to}
          aria-valuemax={periods[bottomStep - EXTRA_STEPS].to}
          aria-valuenow={periods[topStep].to}
        />
        <ThumbContainer
          value={pxBottom - thumb.margin}
          min={pxTop + 2 * (track.step + thumb.margin)}
          max={container.height - track.step}
          size={thumb}
          setTemporaryValue={this.setTemporaryBottom}
          commitTemporaryValue={this.commitTemporaryBottomValue}
          commitValue={this.commitBottomValue}
          role="slider"
          tabIndex={0}
          aria-label={localize('fromYear', this.context.lang)}
          aria-valuemin={periods[periods.length - 1].from}
          aria-valuemax={periods[topStep].from}
          aria-valuenow={periods[bottomStep - EXTRA_STEPS].from}
        />
      </Container>
    );
  }
}

Slider.contextTypes = {
  lang: PropTypes.string,
};

class ThumbContainer extends PureComponent {
  element = null;
  offsetTop = null;
  startValue = null;
  isCapturing = false;

  componentDidMount() {
    window.addEventListener('mouseup', this.setCapturingOff);
    window.addEventListener('touchend', this.setCapturingOff);
    window.addEventListener('focusout', this.setCapturingOff);
    window.addEventListener('touchmove', this.preventIOSBounceAndScrolling);
    document.addEventListener('touchmove', this.preventIOSBounceAndScrolling);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.setCapturingOff);
    window.removeEventListener('touchend', this.setCapturingOff);
    window.removeEventListener('focusout', this.setCapturingOff);
    window.removeEventListener('touchmove', this.preventIOSBounceAndScrolling);
    document.removeEventListener(
      'touchmove',
      this.preventIOSBounceAndScrolling,
    );
  }

  acquireRef = ref => {
    this.element = ref;
  };

  preventIOSBounceAndScrolling = e => {
    if (this.isCapturing) {
      e.preventDefault();
    }
  };

  setCapturing = () => {
    if (this.isCapturing) {
      return;
    }
    this.isCapturing = true;
    this.offsetTop = this.element.getBoundingClientRect().top;
    this.startValue = this.props.value;
    window.addEventListener('mousemove', this.handleMove);
    window.addEventListener('touchmove', this.handleMove);
    window.addEventListener('keydown', this.handleAccessibleMove);
  };

  setCapturingOff = () => {
    if (!this.isCapturing) {
      return;
    }
    this.isCapturing = false;
    this.props.commitTemporaryValue();
    this.element.blur();
    window.removeEventListener('mousemove', this.handleMove);
    window.removeEventListener('touchmove', this.handleMove);
    window.removeEventListener('keydown', this.handleAccessibleMove);
  };

  handleMove = e => {
    if (!this.isCapturing) {
      return;
    }

    const clientY =
      typeof e.clientY === 'number'
        ? e.clientY
        : e.touches && e.touches[0].clientY;
    const topDelta = clientY - this.offsetTop - HIT_SLOP;
    const newValue = this.startValue + topDelta;
    const newTop = Math.max(this.props.min, Math.min(this.props.max, newValue));
    this.props.setTemporaryValue(newTop);
  };

  handleAccessibleMove = e => {
    if (e.ctrlKey || e.shiftKey || e.altKey) return;

    const setMove = newValue => {
      e.preventDefault();
      const newTop = Math.max(
        this.props.min,
        Math.min(this.props.max, newValue),
      );
      this.props.commitValue(newTop);
    };
    const { size } = this.props;
    switch (e.key) {
      case 'ArrowLeft':
      case 'ArrowUp':
        return setMove(this.props.value - size.height - size.margin);
      case 'ArrowRight':
      case 'ArrowDown':
        return setMove(this.props.value + size.height + size.margin);
      case 'Home':
        return setMove(this.props.min);
      case 'End':
        return setMove(this.props.max);
      default:
        return;
    }
  };

  render() {
    return (
      <ThumbHitSlop
        top={this.props.value}
        onFocus={this.setCapturing}
        onMouseDown={this.setCapturing}
        onTouchStart={this.setCapturing}
        innerRef={this.acquireRef}
        width={this.props.size.width + 2 * HIT_SLOP}
        height={this.props.size.height + 2 * HIT_SLOP}
        role={this.props.role}
        tabIndex={this.props.tabIndex}
        aria-label={this.props['aria-label']}
        aria-valuemin={this.props['aria-valuemin']}
        aria-valuemax={this.props['aria-valuemax']}
        aria-valuenow={this.props['aria-valuenow']}
      >
        <Thumb width={this.props.size.width} height={this.props.size.height} />
      </ThumbHitSlop>
    );
  }
}

const Container = glamorous.div(({ width, height }) => ({
  position: 'absolute',
  width,
  height,
}));

const Track = glamorous.div(({ top, width, height, backgroundColor }) => ({
  position: 'absolute',
  top,
  width,
  height,
  backgroundColor,
  left: '50%',
  transform: 'translateX(-50%)',
}));

const BackgroundTrack = Track.withProps({
  top: 0,
  backgroundColor: '#222',
});

const ForegroundTrack = Track.withProps({
  backgroundColor: '#fff',
});

const Thumb = glamorous.div(({ width, height }) => ({
  height,
  width,
  background: 'white',
  border: 'solid 1px #000',
  marginTop: HIT_SLOP,
  marginLeft: HIT_SLOP,
  borderRadius: '50%',
}));

const ThumbHitSlop = glamorous.div(({ top, width, height }) => ({
  position: 'absolute',
  top: top - HIT_SLOP,
  left: -HIT_SLOP,
  width,
  height,
  cursor: 'grab',
  touchAction: 'none',
}));
