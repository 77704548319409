import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import responsive from 'components/hoc/responsive';

import NavigationControl from './NavigationControl';
import { MENU_CONTAINER_ANIM_DURATION } from 'components/Menu/Menu';

const MARGIN_DESKTOP = 40;
const MARGIN_MOBILE = 20;

export default class Header extends Component {
  state = {
    isDownloaderOpen: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isMenuOpen !== this.props.isMenuOpen) {
      const currentId = this.props.isMenuOpen
        ? 'insideMenuToggle'
        : 'outsideMenuToggle';
      document.getElementById(currentId) &&
        document.getElementById(currentId).focus();
      if (this.props.isMenuOpen) {
        setTimeout(function() {
          const cw = document.getElementById('content-wrapper');
          if (cw) {
            cw.style.display = 'none';
          }
        }, MENU_CONTAINER_ANIM_DURATION);
      } else {
        const cw = document.getElementById('content-wrapper');
        if (cw) {
          cw.style.display = 'block';
        }
      }
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  /* using fat arrow to bind to instance */
  handleDocumentClick = evt => {
    const navRef = ReactDOM.findDOMNode(this.refs.navRef);
    if (navRef && !navRef.contains(evt.target) && this.props.isMenuOpen) {
      this.props.closeMenu();
    }
  };

  render() {
    return (
      <Navigation ref="navRef" role="menubar">
        <ControlContainer isMenuOpen={this.props.isMenuOpen}>
          <NavigationControl
            isMenuOpen={this.props.isMenuOpen}
            openMenu={this.props.openMenu}
            closeMenu={this.props.closeMenu}
            buttonId="outsideMenuToggle"
          />
        </ControlContainer>
      </Navigation>
    );
  }
}

const Navigation = responsive.nav(
  {
    marginTop: MARGIN_DESKTOP,
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-end',
    // Make sure we reset pointer-events because sometimes they are overwritten on the parent
    pointerEvents: 'all',
    zIndex: 2,
    '@media print': {
      display: 'none',
    },
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      marginTop: MARGIN_MOBILE,
    },
  ({ browser }) =>
    browser.fits.medium && {
      zIndex: 3,
    },
);

const ControlContainer = responsive.div(
  {
    display: 'block',
  },
  ({ isMenuOpen }) =>
    isMenuOpen && {
      display: 'none',
    },
);
