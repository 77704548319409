import withBrowserState from 'components/hoc/withBrowserState';
import glamorous from 'glamorous';

function responsive(...componentParams) {
  return (...styleParams) =>
    withBrowserState(glamorous(...componentParams)(...styleParams));
}

Object.entries(glamorous).forEach(([key, componentFunction]) => {
  responsive[key] = (...styleParams) =>
    withBrowserState(componentFunction(...styleParams));
});

export default responsive;
