import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import _ from 'lodash';
import fabric from 'fabric';
import Velocity from 'velocity-animate';
import featuredVondstnummers from './featured-vondstnummers';
import LocalizedString from 'components/LocalizedString';
import localize from 'components/LocalizedString/Localize';
import PageTitleHeading from 'components/util/PageTitleHeading';
import { routeUtils } from 'lib/history';
import objectFitImages from 'object-fit-images';

import Header from 'components/Header';
import Loader from 'components/Loader';
import Icon from 'components/Icon';
import LogoFooter from 'components/LogoFooter';
import responsive from 'components/hoc/responsive';
import * as cssVariable from 'cssVariables';
import { fetchObjects } from 'api/search';

import NewCanvas from './new-canvas.svg';
import NewCanvasMobile from './new-canvas-mobile.svg';
import Grid from './grid.svg';

const BASE_STATICS_URL = process.env.REACT_APP_BASE_STATICS_URL;
const EXPORT_CANVAS_IMAGE_WIDTH = 1024;

export default class CanvasEditor extends Component {
  static contextTypes = { lang: PropTypes.string };

  clipboard = null;

  state = {
    unsavedChanges: false,
    isEmpty: true,
    isLoading: true,
    isLoadingImage: false,
    countOfLoadingImages: 0,
    isActiveSelection: false,
    enabledGrid: false,
    objectMetadata: {},
  };

  async componentDidMount() {
    // This fixes issues for IE11
    // Polyfill needs to be initialized after components are mounted
    objectFitImages();

    fabric.Object.prototype.set({
      transparentCorners: false,
      rotatingPointOffset: 30,
      borderColor: 'black',
      cornerColor: 'black',
      cornerStyle: 'circle',
      cornerSize: fabric.isTouchSupported ? 30 : 10,
    });

    this.canvas = new fabric.Canvas(this.canvasElement, {
      allowTouchScrolling: false,
      backgroundColor: '#f3f1ed',
      distance: 110,
      param: {
        stroke: '#DBD9D6',
        strokeWidth: 1,
        selectable: false,
      },
    });

    this.canvas.on('drop', this.handleDrop);
    this.canvas.on('mouse:down', this.handleMouseDown);
    this.canvas.on('object:selected', this.handleSelectedObject);
    this.canvas.on('selection:updated', this.handleSelectedObject);
    this.canvas.on('selection:created', this.handleSelectedObject);

    this.canvas.selectionColor = 'rgba(0,0,0,.05)';
    this.canvas.selectionBorderColor = 'black';
    this.canvas.selectionLineWidth = 2;

    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('resize', this.setCanvasDimensions);

    await Promise.all([this.loadCanvasData(), this.fetchObjectData()]);
    this.setState({ isLoading: false });

    for (const vondstnummer of this.props.data.addToCanvas) {
      this.addToCenter({ vondstnummer, width: 100 });
      this.props.addedToCanvas(vondstnummer);
      this.requestSaveChanges();
    }
  }

  loadCanvasData() {
    return new Promise(resolve => {
      this.setCanvasDimensions();
      if (this.props.data.json) {
        this.canvas.loadFromJSON(this.props.data.json, () => {
          this.canvas.renderAll();
          const isEmpty = this.canvas.getObjects().length <= 0;
          this.setSaveListeners();
          this.setState({
            isEmpty,
            unsavedChanges: true,
          });
          this.setCanvasDimensions();
          resolve();
        });
      } else {
        this.setSaveListeners();
        resolve();
      }
    });
  }

  async fetchObjectData() {
    const vondstnummers = _.uniq([
      ...this.props.data.collectedVondstnummers,
      ...featuredVondstnummers,
    ]);

    const { objects: fetchedObjects } = await fetchObjects({ vondstnummers });
    const objectMetadata = fetchedObjects.reduce((result, obj) => {
      result[obj.vondstnummer] = obj.object;
      return result;
    }, {});
    this.setState({ objectMetadata });
  }

  setSaveListeners = () => {
    this.canvas.on('canvas:cleared', this.onCanvasChanged);
    this.canvas.on('object:added', this.onCanvasChanged);
    this.canvas.on('object:modified', this.onCanvasChanged);
    this.canvas.on('object:removed', this.onCanvasChanged);
    this.canvas.on('path:created', this.onCanvasChanged);
    this.canvas.on('text:changed', this.onCanvasChanged);

    this.canvas.on('selection:created', ({ target }) => {
      if (target.type === 'textbox') {
        target.setControlVisible('mb', false);
        target.setControlVisible('mt', false);
      } else if (target.type === 'path') {
        target.setControlVisible('ml', false);
        target.setControlVisible('mr', false);
        target.setControlVisible('tl', false);
        target.setControlVisible('br', false);
        target.setControlVisible('bl', false);
        target.setControlVisible('tr', false);
        target.setControlVisible('mt', false);
        target.setControlVisible('mb', false);
        target.setControlVisible('mtr', false);

        const yDelta = Math.abs(target.aCoords.br.y - target.aCoords.tl.y);
        const xDelta = Math.abs(target.aCoords.br.x - target.aCoords.tl.x);

        if (xDelta > yDelta) {
          // horizontal
          target.setControlVisible('ml', true);
          target.setControlVisible('mr', true);
        } else {
          // vertical
          target.setControlVisible('mt', true);
          target.setControlVisible('mb', true);
        }
      }
      this.setState({ isActiveSelection: true });
    });
    this.canvas.on('selection:cleared', () =>
      this.setState({ isActiveSelection: false }),
    );

    this.canvas.on('text:editing:entered', () => (this.isEditingText = true));
    this.canvas.on('text:editing:exited', () => (this.isEditingText = false));

    this.canvas.on('path:created', e => {
      this.canvas.remove(e.path);
    });

    if (fabric.isTouchSupported) {
      window.addEventListener('touchstart', this.pencilStart);
      window.addEventListener('touchmove', this.pencilTouchMove);
      window.addEventListener('touchend', this.pencilEnd);
    } else {
      this.canvas.on('mouse:down', this.pencilStart);
      this.canvas.on('mouse:move', this.pencilMouseMove);
      this.canvas.on('mouse:up', this.pencilEnd);
    }
  };

  pencilStart = e => {
    if (!this.canvas.isDrawingMode) {
      return;
    }
    this.startDrawingPath = true;
  };

  pencilTouchMove = e => {
    this.pencilMove(
      e.touches[0].clientX,
      e.touches[0].clientY + window.scrollY,
    );
  };

  pencilMouseMove = ({ e }) => {
    this.pencilMove(e.clientX, e.clientY + window.scrollY);
  };

  pencilMove(moveX, moveY) {
    if (!this.canvas.isDrawingMode || !this.startDrawingPath) {
      return;
    }
    if (this.startDrawingPath && !this.lineStartPoint) {
      this.lineStartPoint = { x: moveX, y: moveY };
    }
    const lineEndPoint = { x: moveX, y: moveY };

    const xDelta = lineEndPoint.x - this.lineStartPoint.x;
    const yDelta = lineEndPoint.y - this.lineStartPoint.y;

    const x1 = this.lineStartPoint.x;
    const y1 = this.lineStartPoint.y;

    const isLineHorizontal = Math.abs(xDelta) >= Math.abs(yDelta);

    const x2 = x1 + (isLineHorizontal ? xDelta : 0);
    const y2 = y1 + (isLineHorizontal ? 0 : yDelta);

    this.canvas.remove(this.linePreview);
    this.linePreview = new fabric.Path(`M ${x1} ${y1} L ${x2} ${y2} z`, {
      stroke: 'black',
      strokeWidth: 3,
    });
    this.canvas.add(this.linePreview);

    this.canvas.requestRenderAll();
  }

  pencilEnd = () => {
    if (!this.canvas.isDrawingMode) {
      return;
    }
    this.startDrawingPath = false;
    this.linePreview = null;
    this.lineStartPoint = null;
    this.canvas.isDrawingMode = false;
    this.setState({ isDrawingMode: this.canvas.isDrawingMode });
  };

  componentDidUpdate(prevProps, prevState) {
    this.canvas.requestRenderAll();
  }

  componentWillUnmount() {
    this.canvas.dispose();
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('resize', this.setCanvasDimensions);
    if (fabric.isTouchSupported) {
      window.removeEventListener('touchstart', this.pencilStart);
      window.removeEventListener('touchmove', this.pencilTouchove);
      window.removeEventListener('touchend', this.pencilEnd);
    }
    this.requestSaveChanges.cancel();
  }

  acquireCanvasRef = element => {
    this.canvasElement = element;
  };

  collectionContainerRef = element => {
    this.collectionContainerElement = element;
  };

  onCanvasChanged = e => {
    const isEmpty = this.canvas.getObjects().length <= 0;

    this.setState({ unsavedChanges: true, isEmpty });
    this.requestSaveChanges();
  };

  saveChanges = () => {
    const json = this.canvas.toJSON(['lockUniScaling']);
    const dataUrl = this.canvas.toDataURL({
      format: 'jpeg',
      // export images always at the same size (but with different dimensions)
      multiplier: EXPORT_CANVAS_IMAGE_WIDTH / this.canvas.width,
    });

    const escapedImgBaseUrl = _.escapeRegExp(BASE_STATICS_URL);
    const vondstUrlRegex = new RegExp(
      `(xlink:href="${escapedImgBaseUrl}/vondst/[0-9]+/([A-Z0-9.-]+))`,
      'gi',
    );

    const svg = this.canvas
      .toSVG()
      .replace(
        vondstUrlRegex,
        'style="cursor: pointer;" onclick="window.onBtsCanvasObjectClicked(\'$2\')" $1',
      );

    // Extract all used items from the SVG
    const usedVondstnummers = new Set();
    let match = null;
    while ((match = vondstUrlRegex.exec(svg)) !== null) {
      usedVondstnummers.add(match[2]);
    }

    this.props.save({
      json,
      svg,
      dataUrl,
      usedVondstnummers: Array.from(usedVondstnummers),
      isEmpty: this.state.isEmpty,
    });
    this.setState({ unsavedChanges: false });
  };

  requestSaveChanges = _.debounce(() => {
    if (this.state.isLoading) {
      this.requestSaveChanges();
      return;
    }
    this.saveChanges();
  }, 1000);

  handleKeyDown = e => {
    if (this.isEditingText || !this.canvas.getActiveObject()) {
      return;
    }

    switch (e.key) {
      case 'Backspace':
      case 'Delete':
        e.preventDefault();
        this.deleteSelectedObject();
        break;
      case 'ArrowUp':
        e.preventDefault();
        this.moveSelectedObject({ x: 0, y: -1 });
        break;
      case 'ArrowDown':
        e.preventDefault();
        this.moveSelectedObject({ x: 0, y: 1 });
        break;
      case 'ArrowLeft':
        e.preventDefault();
        this.moveSelectedObject({ x: -1, y: 0 });
        break;
      case 'ArrowRight':
        e.preventDefault();
        this.moveSelectedObject({ x: 1, y: 0 });
        break;
      case 'Tab':
        e.preventDefault();
        this.selectNextObject(e.shiftKey ? -1 : 1);
        break;
      case 'c':
        if (!e.metaKey) {
          return;
        }
        e.preventDefault();
        this.copySelection();
        break;
      case 'v':
        if (!e.metaKey) {
          return;
        }
        e.preventDefault();
        this.pasteClipboard();
        break;
      default:
        return;
    }
  };

  deleteSelectedObject = () => {
    for (const activeObject of this.canvas.getActiveObjects()) {
      this.canvas.remove(activeObject);
    }
    this.canvas.discardActiveObject();
  };

  moveSelectedObject(delta) {
    const activeObject = this.canvas.getActiveObject();
    activeObject.set({
      left: activeObject.left + delta.x,
      top: activeObject.top + delta.y,
    });
    this.canvas.requestRenderAll();
  }

  selectNextObject(delta) {
    const [activeObject] = this.canvas.getActiveObjects();

    const allObjects = this.canvas.getObjects();
    const activeObjectIndex = allObjects.indexOf(activeObject);

    let nextActiveObjectIndex = (activeObjectIndex + delta) % allObjects.length;
    nextActiveObjectIndex =
      nextActiveObjectIndex === -1
        ? allObjects.length - 1
        : nextActiveObjectIndex;
    const nextActiveObject = allObjects[nextActiveObjectIndex];

    this.canvas.setActiveObject(nextActiveObject);
    this.canvas.requestRenderAll();
  }

  toggleGrid = () => {
    this.setState({
      enabledGrid: !this.state.enabledGrid,
    });
  };

  toggleDrawingMode = () => {
    const canvas = this.canvas;
    canvas.isDrawingMode = !canvas.isDrawingMode;
    this.setState({ isDrawingMode: canvas.isDrawingMode });
    canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
    canvas.freeDrawingBrush.color = 'transparent';
    canvas.freeDrawingBrush.width = 1;
  };

  setCanvasDimensions = () => {
    const newCanvasWidth = window.innerWidth;
    const newCanvasHeight = window.innerHeight * 0.8;

    this.canvas.discardActiveObject();

    this.canvas.setDimensions({
      width: newCanvasWidth,
      height: newCanvasHeight,
    });
    this.props.pinDimensions({
      width: this.canvas.width,
      height: this.canvas.height,
    });

    if (!this.canvas.isEmpty()) {
      const group = new fabric.Group(this.canvas.getObjects());
      const bbRect = group.getBoundingRect();
      const bbWidth = bbRect.width;
      const bbHeight = bbRect.height;

      const widthRatio = bbWidth / newCanvasWidth;
      const proportionalHeight = bbHeight / widthRatio;
      const heightRatio = bbHeight / newCanvasHeight;
      const proportionalWidth = bbWidth / heightRatio;

      const zoomFactor = Math.min(
        1,
        proportionalHeight <= newCanvasHeight
          ? proportionalHeight / bbHeight
          : proportionalWidth / bbWidth,
      );

      const newBBWidth = bbWidth * zoomFactor;
      const newBBHeight = bbHeight * zoomFactor;

      const deltaLeft = (newCanvasWidth - newBBWidth) / 2;
      const deltaTop = (newCanvasHeight - newBBHeight) / 2;

      group.set({
        scaleX: group.scaleX * zoomFactor,
        scaleY: group.scaleY * zoomFactor,
        left: deltaLeft,
        top: deltaTop,
      });
      group.setCoords();

      group.ungroupOnCanvas();
    }

    this.canvas.renderAll();
    this.canvas.calcOffset();
    this.requestSaveChanges();
  };

  scrollTo(offset) {
    this.lastScrollPositionBeforeAutoScroll = window.scrollY;
    Velocity(document.body, 'scroll', {
      duration: 600,
      easing: 'ease-out',
      offset: offset,
    });
  }

  scrollToCanvas = e => {
    this.scrollTo(this.canvasElement.getBoundingClientRect().top);
  };

  scrollToCollection = e => {
    this.scrollTo(this.collectionContainerElement.getBoundingClientRect().top);
  };

  handleDrop = async ({ e }) => {
    e.preventDefault();
    const dragData = JSON.parse(e.dataTransfer.getData('text'));
    try {
      this.scrollToCanvas();
      const img = await this.loadImage(dragData.vondstnummer);
      this.canvas.discardActiveObject();
      this.canvas.add(
        img.scaleToWidth(dragData.width, true).set({
          left: e.clientX - dragData.offsetX,
          top:
            e.clientY -
            dragData.offsetY +
            this.lastScrollPositionBeforeAutoScroll,
        }),
      );
    } catch (e) {
      alert(localize('imageLoadingFailed', this.context.lang));
    }
  };

  addToCenter = async ({ vondstnummer, width }) => {
    try {
      this.scrollToCanvas();
      const img = await this.loadImage(vondstnummer);
      this.canvas.discardActiveObject();
      this.canvas.add(img.scaleToWidth(width, true));
      this.canvas.centerObject(img);
    } catch (e) {
      alert(localize('imageLoadingFailed', this.context.lang));
    }
  };

  loadImage(vondstnummer) {
    return new Promise((resolve, reject) => {
      this.setState(({ countOfLoadingImages }) => ({
        isLoadingImage: true,
        countOfLoadingImages: countOfLoadingImages + 1,
      }));
      fabric.Image.fromURL(
        imgUrl(vondstnummer, 600),
        img => {
          this.setState(({ countOfLoadingImages }) => ({
            isLoadingImage: countOfLoadingImages > 1,
            countOfLoadingImages: countOfLoadingImages - 1,
          }));

          if (!img.getElement()) {
            reject(new Error(`Can't load image.`));
          } else {
            resolve(img);
          }
        },
        { lockUniScaling: true, crossOrigin: 'Anonymous' },
      );
    });
  }

  insertNewTextbox = e => {
    if (this.state.isDrawingMode) {
      return;
    }
    const label = localize('canvasDefaultTextBoxText', this.context.lang);
    const txt = new fabric.Textbox(label, {
      fontFamily: 'Maison',
      fontWeight: 'bold',
      fontSize: 26,
    });

    this.canvas.add(
      txt.set({
        left: this.canvas.width / 5 - txt.width / 2,
        top: this.canvas.height / 2 + txt.height / 3,
      }),
    );
    this.canvas.setActiveObject(txt);
    txt.enterEditing();
  };

  copySelection() {
    const activeObject = this.canvas.getActiveObject();
    activeObject.clone(cloned => {
      this.clipboard = cloned;
    });
  }

  pasteClipboard() {
    const clipboard = this.clipboard;
    const canvas = this.canvas;

    if (!clipboard) {
      return;
    }

    clipboard.clone(clonedObj => {
      canvas.discardActiveObject();
      clonedObj.set({
        left: clonedObj.left + 10,
        top: clonedObj.top + 10,
        evented: true,
        lockUniScaling: clonedObj.type === 'image',
      });
      if (clonedObj.type === 'activeSelection') {
        // active selection needs a reference to the canvas.
        clonedObj.canvas = canvas;
        clonedObj.forEachObject(obj => {
          canvas.add(obj);
        });
        // this should solve the unselectability
        clonedObj.setCoords();
      } else {
        canvas.add(clonedObj);
      }
      clipboard.top += 10;
      clipboard.left += 10;
      canvas.setActiveObject(clonedObj);
      canvas.requestRenderAll();
    });
  }

  handleSelectedObject = () => {
    this.canvas.bringToFront(this.canvas.getActiveObject());
  };

  renderLink() {
    return (
      <Link href={routeUtils.objectOverview()} className="icon-hover-right">
        <LocalizedString string="toObjectsLink" />
        <Icon iconName="arrowRight" color="black" />
      </Link>
    );
  }

  render() {
    const { collectedVondstnummers } = this.props.data;
    const {
      isEmpty,
      isActiveSelection,
      enabledGrid,
      isDrawingMode,
    } = this.state;

    return (
      <Wrapper>
        <Header
          hideLogo={!isEmpty}
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}
        />
        <ContentWrapper id="content-wrapper" role="main">
          <PageTitleHeading>
            <LocalizedString string="canvasOverviewHeaderDIY" />
          </PageTitleHeading>
          <CanvasContainer
            isEmpty={isEmpty}
            data-text={localize('emptyCanvasContainerText', this.context.lang)}
          >
            <p className="visually-hidden">
              {localize('accessibilityLabelCanvas', this.context.lang)}
            </p>
            <canvas
              ref={this.acquireCanvasRef}
              aria-label={localize(
                'emptyCanvasContainerText',
                this.context.lang,
              )}
              role="img"
            />
            {this.state.isLoading && <Loader />}
            {!isEmpty && this.renderMessage()}
            {enabledGrid && this.renderGrid()}
            <Publishing>
              <PublishingOptions>
                <Tool
                  title={localize('textToolHoverText', this.context.lang)}
                  onClick={this.insertNewTextbox}
                  className="icon-hover-shrink"
                >
                  <IconWrapper grayedOut={isDrawingMode}>
                    <span aria-hidden="true">Aa</span>
                  </IconWrapper>
                </Tool>
                <Tool
                  active={isDrawingMode}
                  title={localize('pencilToolHoverText', this.context.lang)}
                  onClick={this.toggleDrawingMode}
                  className="icon-hover-shrink"
                >
                  <Icon
                    iconName="line"
                    color={
                      isDrawingMode
                        ? cssVariable.colorBlack
                        : cssVariable.colorWhite
                    }
                    iconSize={cssVariable.iconSizes.medium}
                    title="pencilToolHoverText"
                  />
                </Tool>
                <Tool
                  title={localize('gridToolHoverText', this.context.lang)}
                  onClick={this.toggleGrid}
                  className="icon-hover-shrink"
                  hideOnSmallScreen
                >
                  <Icon
                    iconName="grid"
                    iconSize={cssVariable.iconSizes.medium}
                    title="gridToolHoverText"
                  />
                </Tool>
                <Tool
                  title={localize('clearOneToolHoverText', this.context.lang)}
                  onClick={this.deleteSelectedObject}
                  className="icon-hover-shrink"
                >
                  <IconWrapper grayedOut={isDrawingMode || !isActiveSelection}>
                    <Icon
                      iconName="trash"
                      iconSize={cssVariable.iconSizes.medium}
                      title="clearOneToolHoverText"
                    />
                  </IconWrapper>
                </Tool>
              </PublishingOptions>
              <PublishingOptions>
                {!isEmpty && (
                  <Button
                    onClick={this.props.publish}
                    className="icon-hover-right"
                    hideIconOnSmallScreen
                  >
                    <LocalizedString string="publishButtonCaption" />{' '}
                    <Icon iconName="arrowRight" />
                  </Button>
                )}
              </PublishingOptions>
            </Publishing>
          </CanvasContainer>

          <CollectionContainer innerRef={this.collectionContainerRef}>
            <CollectionWrapper>
              <CollectionHeader onClick={this.scrollToCollection}>
                <CollectionTitle>
                  <LocalizedString
                    string="collectedObjectsCount"
                    values={{ count: collectedVondstnummers.length }}
                  />
                </CollectionTitle>
              </CollectionHeader>
              {(collectedVondstnummers.length > 0 && (
                <div>
                  <Collection>
                    {collectedVondstnummers.map(vondstnummer => (
                      <CollectionItem
                        key={`collected-${vondstnummer}`}
                        vondstnummer={vondstnummer}
                        object={this.state.objectMetadata[vondstnummer]}
                        onAdd={this.addToCenter}
                      />
                    ))}
                  </Collection>
                  <LinkWrapper>
                    {this.renderLink()}
                    <Link
                      href={routeUtils.printObjectCollection()}
                      className="icon-hover-right"
                    >
                      <LocalizedString string="printCollectedObjects" />
                      <Icon
                        iconName="download"
                        iconSize={cssVariable.iconSizes.medium}
                        color="black"
                        title="iconTitleDownload"
                      />
                    </Link>
                  </LinkWrapper>
                </div>
              )) || (
                <div>
                  <p>
                    <LocalizedString string="noCollectedObjectsText" />
                  </p>
                  {this.renderLink()}
                </div>
              )}
            </CollectionWrapper>

            <CollectionWrapper>
              <CollectionTitle>
                <LocalizedString string="precollectedObjectsTitle" />
              </CollectionTitle>
              <Collection>
                {featuredVondstnummers.map(vondstnummer => (
                  <CollectionItem
                    key={`mostused-${vondstnummer}`}
                    vondstnummer={vondstnummer}
                    object={this.state.objectMetadata[vondstnummer]}
                    onAdd={this.addToCenter}
                  />
                ))}
              </Collection>
            </CollectionWrapper>
          </CollectionContainer>
        </ContentWrapper>
        <LogoFooter />
      </Wrapper>
    );
  }

  renderMessage() {
    if (this.state.isLoadingImage) {
      return (
        <Message>
          <LocalizedString string="canvasIsLoadingMessage" />
        </Message>
      );
    }
    if (this.state.unsavedChanges) {
      return (
        <Message>
          <LocalizedString string="canvasHasUnsavedChangesMessage" />
        </Message>
      );
    }
  }

  renderGrid() {
    return <GridOverlay />;
  }
}

function imgUrl(vondstnummer, size = 200) {
  return `${BASE_STATICS_URL}/vondst/${size}/${vondstnummer}(01).png`;
}

class CollectionItem extends Component {
  acquireImageRef = ref => {
    this.imageRef = ref;
  };

  handleDragStart = e => {
    const data = JSON.stringify({
      offsetX: e.nativeEvent.offsetX,
      offsetY: e.nativeEvent.offsetY,
      width: this.imageRef.width,
      vondstnummer: this.props.vondstnummer,
    });

    e.dataTransfer.setData('text', data);
  };

  handleClick = e => {
    this.props.onAdd({
      vondstnummer: this.props.vondstnummer,
      width: this.imageRef.width,
    });
  };

  handleKeyPress = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.handleClick(event);
    }
  };

  render() {
    const { vondstnummer } = this.props;
    return (
      <CollectionItemContainer
        tabIndex="0"
        role="button"
        onKeyPress={this.handleKeyPress}
      >
        <CollectionItemImage
          innerRef={this.acquireImageRef}
          key={vondstnummer}
          src={imgUrl(vondstnummer)}
          alt=""
          onDragStart={this.handleDragStart}
          onClick={this.handleClick}
        />
        <AccessibilityLabel className="visually-hidden">
          {this.props.object}.
        </AccessibilityLabel>
      </CollectionItemContainer>
    );
  }
}

const Wrapper = glamorous.section({
  overflowY: 'hidden',
});

const ContentWrapper = glamorous.div();

const CanvasContainer = responsive.div(
  {
    background: `#f3f1ed url(${NewCanvas}) no-repeat center center`,
    backgroundSize: '50%',
    position: 'relative',
    top: 0,
    left: 0,
    '::after': {
      content: 'attr(data-text)',
      position: 'absolute',
      top: '70%',
      left: '50%',
      maxWidth: 380,
      textAlign: 'center',
      fontWeight: cssVariable.fontWeights.bold,
      color: '#C8C6C3',
      transform: 'translate(-50%, -50%)',
    },
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      backgroundImage: `url(${NewCanvasMobile})`,
      backgroundSize: '75%',
      '::after': {
        top: '80%',
      },
    },
  ({ browser }) =>
    browser.fits.extremelySmall && {
      '::after': {
        width: '80vw',
      },
    },
  ({ isEmpty }) => ({
    '> .canvas-container': {
      transition: 'opacity .2s ease-out',
      opacity: isEmpty ? 0 : 1,
    },
    '::after': {
      transition: 'opacity .2s ease-out',
      opacity: !isEmpty ? 0 : 1,
    },
  }),
);

const GridOverlay = responsive.div(
  {
    background: `url(${Grid}) repeat 50px 50px`,
    backgroundSize: '100px',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      backgroundSize: '50px',
      backgroundPosition: '25px 25px',
    },
);

const CollectionWrapper = responsive.div({ marginBottom: 72 });

const Publishing = responsive.div(
  {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    padding: `8px ${cssVariable.gridMargins.desktop}px 24px`,
    pointerEvents: 'none',
  },
  ({ browser }) =>
    browser.fits.medium && {
      padding: `8px ${cssVariable.gridMargins.tablet}px 24px`,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      padding: `8px ${cssVariable.gridMargins.mobile}px 24px`,
    },
);

const PublishingOptions = glamorous.div({
  display: 'flex',
});

const Button = responsive.button(
  {
    border: 'none',
    color: 'white',
    fontWeight: cssVariable.fontWeights.bold,
    pointerEvents: 'all',
    height: 50,
    fontSize: 18,
    padding: '0 24px',
    backgroundColor: 'black',
    '> svg': {
      height: 18,
      marginLeft: 16,
      verticalAlign: 'middle',
    },
  },
  ({ hideIconOnSmallScreen, browser }) =>
    hideIconOnSmallScreen &&
    browser.fits.extremelySmall && {
      '> svg': {
        display: 'none',
      },
    },
);

const Tool = responsive.button(
  {
    padding: 0,
    width: 50,
    height: 50,
    marginRight: 1,
    border: 'none',
    color: cssVariable.colorWhite,
    fontWeight: cssVariable.fontWeights.bold,
    pointerEvents: 'all',
    fontSize: 20,
    lineHeight: '20px',
    backgroundColor: cssVariable.colorBlack,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '> svg': {
      verticalAlign: 'middle',
    },
  },
  ({ active }) =>
    active && {
      backgroundColor: cssVariable.colorWhite,
      color: cssVariable.colorBlack,
    },
  ({ hideOnSmallScreen, browser }) =>
    hideOnSmallScreen &&
    browser.fits.extraSmall && {
      display: 'none',
    },
);

const IconWrapper = glamorous.div(({ grayedOut }) => ({
  opacity: grayedOut ? 0.5 : 1,
  width: 20,
  height: 20,
  margin: 'auto',
  flex: '1 0 auto',
}));

const Message = responsive.div(
  {
    position: 'absolute',
    top: 26,
    left: cssVariable.gridMargins.desktop,
    fontSize: 18,
    fontWeight: cssVariable.fontWeights.bold,
  },
  ({ browser }) =>
    browser.fits.medium && {
      left: cssVariable.gridMargins.tablet,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      left: cssVariable.gridMargins.mobile,
    },
);

const CollectionContainer = responsive.div(
  {
    background: 'white',
    padding: `26px ${cssVariable.gridMargins.desktop}px`,
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
  },
  ({ browser }) =>
    browser.fits.medium && {
      padding: `10px ${cssVariable.gridMargins.tablet}px`,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      padding: `10px ${cssVariable.gridMargins.mobile}px`,
    },
);

const Collection = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
});

const CollectionHeader = glamorous.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const CollectionTitle = responsive.h2(
  {
    fontSize: 26,
    marginTop: 0,
    textTransform: 'uppercase',
    fontWeight: cssVariable.fontWeights.bold,
  },
  ({ browser }) =>
    browser.fits.medium && {
      fontSize: 22,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      fontSize: 18,
      lineHeight: 1.88,
      display: 'inline-block',
    },
);

const CollectionItemContainer = responsive.div(
  {
    width: `calc(${100 / 10}% - 40px)`,
    margin: 20,
    position: 'relative',
    '::before': {
      content: ' ',
      width: '100%',
      paddingTop: '100%',
      display: 'block',
    },
  },
  ({ browser }) =>
    browser.fits.large && {
      width: `calc(${100 / 8}% - 40px)`,
    },
  ({ browser }) =>
    browser.fits.medium && {
      width: `calc(${100 / 6}% - 40px)`,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      width: `calc(${100 / 3}% - 40px)`,
    },
);

const CollectionItemImage = glamorous.img({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  fontFamily: '"object-fit: contain"', // Dirty IE11 polyfill fix
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  left: 0,
});

const LinkWrapper = responsive.div(
  {
    display: 'flex',
    flexWrap: 'wrap',
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      '> a': {
        padding: '0 0 10px 0',
      },
    },
);

const Link = glamorous.a({
  padding: '16px 0',
  marginRight: 20,
  fontSize: 18,
  fontWeight: cssVariable.fontWeights.bold,
  '> svg': {
    height: 18,
    marginLeft: 10,
    verticalAlign: 'middle',
  },
});

const AccessibilityLabel = glamorous.p();
