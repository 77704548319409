import responsive from 'components/hoc/responsive';

import * as cssVariable from 'cssVariables';

export default responsive.div(
  {
    paddingRight: cssVariable.gridMargins.desktop,
    paddingLeft: cssVariable.gridMargins.desktop,
  },
  ({ browser }) =>
    browser.fits.medium && {
      paddingRight: cssVariable.gridMargins.tablet,
      paddingLeft: cssVariable.gridMargins.tablet,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      paddingRight: cssVariable.gridMargins.mobile,
      paddingLeft: cssVariable.gridMargins.mobile,
    },
);
