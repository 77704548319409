import React, { PureComponent } from 'react';
import glamorous from 'glamorous';
import { NavLink } from 'react-router-dom';

import SmoothImage from 'components/util/SmoothImage';
import LocalizedString from 'components/LocalizedString';

import { routeUtils } from 'lib/history';
import responsive from 'components/hoc/responsive';
import * as cssVariable from 'cssVariables';
import { Fade } from 'components/util/Animations';
import { getCurrentQueryParams, stringifyQueryParams } from 'lib/history';

const IMAGE_SIZE = 200;

export default class Result extends PureComponent {
  parseQuery() {
    const query = getCurrentQueryParams();
    query.index = this.props.index;

    return stringifyQueryParams(query);
  }

  render() {
    return (
      <Container minWidth={this.props.minWidth} columns={this.props.columns}>
        {this.props.hit && (
          <Link
            to={{
              pathname: `${routeUtils.objectDetail({
                vondstnummer: this.props.hit.vondstnummer,
              })}`,
              search: this.parseQuery(),
            }}
          >
            <Content>
              <SmoothImage
                imageSize={IMAGE_SIZE}
                image={`${this.props.hit.vondstnummer}(01).png`}
                sqip={this.props.hit.sqip}
                imageDidLoad={this.props.imageDidLoad}
              />
              <Label>
                <span className="visually-hidden">
                  {this.props.hit.object},
                  <LocalizedString string="objectnumber" />:
                </span>
                {this.props.hit.generated_title}
              </Label>
            </Content>
          </Link>
        )}
      </Container>
    );
  }
}

const Container = responsive.li(
  {
    flex: '0 0 auto',
    position: 'relative',
    '&::before': {
      content: ' ',
      display: 'block',
      width: '100%',
      paddingTop: '100%',
    },
  },
  ({ minWidth, columns }) => ({
    width: `${100 / columns}%`,
    minWidth: minWidth,
  }),
);

const Link = responsive(NavLink, {
  rootEl: 'a',
  forwardProps: ['to', 'exact'],
})({
  display: 'block',
  animation: `${Fade} 0.2s forwards ease-out`,
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
});

const Content = glamorous.div({
  padding: '3vw',
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

const Label = responsive.p(
  {
    fontSize: 11,
    fontFamily: cssVariable.fontFamilies.maisonMono,
    fontWeight: cssVariable.fontWeights.regular,
    textAlign: 'center',
    margin: '1vw 0 0',
  },
  ({ browser }) =>
    browser.fits.extraSmall
      ? {
          display: 'none',
        }
      : null,
);
