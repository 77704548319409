import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';

import { getPersistor } from 'store';

export default function withPersistGate(Component) {
  return props => (
    <PersistGate loading={<LoadingComponent />} persistor={getPersistor()}>
      <Component {...props} />
    </PersistGate>
  );
}

const LoadingComponent = () => <p>Loading...</p>;
