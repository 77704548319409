import qs from 'querystringify';
import promiseContainer from 'components/hoc/promiseContainer';
import withBrowserState from 'components/hoc/withBrowserState';
import { featuredCanvas, mostRecentCanvas } from 'api/canvas';

import CanvasOverview from './CanvasOverview';

function mapPropsToPromises(props) {
  const params = qs.parse(props.location.search);
  const columnCount = getColumnCount(props.browser);
  const limit = columnCount * 10;

  return {
    ...props,
    featured: featuredCanvas(),
    currentPageToken: params.pageToken,
    offset: parseInt(params.offset, 10) || 0,
    mostRecent: mostRecentCanvas(params.pageToken, limit),
    columnCount,
    limit,
  };
}

export default withBrowserState(
  promiseContainer(mapPropsToPromises)(CanvasOverview),
);

function getColumnCount(browser) {
  let featuredAmount = 5;

  if (browser.greaterThan.extremelyLarge) {
    featuredAmount = 6;
  }

  if (browser.fits.extraLarge) {
    featuredAmount = 4;
  }

  if (browser.fits.medium) {
    featuredAmount = 3;
  }

  if (browser.fits.extraSmall) {
    featuredAmount = 2;
  }

  if (browser.fits.extraExtraSmall) {
    featuredAmount = 3;
  }

  return featuredAmount;
}
