import glamorous from 'glamorous';

export function getAspectContainerStyle({ width, height }) {
  return {
    position: 'relative',
    width: '100%',
    paddingBottom: `${height / width * 100}%`,
    '> *': {
      position: 'absolute!important',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  };
}

export default glamorous.div(getAspectContainerStyle);
