const MICRIO_V1_SCRIPT_URL = process.env.REACT_APP_MICRIO_V1_SCRIPT_URL;

export default class MicrioLoader {
  static async load() {
    if (global.Micrio) {
      return;
    }

    return this.fetchMicrio();
  }

  static fetchMicrio = async () => {
    return new Promise(async (resolve, reject) => {
      const script = document.createElement('script');
      script.async = true;
      script.src = MICRIO_V1_SCRIPT_URL;
      script.onload = () => resolve();
      script.onerror = () => reject();

      document.head.appendChild(script);
    });
  };
}
