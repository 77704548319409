import React, { Component } from 'react';
import glamorous from 'glamorous';
import responsive from 'components/hoc/responsive';

import * as cssVariable from 'cssVariables';

function renderBottomDate(topDate, bottomDate) {
  if (topDate && bottomDate && topDate !== bottomDate) {
    return (
      <span>
        <MiddleContainer>
          <DateYear>&nbsp;</DateYear>
          <SmallDateBar />
        </MiddleContainer>
        <BottomContainer>
          <DateYear>{bottomDate}</DateYear>
          <DateBar />
        </BottomContainer>
      </span>
    );
  } else {
    return null;
  }
}
export default class ObjectDetailDate extends Component {
  render() {
    const { topDate, bottomDate } = this.props;

    if (!topDate && !bottomDate) {
      return null;
    }

    return (
      <Container>
        <TopContainer>
          <DateYear>{topDate || bottomDate}</DateYear>
          <DateBar />
        </TopContainer>
        {renderBottomDate(topDate, bottomDate)}
      </Container>
    );
  }
}

const containerBase = {
  position: 'absolute',
  right: 0,
  display: 'flex',
};

const Container = glamorous.span({
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 10,
});

const TopContainer = glamorous.div({
  ...containerBase,
  top: 50,
});

const MiddleContainer = glamorous.div({
  ...containerBase,
  top: 100,
});

const BottomContainer = glamorous.div({
  ...containerBase,
  top: 150,
});

const DateYear = responsive.div(
  {
    display: 'inline-block',
    marginRight: 26,
    ...cssVariable.title,
  },
  ({ browser }) =>
    browser.fits.medium && {
      marginRight: 13,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      fontSize: 30,
    },
);

const DateBar = responsive.div(
  {
    backgroundColor: cssVariable.colorBlack,
    display: 'inline-block',
    width: 40,
    height: 7,
    alignSelf: 'center',
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      width: 22,
      height: 4,
    },
);

const SmallDateBar = responsive.div(
  {
    backgroundColor: cssVariable.colorBlack,
    display: 'inline-block',
    width: 20,
    height: 7,
    alignSelf: 'center',
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      width: 11,
      height: 4,
    },
);
