const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
const STATICS_ENDPOINT = process.env.REACT_APP_BASE_STATICS_URL;

export async function getJSON(path: string): Promise<Object> {
  const response = await fetch(`${BACKEND_ENDPOINT}${path}`);

  thowOnError(response);

  return await response.json();
}

export async function getStaticJSON(path: string): Promise<Object> {
  const response = await fetch(`${STATICS_ENDPOINT}${path}`);

  thowOnError(response);

  return await response.json();
}

export async function postJSON(path: string, body: ?Object) {
  const response = await fetch(`${BACKEND_ENDPOINT}${path}`, {
    method: 'POST',
    body: body && JSON.stringify(body),
  });

  thowOnError(response);

  return response;
}

export function thowOnError(response: Response) {
  if (!response.ok) {
    throw new HttpError(response.status, response.statusText);
  }
}

export class HttpError extends Error {
  statusCode: number;
  constructor(statusCode: number, message: string) {
    super(message);
    this.statusCode = statusCode;
  }
}
