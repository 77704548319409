import { connect } from 'react-redux';

import FacetFilter from './FacetFilter';

function mapStateToProps(state, ownProps) {
  const { searchFilters } = state;
  return { value: searchFilters[ownProps.field] };
}

export default connect(mapStateToProps)(FacetFilter);
