import { connect } from 'react-redux';

import promiseContainer from 'components/hoc/promiseContainer';
import withPersistGate from 'components/hoc/withPersistGate';
import { fetchObjects } from 'api/search';

import PrintObjectCollection from './PrintObjectCollection';

function mapStateToProps(state) {
  return {
    vondstnummers: state.canvasEditor.collectedVondstnummers,
  };
}

function mapPropsToPromises({ vondstnummers }) {
  return {
    vondstnummers,
    objectCollection: fetchObjects({ vondstnummers }),
  };
}

export default withPersistGate(
  connect(mapStateToProps)(
    promiseContainer(mapPropsToPromises)(PrintObjectCollection),
  ),
);
