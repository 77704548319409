import { connect } from 'react-redux';

import { setDateFilter } from 'actions/search';
import HistogramFilter from './HistogramFilter';

function mapStateToProps(state, ownProps) {
  const { searchFilters, histogramPreview } = state;
  const { periods } = ownProps;
  const from =
    histogramPreview.from ||
    searchFilters.from ||
    periods[periods.length - 1].from;
  const to = histogramPreview.to || searchFilters.to || periods[0].to;

  const countOfActivePeriods = periods.reduce(
    (sum, period) => sum + (period.doc_count > 0 ? 1 : 0),
    0,
  );

  const isEmptyHistogram = countOfActivePeriods <= 1;

  return {
    fromYear: from,
    from: getFromIndex(from, periods),
    toYear: to,
    to: getToIndex(to, periods),
    isEmptyHistogram,
  };
}

function getToIndex(to, periods) {
  return to ? periods.findIndex(period => period.to <= to) : 0;
}

function getFromIndex(from, periods) {
  const index = from
    ? [...periods].reverse().findIndex(period => period.from >= from)
    : 0;
  return periods.length - index - 1;
}

const mapDispatchToProps = {
  setDateFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(HistogramFilter);
