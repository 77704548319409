import React, { PureComponent } from 'react';
import glamorous from 'glamorous';

import { fetchSearch } from 'api/search';
import { DefaultErrorComponent } from 'components/hoc/promiseContainer';
import { SEARCH_RESULT_SIZE } from 'config';

import Header from 'components/Header';
import Filters from 'components/Filters';
import Loader from 'components/Loader';
import SearchResults from 'components/SearchResults';
import Timeline from 'components/Timeline';
import LocalizedString from 'components/LocalizedString';
import PageTitleHeading from 'components/util/PageTitleHeading';
import responsive from 'components/hoc/responsive';

const VIEWPORT_THRESHOLD = 2;
const TRANSITION_SPEED = 200;

export default class ObjectOverview extends PureComponent {
  state = {
    resultsHeight: null,
    filtersOpen: false,
    filtersWidth: 380,
    columnCount: 6,
    columnHeight: 124,
    gridOffset: 240,
  };

  componentDidMount() {
    this.props.loadData(true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.search !== prevProps.search && this.resultsElement) {
      this.setState({ resultsHeight: this.resultsElement.clientHeight });
    }
  }

  resultsRef = element => {
    this.resultsElement = element;
  };

  handleMissingHits = async missingHitIndices => {
    const avgIndex = Math.round(
      (Math.min(...missingHitIndices) + Math.max(...missingHitIndices)) / 2,
    );
    const offset = avgIndex - Math.round(SEARCH_RESULT_SIZE / 2);

    const searchResult = await fetchSearch(
      this.props.searchFilters,
      offset,
      SEARCH_RESULT_SIZE,
    );
    return searchResult.hits;
  };

  onFiltersToggle = (opened, width) => {
    this.setState({ filtersOpen: opened, filtersWidth: width });
  };

  gridDidChange = (columnCount, columnHeight, gridOffset) => {
    this.setState({ columnCount, columnHeight, gridOffset });
  };

  render() {
    const { search, browser } = this.props;
    const { filtersOpen, filtersWidth } = this.state;
    const { result, fetchError, visibleFilters } = search;

    if (fetchError) {
      return <DefaultErrorComponent error={fetchError} />;
    }

    if (result) {
      return (
        <Wrapper filtersOpen={filtersOpen} filtersWidth={filtersWidth}>
          <Header />
          <ContentWrapper id="content-wrapper" role="main">
            <PageTitleHeading>
              <LocalizedString string="objects" />
            </PageTitleHeading>
            <Search>
              <SearchResults
                ref={this.resultsRef}
                total={result.hits_total}
                hits={result.hits}
                handleMissingHits={this.handleMissingHits}
                threshold={VIEWPORT_THRESHOLD}
                onGridChange={this.gridDidChange}
              />
              <Timeline
                periods={result.periods}
                total={result.hits_total}
                columnCount={this.state.columnCount}
                columnHeight={this.state.columnHeight}
                gridOffset={this.state.gridOffset}
              />
              <Filters
                histogram={result.histogram}
                total={result.hits_total}
                filters={visibleFilters}
                onToggle={this.onFiltersToggle}
                browser={browser}
              />
            </Search>
          </ContentWrapper>
        </Wrapper>
      );
    }

    return <Loader />;
  }
}

const Wrapper = responsive.section(
  {
    transition: `margin ${TRANSITION_SPEED}ms ease`,
    willChange: 'margin',
  },
  ({ filtersOpen, filtersWidth, browser }) =>
    filtersOpen &&
    !browser.fits.extraExtraSmall && {
      marginLeft: filtersWidth,
    },
);

const ContentWrapper = glamorous.div();

const Search = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  paddingRight: 16,
});
