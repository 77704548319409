import { connect } from 'react-redux';

import { openMenu, closeMenu } from 'actions/menu';
import Menu from './Menu';

function mapStateToProps(state, ownProps) {
  const { menu } = state;
  return {
    isMenuOpen: menu.isOpen,
  };
}

const mapDispatchToProps = {
  openMenu,
  closeMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
