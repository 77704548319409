import React, { Component } from 'react';
import glamorous from 'glamorous';
import history, { getCurrentParams } from 'lib/history';

import Container from 'components/util/Container';
import Header from 'components/Header';
import Icon from 'components/Icon';
import LogoFooter from 'components/LogoFooter';

import isIOS from 'lib/isIOS';
import * as cssVariable from 'cssVariables';
import responsive from 'components/hoc/responsive';

const BASE_STATICS_URL = process.env.REACT_APP_BASE_STATICS_URL;
const iOS = isIOS();

export default class HomePage extends Component {
  state = {
    currentItem: 0,
  };

  blockVideoElements = [];
  lang = getCurrentParams()['lang'] || 'nl';

  componentDidMount() {
    this.interval = setInterval(this.loopThroughItems, 3000);
    this.blockVideoElements.forEach(video => {
      video.play();
      video.addEventListener('mouseover', this.playVideo, false);
      video.addEventListener('mouseout', this.pauseVideo, false);
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.blockVideoElements.forEach(video => {
      video.removeEventListener('mouseover', this.playVideo, false);
      video.removeEventListener('mouseout', this.pauseVideo, false);
    });
  }

  loopThroughItems = () => {
    const { heroItems } = this.props.content;

    if (this.state.currentItem >= heroItems.length - 1) {
      this.setState({
        currentItem: 0,
      });
      return;
    }

    this.setState({
      currentItem: this.state.currentItem + 1,
    });
  };

  playVideo = e => {
    e.target.play();
  };

  pauseVideo = e => {
    e.target.pause();
  };

  resolvePath(path) {
    return `/${this.lang}${path}`;
  }

  renderHero() {
    const { content } = this.props;
    const { currentItem } = this.state;

    return (
      <HeroWrapper>
        <HeroImages>
          {content.heroItems[currentItem] && (
            <HeroItem
              src={`${BASE_STATICS_URL}/vondst/600/${content.heroItems[currentItem]}(01).png`}
              alt=""
              role="presentation"
            />
          )}
        </HeroImages>
        <HeroBlock>
          <HeroTitle>{content.heroText}</HeroTitle>
          <ButtonWrapper>
            <BlackButton
              onClick={() =>
                history.push(this.resolvePath(content.heroButtonPath))
              }
              className="icon-hover-right"
            >
              {content.heroButtonText} <Icon iconName="arrowRight" />
            </BlackButton>
            <Link
              href={this.resolvePath(content.heroLinkPath)}
              className="icon-hover-right"
            >
              {content.heroLinkText}
              <Icon iconName="arrowRight" color="black" />
            </Link>
          </ButtonWrapper>
        </HeroBlock>
      </HeroWrapper>
    );
  }

  renderBelowTheFooter() {
    const {
      content: {
        bottomBlockText,
        bottomBlockMedia,
        bottomBlockPath,
        bottomBlockButtonText,
        bottomBlockLinkPath,
        bottomBlockLinkText,
      },
    } = this.props;

    return (
      <HeroWrapper>
        {bottomBlockMedia.file && (
          <HeroImages>
            <HeroBottomItem
              src={bottomBlockMedia.file.url}
              alt={bottomBlockMedia.file.title}
            />
          </HeroImages>
        )}
        <HeroBlock>
          <HeroTitle>{bottomBlockText}</HeroTitle>
          <ButtonWrapper>
            {!!bottomBlockButtonText && !!bottomBlockPath && (
              <BlackButton
                onClick={() => history.push(this.resolvePath(bottomBlockPath))}
                className="icon-hover-right"
              >
                {bottomBlockButtonText} <Icon iconName="arrowRight" />
              </BlackButton>
            )}
            {!!bottomBlockLinkText && !!bottomBlockLinkPath && (
              <Link
                href={this.resolvePath(bottomBlockLinkPath)}
                className="icon-hover-right"
              >
                {bottomBlockLinkText}
                <Icon iconName="arrowRight" color="black" />
              </Link>
            )}
          </ButtonWrapper>
        </HeroBlock>
      </HeroWrapper>
    );
  }

  blockVideoRef = element => {
    this.blockVideoElements.push(element);
  };

  renderBlockContent(str) {
    const { content } = this.props;
    let fullwidth = false;
    let media = content[str];

    if (iOS) {
      // If ios and alternative content is available: render that.
      // This is because of a bug width video on ios which causes vid not to render
      const node = `${str}Mobile`;
      if (content[node]) {
        fullwidth = true;
        media = content[node];
      }
    }

    if (
      media.file.contentType === 'image/png' ||
      media.file.contentType === 'image/jpeg'
    ) {
      const img = fullwidth ? (
        <BlockImageFull src={media.file.url} alt={media.title} />
      ) : (
        <BlockImage src={media.file.url} alt={media.title} />
      );
      return img;
    }

    return (
      <BlockVideo muted innerRef={this.blockVideoRef}>
        <source src={media.file.url} type={media.file.contentType} />
      </BlockVideo>
    );
  }

  renderFooter() {
    const { content } = this.props;

    return (
      <Footer>
        <FooterBlock>
          {this.renderBlockContent('leftBlockMedia')}
          <Block>
            <BlockTitle>{content.leftBlockText}</BlockTitle>
            <WhiteButton
              onClick={() =>
                history.push(this.resolvePath(content.leftBlockButtonPath))
              }
              className="icon-hover-right"
            >
              {content.leftBlockButtonText}
              <Icon iconName="arrowRight" color="black" />
            </WhiteButton>
          </Block>
        </FooterBlock>
        <FooterBlock background="#f3f1ed">
          {this.renderBlockContent('rightBlockMedia')}
          <Block background="transparent" fullHeight>
            <TextContent>
              <BlockTitle color="black">{content.rightBlockText}</BlockTitle>
              <BlackButton
                onClick={() =>
                  history.push(this.resolvePath(content.rightBlockButtonPath))
                }
                className="icon-hover-right"
              >
                {content.rightBlockButtonText}
                <Icon iconName="arrowRight" />
              </BlackButton>
            </TextContent>
          </Block>
        </FooterBlock>
      </Footer>
    );
  }

  render() {
    return (
      <Wrapper>
        <Header />
        <ContentWrapper id="content-wrapper" role="main">
          <Container>
            {this.renderHero()}
            {this.renderFooter()}
            {this.renderBelowTheFooter()}
          </Container>
        </ContentWrapper>
        <LogoFooter />
      </Wrapper>
    );
  }
}

const Wrapper = glamorous.section();

const ContentWrapper = glamorous.div();

const Footer = responsive.div(
  {
    display: 'flex',
    paddingTop: 50,
  },
  ({ browser }) =>
    browser.fits.small && {
      display: 'block',
    },
);

const FooterBlock = responsive.div(
  ({ background }) => ({
    background: background || 'transparent',
    width: '50%',
    height: 460,
    position: 'relative',
    overflow: 'hidden',
  }),
  ({ browser }) =>
    browser.fits.small && {
      width: '100%',
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      height: 'auto',
      padding: 0,
    },
);

const TextContent = responsive.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  height: '100%',
});

const Block = responsive.div(
  ({ background }) => ({
    width: '50%',
    background: background || 'black',
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: 24,
  }),
  ({ fullHeight }) =>
    fullHeight && {
      top: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
  ({ browser }) =>
    browser.fits.large && {
      width: '75%',
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      width: '100%',
      position: 'static',
    },
);
const BlockTitle = glamorous.h2(({ color }) => ({
  fontSize: 26,
  lineHeight: 1.15,
  textTransform: 'uppercase',
  color: color || 'white',
  marginBottom: 16,
}));

const HeroWrapper = responsive.header(
  {
    minHeight: '40vh',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    position: 'relative',
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      display: 'block',
      height: '100%',
    },
);

const HeroTitle = responsive.h1(
  {
    fontSize: 52,
    lineHeight: 1.15,
    maxWidth: '40vw',
    textTransform: 'uppercase',
  },
  ({ browser }) =>
    browser.fits.large && {
      maxWidth: '50vw',
    },
  ({ browser }) =>
    browser.fits.smallMedium && {
      maxWidth: '70vw',
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      fontSize: 30,
      maxWidth: '100%',
    },
);

const HeroImages = responsive.div(
  {
    position: 'absolute',
    height: '40vh',
    left: '50%',
  },
  ({ browser }) =>
    browser.fits.smallMedium && {
      maxWidth: 300,
      right: 0,
      left: 'auto',
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      position: 'relative',
      left: 0,
      width: '100%',
      display: 'block',
    },
);

const HeroItem = responsive.img(
  {
    height: '100%',
    objectFit: 'contain',
  },
  ({ browser }) =>
    browser.fits.medium && {
      maxWidth: 300,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      maxWidth: 'none',
    },
);

const HeroBottomItem = responsive.img(
  {
    height: '100%',
    objectFit: 'contain',
    paddingTop: '24px',
  },
  ({ browser }) =>
    browser.fits.medium && {
      maxWidth: 300,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      maxWidth: 'none',
    },
);

const HeroBlock = responsive.div(
  {
    paddingRight: '20%',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      paddingRight: 0,
    },
);

const ButtonWrapper = glamorous.div();

const Button = glamorous.button(({ background, color }) => ({
  background,
  color,
  border: 'none',
  fontWeight: cssVariable.fontWeights.bold,
  pointerEvents: 'all',
  height: 50,
  fontSize: 18,
  padding: '0 24px',
  marginRight: 24,
  '> svg': {
    height: 18,
    marginLeft: 16,
    verticalAlign: 'middle',
  },
}));

const BlackButton = Button.withProps({
  background: 'black',
  color: 'white',
});

const WhiteButton = Button.withProps({
  background: 'white',
  color: 'black',
});

const Link = responsive.a(
  {
    padding: '16px 0',
    marginRight: 8,
    fontSize: 18,
    fontWeight: cssVariable.fontWeights.bold,
    '> svg': {
      height: 18,
      marginLeft: 10,
      verticalAlign: 'middle',
    },
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      display: 'block',
    },
);

const BlockVideo = responsive.video(
  {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'block',
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      position: 'static',
    },
);

const BlockImage = responsive.img(
  {
    objectFit: 'cover',
    height: '75%',
    position: 'absolute',
    top: 24,
    right: 24,
    display: 'block',
  },
  ({ browser }) =>
    browser.fits.small && {
      position: 'relative',
      right: 0,
      margin: '24px auto',
      maxHeight: 360,
    },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      maxHeight: 180,
    },
);

const BlockImageFull = responsive.img(
  {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'block',
  },
  ({ browser }) =>
    browser.fits.small && {
      position: 'relative',
    },
);
