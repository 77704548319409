import promiseContainer from 'components/hoc/promiseContainer';
import { fetchObject } from 'api/search';

import ObjectDetail from './ObjectDetail';

function mapPropsToPromises({ location, match, ...props }) {
  return {
    objectData: fetchObject({ vondstnummer: match.params.vondstnummer }),
    query: location.search,
    ...props,
  };
}

export default promiseContainer(mapPropsToPromises)(ObjectDetail);
