import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { routes, routeUtils } from 'lib/history';

import _Showcase from 'components/Showcase';
import _ObjectOverview from 'components/ObjectOverview';
import _PrintObjectOverview from 'components/PrintObjectOverview';
import _ObjectDetail from 'components/ObjectDetail';
import _HomePage from 'components/HomePage';
import _CanvasOverview from 'components/CanvasOverview';
import _CanvasEditor from 'components/CanvasEditor';
import _CanvasDetail from 'components/CanvasDetail';
import _CanvasPublish from 'components/CanvasPublish';
import _CanvasPublished from 'components/CanvasPublished';
import _ContentPage from 'components/ContentPage';
import _BookPage from 'components/BookPage';
import _StoryPage from 'components/StoryPage';
import _PrintObjectCollection from 'components/PrintObjectCollection';
import _NotFound from 'components/NotFound';

const Showcase = withLangCheck(_Showcase);
const ObjectOverview = withLangCheck(_ObjectOverview);
const PrintObjectOverview = withLangCheck(_PrintObjectOverview);
const ObjectDetail = withLangCheck(_ObjectDetail);
const HomePage = _HomePage;
const CanvasOverview = withLangCheck(_CanvasOverview);
const CanvasEditor = withLangCheck(_CanvasEditor);
const CanvasDetail = withLangCheck(_CanvasDetail);
const CanvasPublish = withLangCheck(_CanvasPublish);
const CanvasPublished = withLangCheck(_CanvasPublished);
const ContentPage = withLangCheck(_ContentPage);
const BookPage = withLangCheck(_BookPage);
const StoryPage = withLangCheck(_StoryPage);
const PrintObjectCollection = withLangCheck(_PrintObjectCollection);
const NotFound = withLangCheck(_NotFound);

const SUPPORTED_LANGS = ['nl', 'en'];

export default class Layout extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={routes.ROOT} component={HomePage} />
        <Route exact path={routes.HOMEPAGE} component={HomePage} />
        <Route exact path={routes.SHOWCASE} component={Showcase} />
        <Route exact path={routes.SHOWCASE_ITEM} component={Showcase} />
        <Route exact path={routes.OBJECT_OVERVIEW} component={ObjectOverview} />
        <Route
          exact
          path={routes.PRINT_OBJECT_OVERVIEW}
          component={PrintObjectOverview}
        />
        <Route exact path={routes.OBJECT_DETAIL} component={ObjectDetail} />
        <Route exact path={routes.BOOK_PAGE} component={BookPage} />
        <Route exact path={routes.SET_OVERVIEW} component={CanvasOverview} />
        <Route exact path={routes.SET_DRAFT} component={CanvasEditor} />
        <Route exact path={routes.SET_PUBLISH} component={CanvasPublish} />
        <Route exact path={routes.SET_PUBLISHED} component={CanvasPublished} />
        <Route exact path={routes.SET_DETAIL} component={CanvasDetail} />
        <Route exact path={routes.CONTENT_PAGE} component={ContentPage} />
        <Route exact path={routes.STORY_PAGE} component={StoryPage} />
        <Route exact path={routes.STORY_ITEM} component={StoryPage} />
        <Route
          exact
          path={routes.PRINT_OBJECT_COLLECTION}
          component={PrintObjectCollection}
        />
        <Route
          exact
          path={routes.PRINT_OBJECT_COLLECTION}
          component={PrintObjectCollection}
        />
        <Route exact path={routes.NOT_FOUND} component={NotFound} />
        <Redirect to={routeUtils.notFound()} />
      </Switch>
    );
  }
}

function withLangCheck(PageComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      const lang = props.match.params.lang;
      if (!SUPPORTED_LANGS.includes(lang)) {
        props.history.replace(routeUtils.notFound({ lang: 'nl' }));
      }
    }
    render() {
      return <PageComponent {...this.props} />;
    }
  };
}
