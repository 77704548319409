import { connect } from 'react-redux';

import CanvasEditorPage from './CanvasEditorPage';

function mapStateToProps(state, ownProps) {
  const { canvasEditor } = state;
  return { canvasData: canvasEditor };
}

function mapDispatchToProps(dispatch) {
  return {
    clearCanvas: () => dispatch({ type: 'CE_CLEAR' }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CanvasEditorPage);
