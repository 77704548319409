import React, { Component } from 'react';
import PropTypes from 'prop-types';
import responsive from 'components/hoc/responsive';

import Container from 'components/util/Container';
import Announcements from 'components/util/Announcements';
import localize from 'components/LocalizedString/Localize';
import Navigation from './NavigationContainer';
import LogoSVG from './logo.svg';

import { routeUtils } from 'lib/history';

const MARGIN_DESKTOP = 40;
const MARGIN_MOBILE = 20;

let headerRenderedBefore = false;

export default class Header extends Component {
  static contextTypes = {
    lang: PropTypes.string,
  };

  constructor(props, context) {
    super(props);
    this.state = {
      announcementMessage: null,
    };
  }

  componentDidMount() {
    const mainLogo = document.getElementById('main-logo');
    // do not set focus on header on init but only on re-mount
    if (mainLogo && headerRenderedBefore) mainLogo.focus();
    headerRenderedBefore = true;

    const message = localize('newContent', this.context.lang);
    this.setState({ announcementMessage: message });
  }

  renderNav() {
    if (this.props.hideNav) return;

    return <Navigation />;
  }

  render() {
    return (
      <Container
        css={this.props.css}
        style={{
          ...flexContainer,
          pointerEvents: this.props.hideLogo ? 'none' : 'auto',
        }}
        role="banner"
      >
        <Announcements
          level="assertive"
          message={this.state.announcementMessage}
        />
        <LogoContainer
          href={routeUtils.homepage()}
          hide={this.props.hideLogo}
          id="main-logo"
          lang="en"
        >
          <img src={LogoSVG} alt="Below The Surface" />
        </LogoContainer>
        {this.renderNav()}
      </Container>
    );
  }
}

const flexContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 2,
};

const LogoContainer = responsive.a(
  {
    display: 'block',
    width: 360,
    marginTop: MARGIN_DESKTOP,
    marginBottom: MARGIN_DESKTOP,
    position: 'relative',
    zIndex: 9,
    '& > img': {
      width: '100%',
      height: 'auto,',
    },
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      width: 191,
      marginTop: MARGIN_MOBILE,
      marginBottom: MARGIN_MOBILE,
    },
  ({ browser }) =>
    browser.fits.medium && {
      zIndex: 3,
    },
  ({ hide }) =>
    hide && {
      opacity: 0,
      transition: 'opacity .2s ease-out, width 0s .2s, height 0s .2s',
      width: 0,
      height: 0,
      pointerEvents: 'none',
    },
);
