import React, { Component } from 'react';

import IconButton from 'components/util/IconButton';

import history, { routeUtils } from 'lib/history';

export default class CanvasCollectButton extends Component {
  collect = () => {
    const { collect, vondstnummers, addToCanvas } = this.props;
    collect({ vondstnummers, addToCanvas });

    history.push(routeUtils.draftSet());
  };

  render() {
    const { text } = this.props;
    const buttonText = text || undefined;

    return (
      <IconButton
        buttonText={buttonText}
        icon="add"
        onClick={this.collect}
        title={buttonText === undefined ? 'iconTitleAddToCollection' : false}
      />
    );
  }
}
