import React, { Component } from 'react';
import glamorous from 'glamorous';

import CanvasEditor from './CanvasEditorContainer';
import history, { routeUtils } from 'lib/history';

export default class CanvasEditorPage extends Component {
  publish = () => {
    history.push(routeUtils.publishSet());
  };

  render() {
    return (
      <Container>
        <CanvasEditor publish={this.publish} />
      </Container>
    );
  }
}

const Container = glamorous.div();
