import React, { PureComponent } from 'react';
import glamorous from 'glamorous';

export default class Histogram extends PureComponent {
  render() {
    const { periods, from, to, blockSize, objectsPerBlock } = this.props;
    return (
      <Container>
        {periods.map(period => {
          const isOneBlock = period.doc_count <= objectsPerBlock;
          const countOfWholeBlocks = Math.ceil(
            (period.doc_count - objectsPerBlock) / objectsPerBlock,
          );
          const percFillForOneBlock =
            (period.doc_count % objectsPerBlock) / objectsPerBlock;
          const isOutOfRange = !(period.from >= from && period.to <= to);
          const noResults = period.doc_count === 0;
          const greyedOut = isOutOfRange || noResults;
          return (
            <RowOfBlocks key={period.key}>
              {isOneBlock ? (
                <Block
                  height={blockSize.height}
                  margin={blockSize.margin}
                  fill={percFillForOneBlock}
                  greyedOut={greyedOut}
                />
              ) : (
                [...Array(countOfWholeBlocks)].map((x, i) => (
                  <Block
                    key={i}
                    height={blockSize.height}
                    margin={blockSize.margin}
                    fill={1}
                    greyedOut={greyedOut}
                  />
                ))
              )}
            </RowOfBlocks>
          );
        })}
      </Container>
    );
  }
}

const Container = glamorous.div({});

const RowOfBlocks = glamorous.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  ':last-child > div': {
    marginBottom: 0,
  },
});

const Block = glamorous.div(({ height, margin, fill, greyedOut }) => ({
  width: Math.min(height, Math.max(1, fill * height)),
  height,
  backgroundColor: greyedOut ? '#222' : 'white',
  transition: 'background-color 100ms ease-out',
  marginRight: margin,
  marginBottom: margin,
  ':last-child': {
    marginRight: 0,
  },
}));
