import { createBrowserHistory } from 'history';
import _ from 'lodash';
import qs from 'querystringify';
import ReactGA from 'react-ga';

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.pageview(location.pathname);
});

export const routes = {
  ROOT: '/',
  HOMEPAGE: '/:lang',

  OBJECT_OVERVIEW: '/:lang/vondsten',
  PRINT_OBJECT_OVERVIEW: '/:lang/vondsten/print',
  OBJECT_DETAIL: '/:lang/vondst/:vondstnummer',

  BOOK_PAGE: '/:lang/stuff',

  SHOWCASE: '/:lang/rokin',
  SHOWCASE_ITEM: '/:lang/rokin/:vondstnummer',

  SET_OVERVIEW: '/:lang/vitrines',
  SET_DRAFT: '/:lang/vitrine/concept',
  SET_PUBLISH: '/:lang/vitrine/publiceren',
  SET_PUBLISHED: '/:lang/vitrine/:id/gepubliceerd',
  SET_DETAIL: '/:lang/vitrine/:id',

  PRINT_OBJECT_COLLECTION: '/:lang/verzamelde-vondsten/print',

  CONTENT_PAGE: '/:lang/pagina/:slug',

  STORY_PAGE: '/:lang/verhalen',
  STORY_ITEM: '/:lang/verhalen/:vondstnummer',

  NOT_FOUND: '/:lang/x404',
};
export const routeUtils = {
  isHomepage: pathname =>
    isRoute(routes.HOMEPAGE, pathname) || isRoute(routes.ROOT, pathname),
  isShowcase: pathname => isRoute(routes.SHOWCASE, pathname),
  isShowcaseItem: pathname => isRoute(routes.SHOWCASE_ITEM, pathname),
  isBook: pathname => isRoute(routes.BOOK_PAGE, pathname),
  isObjectOverview: pathname => isRoute(routes.OBJECT_OVERVIEW, pathname),
  isPrintObjectOverview: pathname =>
    isRoute(routes.PRINT_OBJECT_OVERVIEW, pathname),
  isObjectDetail: pathname => isRoute(routes.OBJECT_DETAIL, pathname),
  isDraftSet: pathname => isRoute(routes.SET_DRAFT, pathname),
  isPublishSet: pathname => isRoute(routes.SET_PUBLISH, pathname),
  isPublishedSet: pathname => isRoute(routes.SET_PUBLISHED, pathname),
  isSetOverview: pathname => isRoute(routes.SET_OVERVIEW, pathname),
  isSetDetail: pathname => isRoute(routes.SET_DETAIL, pathname),
  isContentPage: pathname => isRoute(routes.CONTENT_PAGE, pathname),
  isStoryPage: pathname => isRoute(routes.STORY_PAGE, pathname),
  isStoryItem: pathname => isRoute(routes.STORY_ITEM, pathname),
  isPrintObjectCollection: pathname =>
    isRoute(routes.PRINT_OBJECT_COLLECTION, pathname),

  homepage: params => createPath(routes.HOMEPAGE, params),
  showcase: params => createPath(routes.SHOWCASE, params),
  showcaseItem: params => createPath(routes.SHOWCASE_ITEM, params),
  book: params => createPath(routes.BOOK_PAGE, params),
  objectOverview: params => createPath(routes.OBJECT_OVERVIEW, params),
  printObjectOverview: params =>
    createPath(routes.PRINT_OBJECT_OVERVIEW, params),
  objectDetail: params => createPath(routes.OBJECT_DETAIL, params),
  draftSet: params => createPath(routes.SET_DRAFT, params),
  publishSet: params => createPath(routes.SET_PUBLISH, params),
  publishedSet: params => createPath(routes.SET_PUBLISHED, params),
  setOverview: params => createPath(routes.SET_OVERVIEW, params),
  setDetail: params => createPath(routes.SET_DETAIL, params),
  contentPage: params => createPath(routes.CONTENT_PAGE, params),
  storyPage: params => createPath(routes.STORY_PAGE, params),
  storyItem: params => createPath(routes.STORY_ITEM, params),
  printObjectCollection: params =>
    createPath(routes.PRINT_OBJECT_COLLECTION, params),

  notFound: params => createPath(routes.NOT_FOUND, params),

  langSwitch: params => {
    const route = getRouteForPath(window.location.pathname);
    if (routeUtils.isHomepage(window.location.pathname)) {
      return routeUtils.homepage(params);
    } else {
      return route && createPath(route, params);
    }
  },
};

const ROUTE_PARAM_REGEXP = /:[^/]+/g;

function generateRegExpFromRoute(route) {
  return new RegExp(`^${route.replace(ROUTE_PARAM_REGEXP, '([^/]+)')}[/]?$`);
}

function getRouteForPath(path) {
  for (const routeName in routes) {
    const route = routes[routeName];
    if (isRoute(route, path)) {
      return route;
    }
  }
}

export function getCurrentParams() {
  return parseParams(window.location.pathname);
}

export function getCurrentQueryParams() {
  return qs.parse(window.location.search);
}

export function stringifyQueryParams(query) {
  return qs.stringify(query);
}

export function isCurrentPathSelected(path) {
  const current = history.location.pathname;
  return current === path;
}

function parseParams(path) {
  const route = getRouteForPath(path);
  if (!route) {
    return {};
  }
  const routeRegExp = generateRegExpFromRoute(route);
  const routeParamsMatch = route.match(routeRegExp);
  const pathParamsMatch = path.match(routeRegExp);
  const routeParams = routeParamsMatch.slice(1, routeParamsMatch.length);
  const pathParams = pathParamsMatch.slice(1, pathParamsMatch.length);
  return routeParams.reduce(
    (params, paramName, paramIndex) => ({
      ...params,
      [stripParamPrefix(paramName)]: pathParams[paramIndex],
    }),
    {},
  );
}

function isRoute(route, pathname = window.location.pathname) {
  for (const routeName in routes) {
    const r = routes[routeName];
    if (generateRegExpFromRoute(r).test(pathname)) {
      return r === route;
    }
  }
}

function createPath(route, params) {
  const combinedParams = _.merge(parseParams(window.location.pathname), params);
  return route
    .replace(ROUTE_PARAM_REGEXP, paramName => {
      return combinedParams[stripParamPrefix(paramName)];
    })
    .replace('undefined', 'nl'); // nl homepage hangt onder de root en het gaat nu niet altijd goed.
}

function stripParamPrefix(param) {
  return param.substring(':'.length);
}

export default history;
