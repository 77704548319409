import { getCurrentParams } from 'lib/history';
import { pickBy, forOwn } from 'lodash';

const BASE_URL = 'https://cdn.contentful.com';
const SPACE_ID = '1g4p9jizfk7g';
const ACCESS_TOKEN =
  '3336fd451016fbc6d715c6e461071db39cc0e48b35a81a4d1781ca87214d30ce';

export async function fetchContentPage(slug, type = 'contentPage') {
  const lang = getCurrentParams()['lang'] || 'nl';
  const url = `${BASE_URL}/spaces/${SPACE_ID}/entries?access_token=${ACCESS_TOKEN}&locale=${lang}&content_type=${type}&fields.slug=${encodeURIComponent(
    slug,
  )}&limit=1`;
  const res = await fetch(url);
  const data = await res.json();

  const item = data.items[0];

  if (!item) {
    return null;
  }

  resolveContentAssets(data);

  return {
    ...item.fields,
    createdAt: item.sys.createdAt,
    updatedAt: item.sys.updatedAt,
  };
}

function resolveContentAssets(data) {
  const assets = pickBy(data.items[0].fields, (value, key) => {
    if (!value.sys) {
      return false;
    }

    return value.sys.linkType === 'Asset';
  });

  forOwn(assets, (asset, field) => {
    const assetField = data.includes.Asset.filter(includedAsset => {
      return includedAsset.sys.id === asset.sys.id;
    });

    data.items[0].fields[field] = assetField[0].fields;
  });

  return data;
}
