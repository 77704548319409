import promiseContainer from 'components/hoc/promiseContainer';
import { listStories } from 'api/story';
import StoryPage from './StoryPage';

function mapPropsToPromises({ match }) {
  const lang = match.params.lang || 'nl';
  return {
    storyData: listStories(lang),
    id: match.params.id,
  };
}

export default promiseContainer(mapPropsToPromises)(StoryPage);
