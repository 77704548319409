import React, { Component } from 'react';
import Hammer from 'hammerjs';

import SmoothImage from 'components/util/SmoothImage';
import responsive from 'components/hoc/responsive';

export default class ImageContainer extends Component {
  carrouselItemElements = new Map();

  componentDidMount() {
    this.initPanner();
  }

  componentWillUnmount() {
    this.destroyPanner();
  }

  calcAfbeeldingPosition(afbeeldingIndex) {
    const { currentImage } = this.props;
    return -(afbeeldingIndex * 0.5 + currentImage * 0.5) * window.innerWidth;
  }

  isNotDraggable(deltaX) {
    const { currentImage, afbeeldingen } = this.props;
    return (
      (currentImage === 0 && deltaX > 0) ||
      (currentImage === afbeeldingen.length - 1 && deltaX < 0)
    );
  }

  initPanner() {
    this.mc = new Hammer.Manager(this.carrouselElement);

    this.mc.add(new Hammer.Pan({ direction: Hammer.DIRECTION_HORIZONTAL }));

    this.mc.on('panmove', e => {
      if (this.isNotDraggable(e.deltaX)) {
        return;
      }
      for (let [afbeeldingIndex, carouselItemElement] of this
        .carrouselItemElements) {
        const translateX =
          this.calcAfbeeldingPosition(afbeeldingIndex) + e.deltaX;
        carouselItemElement.style.transform = `translateX(${translateX}px)`;
      }
    });

    this.mc.on('panend', e => {
      if (Math.abs(e.deltaX) < 10) {
        this.forceUpdate();
      } else if (e.deltaX < 0) {
        this.props.setCurrentImage(this.props.currentImage + 1);
      } else {
        this.props.setCurrentImage(this.props.currentImage - 1);
      }
    });
  }

  destroyPanner() {
    this.mc.destroy();
  }

  acquireCarrousel = ref => {
    this.carrouselElement = ref;
  };

  acquireCarrouselItem(afbeeldingIndex) {
    return ref => {
      this.carrouselItemElements.set(afbeeldingIndex, ref);
    };
  }

  handleClick(afbeeldingIndex) {
    return e => {
      this.props.setCurrentImage(afbeeldingIndex);
    };
  }

  render() {
    const { afbeeldingen, currentImage, sqip, label } = this.props;
    return (
      <Carrousel innerRef={this.acquireCarrousel}>
        {this.props.afbeeldingen.map((afbeelding, afbeeldingIndex) => {
          const isPrev = afbeeldingIndex === currentImage - 1;
          const isCurrent = afbeeldingIndex === currentImage;
          const isNext = afbeeldingIndex === currentImage + 1;
          const translateX = this.calcAfbeeldingPosition(afbeeldingIndex);
          const shouldLoadImage = afbeeldingIndex <= currentImage + 1;
          return (
            <CarrouselItem
              key={afbeeldingIndex}
              innerRef={this.acquireCarrouselItem(afbeeldingIndex)}
              isPrev={isPrev}
              isCurrent={isCurrent}
              isNext={isNext}
              style={{
                transform: `translateX(${translateX}px)`,
              }}
            >
              <ImageBox onClick={this.handleClick(afbeeldingIndex)}>
                {shouldLoadImage && (
                  <SmoothImage
                    image={afbeeldingen[afbeeldingIndex]}
                    sqip={sqip}
                    imageSize={600}
                    label={label}
                  />
                )}
              </ImageBox>
            </CarrouselItem>
          );
        })}
      </Carrousel>
    );
  }
}

const Carrousel = responsive.div({
  display: 'flex',
  height: '50vh',
  overflow: 'hidden',
});

const CarrouselItem = responsive.div(
  {
    display: 'flex',
    justifyContent: 'center',
    transition: 'opacity 200ms ease-in-out, transform 100ms ease-in-out',
    pointerEvents: 'none',
    position: 'relative',
  },
  ({ isPrev, isCurrent, isNext }) => ({
    opacity: isCurrent ? 1 : isPrev || isNext ? 0.1 : 0,
    minWidth: '100vw',
    height: '50vh',
    zIndex: isCurrent ? 2 : 1,
  }),
);

const ImageBox = responsive.div(
  {
    maxWidth: 550,
    height: '100%',
    flexGrow: 1,
    pointerEvents: 'auto',
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      maxWidth: '80vw',
    },
);
