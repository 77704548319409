import promiseContainer from 'components/hoc/promiseContainer';
import { fetchCanvas } from 'api/canvas';
import { connect } from 'react-redux';

import CanvasDetail from './CanvasDetail';

function mapPropsToPromises({ match: { params: { id } } }) {
  return {
    canvasData: fetchCanvas(id),
  };
}

function mapStateToProps(state, ownProps) {
  const { canvasEditor } = state;
  return { canvasIsEmpty: canvasEditor.isEmpty };
}

function mapDispatchToProps(dispatch) {
  return {
    loadCanvas: params => dispatch({ type: 'CE_LOAD', ...params }),
  };
}

export default promiseContainer(mapPropsToPromises, mapDispatchToProps)(
  connect(mapStateToProps, mapDispatchToProps)(CanvasDetail),
);
