import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import CommonMark from 'commonmark';
import ReactRenderer from 'commonmark-react-renderer';

import { routeUtils } from 'lib/history';
import Header from 'components/Header';
import Container from 'components/util/Container';
import Announcements from 'components/util/Announcements';
import LogoFooter from 'components/LogoFooter';
import localize from 'components/LocalizedString/Localize';
import responsive from 'components/hoc/responsive';
import * as cssVariable from 'cssVariables';

import './ContentPage.css';

let contentRenderedBefore = false;

export default class ContentPage extends PureComponent {
  static contextTypes = {
    lang: PropTypes.string,
  };

  constructor(props, context) {
    super(props);
    this.state = {
      announcementMessage: null,
    };
  }

  componentDidMount() {
    if (!this.props.content) {
      this.props.history.replace(routeUtils.notFound());
    }
  }

  componentDidUpdate() {
    const mainLogo = document.getElementById('main-logo');
    // do not set focus on header on init but only on update
    if (mainLogo && contentRenderedBefore) mainLogo.focus();
    contentRenderedBefore = true;

    const message = localize('newContent', this.context.lang);
    this.setState({ announcementMessage: message });
  }

  responsiveClass = () => {
    if (this.props.browser.fits.extraSmall) {
      return 'extra-small';
    }

    if (this.props.browser.fits.medium) {
      return 'medium';
    }

    if (this.props.browser.greaterThan.extremelyLarge) {
      return 'extremely-large';
    }

    return '';
  };

  render() {
    const { content } = this.props;

    if (!content) {
      return null;
    }

    const parser = new CommonMark.Parser();
    const renderer = new ReactRenderer();

    const ast = parser.parse(content.text);
    const result = renderer.render(ast);

    return (
      <Wrapper>
        <Header />
        <ContentWrapper id="content-wrapper" role="main">
          <Container>
            <Announcements
              level="assertive"
              message={this.state.announcementMessage}
            />
            <Article className={`article ${this.responsiveClass()}`}>
              {result}
            </Article>
          </Container>
        </ContentWrapper>
        <LogoFooter />
      </Wrapper>
    );
  }
}

const Wrapper = glamorous.section();

const ContentWrapper = glamorous.div();

const Article = responsive.article(
  {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '8.8%',
    '> p': {
      fontFamily: cssVariable.fontFamilies.maisonNeue,
    },
  },
  ({ browser }) =>
    browser.fits.medium && {
      display: 'flex',
      flexDirection: 'column',
    },
);
