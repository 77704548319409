import qs from 'querystringify';
import _ from 'lodash';

import history, { routeUtils } from 'lib/history';

import { fetchSearch } from 'api/search';

// TODO: Move to reducer
const filterRelations = {
  generated_categorie: ['subcategorie'],
  niveau1: ['niveau2', 'niveau3', 'niveau4'],
  niveau2: ['niveau3', 'niveau4'],
  niveau3: ['niveau4'],
};

export function initFilters() {
  return (dispatch, getState) => {
    // Bij initiele load parsen en geven we de
    if (routeUtils.isObjectOverview()) {
      updateFilters(history.location);
    }

    history.listen((location, action) => {
      if (action === 'POP') {
        updateFilters(location);
      }
    });

    function updateFilters(location) {
      const queryStringParams = qs.parse(location.search);
      dispatch(setFilters(queryStringParams, true));
    }
  };
}

export function setDateFilter({ isPreviewState, from, to }) {
  return (dispatch, getState) => {
    if (isPreviewState) {
      dispatch({ type: 'SET_HISTOGRAM_PREVIEW', from, to });
      return;
    }

    const { searchFilters } = getState();
    dispatch(setFilters({ ...searchFilters, from, to }));
  };
}

export function setFilter(field, value) {
  return (dispatch, getState) => {
    const { searchFilters } = getState();
    return dispatch(setFilters({ ...searchFilters, [field]: value }));
  };
}

export function clearFilters() {
  return dispatch => {
    return dispatch(setFilters({}));
  };
}

export function setQuery(query) {
  return (dispatch, getState) => {
    dispatch(setFilters({ q: query }));
  };
}

export function unsetFilter(field) {
  return (dispatch, getState) => {
    const { searchFilters } = getState();

    const fields = filterRelations[field] || [];
    fields.push(field);

    const nullFields = fields.reduce((acc, field) => {
      acc[field] = null;
      return acc;
    }, {});

    return dispatch(setFilters({ ...searchFilters, ...nullFields }));
  };
}

export function setFilters(searchFilters, replaceHistory) {
  return (dispatch, getState) => {
    const cleanedFilters = _.pickBy(searchFilters, _.identity);
    dispatch({ type: 'SET_SEARCH_FILTERS', filters: cleanedFilters });
    (replaceHistory ? history.replace : history.push)({
      to: routeUtils.objectOverview(),
      search: qs.stringify(cleanedFilters),
    });
    dispatch(searchWithCurrentFilters());
  };
}

export function searchWithCurrentFilters(cancelIfRequestRunning = false) {
  return async (dispatch, getState) => {
    const { searchFilters, search } = getState();

    if (cancelIfRequestRunning && search.requestStartedTimestamp) {
      return;
    }

    const requestStartedTimestamp = Date.now();
    dispatch({
      type: 'SEARCH_REQUEST',
      requestStartedTimestamp,
      searchFilters,
    });

    try {
      const searchResult = await fetchSearch(searchFilters);

      dispatch({
        type: 'SEARCH_SUCCESS',
        requestStartedTimestamp,
        searchResult,
        searchFilters,
      });
    } catch (fetchError) {
      dispatch({
        type: 'SEARCH_FAILURE',
        requestStartedTimestamp,
        fetchError,
        searchFilters,
      });
    }
  };
}
