import promiseContainer from 'components/hoc/promiseContainer';
import { fetchCanvas } from 'api/canvas';
import { connect } from 'react-redux';

import CanvasPublishPage from './CanvasPublishedPage';

function mapPropsToPromises({ match: { params: { id } } }) {
  return {
    canvasData: fetchCanvas(id),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadCanvas: params => dispatch({ type: 'CE_LOAD', ...params }),
  };
}

export default promiseContainer(mapPropsToPromises, mapDispatchToProps)(
  connect(null, mapDispatchToProps)(CanvasPublishPage),
);
