import React, { Component } from 'react';

import Icon from 'components/Icon';

import LocalizedString from 'components/LocalizedString';
import responsive from 'components/hoc/responsive';
import * as cssVariable from 'cssVariables';

const BUTTON_DIMENS = 50;
export default class IconButton extends Component {
  render() {
    const { buttonText, icon, title, onClick } = this.props;
    return (
      <Button onClick={onClick} className="icon-hover-shrink-button">
        {buttonText && (
          <ButtonText>
            <LocalizedString string={buttonText} />
          </ButtonText>
        )}
        <IconContainer>
          <Icon
            iconName={icon}
            iconSize={cssVariable.iconSizes.medium}
            title={title}
          />
        </IconContainer>
      </Button>
    );
  }
}

const IconContainer = responsive.div({
  backgroundColor: cssVariable.colorBlack,
  width: BUTTON_DIMENS,
  height: BUTTON_DIMENS,
  color: cssVariable.colorWhite,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Button = responsive.button(
  {
    backgroundColor: cssVariable.colorBlack,
    display: 'flex',
    color: cssVariable.colorWhite,
    alignItems: 'center',
    height: BUTTON_DIMENS,
    padding: 0,
    fontSize: 18,
    fontWeight: cssVariable.fontWeights.bold,
    textAlign: 'center',
    margin: 0,
    flex: '0 1 auto',
  },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      display: 'inline-flex',
      paddingLeft: 0,
    },
);

const ButtonText = responsive.span(
  {
    marginLeft: 15,
    lineHeight: `${BUTTON_DIMENS}px`,
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      display: 'none',
      marginLeft: 0,
    },
);
