import 'focus-visible/dist/focus-visible.js';
import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';

import { getStore } from 'store';
import { appBoot } from 'actions/lifecycle';
import history from 'lib/history';

import Layout from 'components/App/Layout';
import Menu from 'components/Menu';
import ErrorBoundary from 'components/ErrorBoundary';
import LanguageProvider from './LanguageProvider';

// outputs Global CSS
import './global-styles';
import './fonts';

const store = getStore();

store.dispatch(appBoot());

export default () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <LanguageProvider>
          <ErrorBoundary>
            <ScrollMemory />
            <Layout />
            <Menu />
          </ErrorBoundary>
        </LanguageProvider>
      </Router>
    </Provider>
  );
};
