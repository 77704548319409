export default function formatNumber(year, beforeChrist) {
  const nonNegative = Math.abs(year);

  if (nonNegative <= 9999) {
    return year;
  }

  const str = nonNegative.toString();
  let out = '';
  for (let i = str.length - 1; i >= 0; i--) {
    const shouldPlaceDot = i > 0 && (str.length - i) % 3 === 0;
    out = (shouldPlaceDot ? '.' : '') + str[i] + out;
  }

  if (year >= 0) {
    return out;
  } else if (beforeChrist) {
    return `${out} ${beforeChrist}`;
  } else {
    return `-${out}`;
  }
}
