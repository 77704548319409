import _ from 'lodash';

const initialState = {
  requestStartedTimestamp: null,
  result: null,
  fetchError: null,
  visibleFilters: [],
};

export default function search(state = initialState, action) {
  switch (action.type) {
    case 'SEARCH_REQUEST':
      return {
        ...state,
        requestStartedTimestamp: action.requestStartedTimestamp,
        visibleFilters: visibleFilters(
          action.searchFilters,
          (state.result && state.result.facets) || [],
        ),
      };
    case 'SEARCH_SUCCESS':
      if (state.requestStartedTimestamp !== action.requestStartedTimestamp) {
        return state;
      }
      return {
        ...state,
        requestStartedTimestamp: null,
        result: {
          ...action.searchResult,
          facets: cleanFacets(action.searchResult.facets, action.searchFilters),
        },
        fetchError: null,
        visibleFilters: visibleFilters(
          action.searchFilters,
          action.searchResult.facets || [],
        ),
      };
    case 'SEARCH_FAILURE':
      if (state.requestStartedTimestamp !== action.requestStartedTimestamp) {
        return state;
      }
      return {
        ...state,
        requestStartedTimestamp: null,
        result: null,
        fetchError: action.fetchError,
        visibleFilters: visibleFilters(
          action.searchFilters,
          (state.result && state.result.facets) || [],
        ),
      };
    default:
      return state;
  }
}

function cleanFacets(receivedFacets, selectedFacets) {
  const facetNames = generateRelevantReceivedFacetNames(selectedFacets);
  return _.pickBy(receivedFacets, (_, name) => facetNames.includes(name));
}

const baseFilterStructure = [
  {
    name: 'filterCategory',
    facets: ['niveau1', 'niveau2', 'niveau3', 'niveau4'],
  },
  { name: 'filterMaterial', facets: ['generated_categorie', 'subcategorie'] },
  { name: 'filterSite', facets: ['generated_project'] },
];

function visibleFilters(selectedFacets, receivedFacets) {
  const relevantReceivedFacetNames = generateRelevantReceivedFacetNames(
    selectedFacets,
  );
  const relevantReceivedFacets = _.pickBy(receivedFacets, (_, name) =>
    relevantReceivedFacetNames.includes(name),
  );

  return baseFilterStructure.map(filter => {
    const facets = filter.facets
      .map(
        facetName =>
          relevantReceivedFacetNames.includes(facetName) ||
          selectedFacets[facetName]
            ? {
                field: facetName,
                options: relevantReceivedFacets[facetName],
                value: selectedFacets[facetName],
              }
            : null,
      )
      .filter(value => value);
    return { ...filter, facets };
  });
}

// Returns only the facets that include relevant options to show to the user
function generateRelevantReceivedFacetNames(selectedFilters) {
  const defaultFacets = baseFilterStructure.map(filter => filter.facets[0]);
  const facetsActivatedByFacet = {
    // TODO: Generate this from baseFilterStructure
    niveau1: 'niveau2',
    niveau2: 'niveau3',
    niveau3: 'niveau4',
    generated_categorie: 'subcategorie',
  };

  const facetsInParams = _.chain(selectedFilters)
    .pickBy(_.identity)
    .map((value, key) => key)
    .value();

  return _.chain(facetsInParams)
    .map(key => facetsActivatedByFacet[key])
    .concat(defaultFacets)
    .filter(_.identity)
    .filter(facet => !facetsInParams.includes(facet))
    .uniq()
    .value();
}
