import { css } from 'glamor';
import * as cssVariable from 'cssVariables';

// Use a normal understading of element sizing
css.global('*, *:before, *:after', {
  boxSizing: 'border-box',
});

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
css.global('.js-focus-visible :focus:not(.focus-visible)', {
  outline: 'none',
});
/*
  Optionally: Define a strong focus indicator for keyboard focus.
  If you choose to skip this step then the browser's default focus
  indicator will be displayed instead.
*/
css.global('.js-focus-visible .focus-visible', {});

// Fix IE10 flex defaults
css.global('*, *:before, *:after', {
  flex: '0 1 auto',
});

// Make sure all elements inherit their font
css.global('button, input, textarea, select', {
  font: 'inherit',
  color: 'inherit',
});

css.global('button', {
  cursor: 'pointer',
  border: 'none',
});

css.global('input', {
  border: 'none',
  appearance: 'none',
  borderRadius: 0,
});

css.global('html', {
  height: '100%',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
});

css.global('body', {
  margin: 0,
  fontSize: 16,
  lineHeight: 1.56,
  fontWeight: cssVariable.fontWeights.regular,
  color: 'black',
});

css.global('.fonts-active body', {
  fontFamily: cssVariable.fontFamilies.maison,
});

css.global('a', {
  textDecoration: 'none',
  color: 'black',
});

css.global('h2, .h2', {
  margin: 0,
  fontSize: 26,
  lineHeight: 1.15,
  fontWeight: cssVariable.fontWeights.bold,
});

css.global('.visually-hidden', {
  position: 'absolute',
  overflow: 'hidden',
  clip: 'rect(0 0 0 0)',
  height: 1,
  width: 1,
  margin: -1,
  padding: 0,
  border: 0,
});

css.global('.icon-hover-shrink > *', {
  transform: 'scale(1)',
  transition: 'transform 0.15s linear',
});

css.global('.icon-hover-shrink:hover > *', {
  transform: 'scale(0.9)',
});

css.global('.icon-hover-shrink-button svg', {
  transform: 'scale(1)',
  transition: 'transform 0.15s linear',
});

css.global('.icon-hover-shrink-button:hover svg', {
  transform: 'scale(0.9)',
});

css.global('.icon-hover-shrink-button span', {
  transform: 'translateX(0)',
  transition: 'transform 0.15s linear',
});

css.global('.icon-hover-shrink-button:hover span', {
  transform: 'translateX(1.5%)',
});

css.global('.icon-hover-left svg', {
  transform: 'translateX(0%)',
  transition: 'transform 0.15s linear',
});

css.global('.icon-hover-left:hover svg', {
  transform: 'translateX(-10%)',
});

css.global('.icon-hover-right svg', {
  transform: 'translateX(0%)',
  transition: 'transform 0.15s linear',
});

css.global('.icon-hover-right:hover svg', {
  transform: 'translateX(10%)',
});

css.global('.icon-hover-down svg', {
  transform: 'translateY(0%)',
  transition: 'transform 0.15s linear',
});

css.global('.icon-hover-down:hover svg', {
  transform: 'translateY(10%)',
});
