import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import Icon from 'components/Icon';
import LocalizedString from 'components/LocalizedString';

import * as cssVariable from 'cssVariables';

export default class FacetLink extends PureComponent {
  static contextTypes = {
    lang: PropTypes.string,
  };

  handleClick = e => {
    e.preventDefault();
    this.props.applyFacetFilter();
    document.getElementById(this.props.headerId).focus();
  };

  render() {
    const { selected, children, count } = this.props;
    return (
      <Button
        selected={selected}
        onClick={this.handleClick}
        className="icon-hover-shrink"
      >
        <Label role="option" aria-selected={selected ? 'true' : 'false'}>
          {selected && (
            <span className="visually-hidden">
              <LocalizedString string="filterActive" />
            </span>
          )}
          {children}
          {count && <Count>({count})</Count>}
        </Label>
        {selected && (
          <Icon iconName="close" iconSize={12} title="iconClearFilter" />
        )}
      </Button>
    );
  }
}

const Button = glamorous.button(
  {
    color: 'white',
    display: 'block',
    background: 'none',
    border: 'none',
    padding: 0,
    textAlign: 'left',
    fontWeight: cssVariable.fontWeights.regular,
  },
  ({ selected }) =>
    selected && {
      textTransform: 'uppercase',
      fontWeight: cssVariable.fontWeights.bold,
      marginBottom: 5,
    },
);

const Label = glamorous.span({
  marginRight: 10,
});

const Count = glamorous.span({
  marginLeft: 5,
  color: cssVariable.colorWhite,
});
