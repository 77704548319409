import { connect } from 'react-redux';

import { setFilter, unsetFilter } from 'actions/search';
import FacetLink from './FacetLink';

function mapDispatchToProps(dispatch, ownProps) {
  return {
    applyFacetFilter() {
      const { unset, field, value } = ownProps;
      const action = unset ? unsetFilter(field) : setFilter(field, value);
      return dispatch(action);
    },
  };
}

export default connect(null, mapDispatchToProps)(FacetLink);
