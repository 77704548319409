import React, { Component } from 'react';
import Promise from 'bluebird';
import hoistNonReactStatics from 'hoist-non-react-statics';
import isEqualShallow from 'is-equal-shallow';
import Loader from 'components/Loader';
import history, { routeUtils } from 'lib/history';

export default mapPropsToPromises => (
  Component,
  LoadingComponent = Loader,
  ErrorComponent = DefaultErrorComponent,
) => {
  class PromiseContainer extends React.Component {
    componentUnmounting = false;

    fetchIndex = 0;

    state = {
      isLoading: true,
      isReloading: false,
      fetchError: null,
      fetchResult: null,
    };

    componentDidMount() {
      this.fetchPromises(this.props);
    }

    componentWillReceiveProps(nextProps) {
      if (!isEqualShallow(this.props, nextProps)) {
        this.setState({ isReloading: true });
        this.fetchPromises(nextProps);
      }
    }

    componentWillUnmount() {
      this.componentUnmounting = true;
    }

    shouldCancelFetch(currentFetchIndex) {
      return currentFetchIndex + 1 < this.fetchIndex;
    }

    async fetchPromises(props) {
      const currentFetchIndex = this.fetchIndex++;
      try {
        const fetchResult = await Promise.props(mapPropsToPromises(props));
        if (
          this.componentUnmounting ||
          this.shouldCancelFetch(currentFetchIndex)
        ) {
          return;
        }
        this.setState({ isLoading: false, isReloading: false, fetchResult });
      } catch (fetchError) {
        if (
          this.componentUnmounting ||
          this.shouldCancelFetch(currentFetchIndex)
        ) {
          return;
        }
        console.error(fetchError);
        this.setState({ isLoading: false, isReloading: false, fetchError });
      }
    }

    render() {
      if (this.state.isLoading) {
        return <LoadingComponent />;
      }

      if (this.state.fetchError) {
        return <ErrorComponent error={this.state.fetchError} />;
      }

      return (
        <Component
          {...this.state.fetchResult}
          isReloading={this.state.isReloading}
        />
      );
    }
  }

  hoistNonReactStatics(PromiseContainer, Component);

  return PromiseContainer;
};

export class DefaultErrorComponent extends Component {
  componentDidMount() {
    if (this.props.error.statusCode === 404) {
      history.replace(routeUtils.notFound());
    } else {
      // Let our ErrorBoundary catch the error.
      throw this.props.error;
    }
  }

  render() {
    return null;
  }
}
