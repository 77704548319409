import React, { Component } from 'react';
import responsive from 'components/hoc/responsive';
import LocalizedString from 'components/LocalizedString';

import * as cssVariable from 'cssVariables';
import { Label } from 'components/util/Texts';
import history, { routeUtils } from 'lib/history';

import ObjectDetailListItem, {
  Link,
  ListText,
  ResponsiveIcon,
} from 'components/ObjectDetail/ObjectDetailListItem';

export default class ObjectSpecialDetailList extends Component {
  setQuery(q) {
    history.push(routeUtils.objectOverview());
    this.props.setQuery(q);
  }

  getVlakHoogte(object) {
    if (!object.vlak_min) {
      return null;
    }

    let vlakhoogte = object.vlak_min + ' — ' + object.vlak_max;
    return vlakhoogte;
  }

  trimTrailingDecimals(value) {
    if (!value) {
      return null;
    }

    return value.replace(/\.00$/, '');
  }

  renderDetailListItem(label, value, labelValue, queryPrefix) {
    if (!value) {
      return null;
    }

    if (queryPrefix) {
      return (
        <ObjectDetailListItem
          onClick={() => this.setQuery(queryPrefix + value)}
          disabled={!queryPrefix}
          label={label}
          labelValue={labelValue}
          value={value}
          link={value}
        />
      );
    }

    return (
      <DetailListItem>
        <Label>
          <LocalizedString string={label} values={{ [label]: labelValue }} />
        </Label>
        {value}
      </DetailListItem>
    );
  }

  renderTrefwoorden(object) {
    if (object['trefwoord'].length === 0) {
      return;
    }

    if (object['trefwoord'][0] === null) {
      return;
    }

    return (
      <DetailListItem>
        <Label>
          <LocalizedString string={'objTrefwoorden'} />
        </Label>
        {object['trefwoord'].map((trefwoord, i) => {
          return (
            <Link
              disabled={false}
              onClick={() => this.setQuery('kw:' + trefwoord)}
              className="icon-hover-right"
              key={i}
            >
              <ListText>{trefwoord}</ListText>
              <ResponsiveIcon
                iconName="arrowRight"
                color={cssVariable.colorBlack}
              />
            </Link>
          );
        })}
      </DetailListItem>
    );
  }

  render() {
    const object = this.props.object;
    const vlakHoogte = this.getVlakHoogte(object);

    return (
      <DetailList>
        {this.renderTrefwoorden(object)}
        {this.renderDetailListItem(
          'objGewicht',
          this.trimTrailingDecimals(object.gewicht),
        )}
        {this.renderDetailListItem('objPut', object.put)}
        {this.renderDetailListItem('objVak', object.vak)}
        {this.renderDetailListItem('objSpoor', object.spoor)}
        {this.renderDetailListItem('objVlakHoogte', vlakHoogte)}
        {this.renderDetailListItem(
          'dsCode',
          object.aardewerk_ds_type,
          [],
          'ds:',
        )}
        {this.renderDetailListItem('herkomst', object.herkomst)}
        {this.renderDetailListItem('herkomst', object.aardewerk_herkomst)}
        {this.renderDetailListItem(
          'objDiameterMM',
          this.trimTrailingDecimals(object.aardewerk_object_diameter_mm),
        )}
        {this.renderDetailListItem(
          'objHoogteMM',
          this.trimTrailingDecimals(object.aardewerk_object_hoogte_mm),
        )}
        {this.renderDetailListItem(
          'fragmLengteMM',
          this.trimTrailingDecimals(object.aardewerk_fragm_lengte_mm),
        )}
        {this.renderDetailListItem(
          'fragmBreedteMM',
          this.trimTrailingDecimals(object.aardewerk_fragm_breedte_mm),
        )}
        {this.renderDetailListItem(
          'fragmHoogteMM',
          this.trimTrailingDecimals(object.aardewerk_fragm_hoogte_mm),
        )}
        {this.renderDetailListItem(
          'cerOppBehandeling',
          object.aardewerk_opp_behandeling,
        )}
        {this.renderDetailListItem(
          'cerDecoratieTechniekMF',
          object.aardewerk_decoratietechniek,
        )}
        {this.renderDetailListItem(
          'cerDecorgroepen',
          object.aardewerk_decorgroepen,
        )}
        {this.renderDetailListItem('cerMerk', object.aardewerk_merk)}
        {this.renderDetailListItem(
          'mtlTotaleLengteMM',
          this.trimTrailingDecimals(object.blankewapens_totale_lengte_mm),
        )}
        {this.renderDetailListItem(
          'mtlTypeLemmet',
          object.blankewapens_type_lemmet,
        )}
        {this.renderDetailListItem(
          'mtlTypeKlingvanger',
          object.blankewapens_type_klingvanger,
        )}
        {this.renderDetailListItem(
          'mtlTypeHeft',
          object.blankewapens_type_heft,
        )}
        {this.renderDetailListItem(
          'mtlMateriaalHeft',
          object.blankewapens_materiaal_heft,
        )}
        {this.renderDetailListItem(
          'mtlTypeHeftbekroning',
          object.blankewapens_type_heftbekroning,
        )}
        {this.renderDetailListItem(
          'mtlProductiemerk',
          object.blankewapens_productiemerk,
        )}
        {this.renderDetailListItem('bwmKleur', object.bouwmaterialen_kleur)}
        {this.renderDetailListItem(
          'bwmGrootsteLengteMM',
          this.trimTrailingDecimals(object.bouwmaterialen_grootstelengte_mm),
        )}
        {this.renderDetailListItem(
          'bwmGrootsteBreedteMM',
          this.trimTrailingDecimals(object.bouwmaterialen_grootstebreedte_mm),
        )}
        {this.renderDetailListItem(
          'bwmGrootsteDikteMM',
          this.trimTrailingDecimals(object.bouwmaterialen_grootstedikte_mm),
        )}
        {this.renderDetailListItem(
          'bwmProductiecentrum',
          object.bouwmaterialen_productiecentrum,
        )}
        {this.renderDetailListItem(
          'bwmAfbeelding',
          object.bouwmaterialen_afbeelding,
        )}
        {this.renderDetailListItem(
          'bwmOppervlakte',
          object.bouwmaterialen_oppervlakte,
        )}
        {this.renderDetailListItem('fauSoort', object.fauna_soort)}
        {this.renderDetailListItem('fauElement', object.fauna_element)}
        {this.renderDetailListItem('fauLengteMM', object.fauna_lengte_mm)}
        {this.renderDetailListItem('fauBreedteMM', object.fauna_breedte_mm)}
        {this.renderDetailListItem('fauHoogteMM', object.fauna_hoogte_mm)}
        {this.renderDetailListItem('dsCode', object.glas_ds_type)}
        {this.renderDetailListItem('glsKleur', object.glas_kleur)}
        {this.renderDetailListItem('glsHerkomst', object.glas_herkomst)}
        {this.renderDetailListItem(
          'objDiameterMM',
          this.trimTrailingDecimals(object.glas_object_diameter_mm),
        )}
        {this.renderDetailListItem(
          'objHoogteMM',
          this.trimTrailingDecimals(object.glas_object_hoogte_mm),
        )}
        {this.renderDetailListItem(
          'fragmLengteMM',
          this.trimTrailingDecimals(object.glas_fragm_lengte_mm),
        )}
        {this.renderDetailListItem(
          'fragmBreedteMM',
          this.trimTrailingDecimals(object.glas_fragm_breedte_mm),
        )}
        {this.renderDetailListItem(
          'fragmHoogteMM',
          this.trimTrailingDecimals(object.glas_fragm_hoogte_mm),
        )}
        {this.renderDetailListItem(
          'fragmDikteMM',
          this.trimTrailingDecimals(object.glas_fragm_dikte_mm),
        )}
        {this.renderDetailListItem(
          'glsDecoratie',
          this.trimTrailingDecimals(object.glas_decoratie),
        )}
        {this.renderDetailListItem(
          'cerMerk',
          this.trimTrailingDecimals(object.glas_merk),
        )}
        {this.renderDetailListItem(
          'objDeelmaterialen',
          object.hout_deelmaterialen,
        )}
        {this.renderDetailListItem(
          'objGrootsteLengteMM',
          this.trimTrailingDecimals(object.hout_grootstelengte_mm),
        )}
        {this.renderDetailListItem(
          'objGrootsteBreedteMM',
          this.trimTrailingDecimals(object.hout_grootstebreedte_mm),
        )}
        {this.renderDetailListItem(
          'objGrootsteHoogteMM',
          this.trimTrailingDecimals(object.hout_grootstehoogte_mm),
        )}
        {this.renderDetailListItem(
          'objGrootsteDikteMM',
          this.trimTrailingDecimals(object.hout_grootstedikte_mm),
        )}
        {this.renderDetailListItem(
          'diameterMM',
          this.trimTrailingDecimals(object.hout_grootstelengte_mm),
        )}
        {this.renderDetailListItem(
          'htProductiewijze',
          object.hout_productiewijze,
        )}
        {this.renderDetailListItem(
          'objDeelmaterialen',
          object.kunststof_deelmaterialen,
        )}
        {this.renderDetailListItem(
          'objGrootsteLengteMM',
          this.trimTrailingDecimals(object.kunststof_grootstelengte_mm),
        )}
        {this.renderDetailListItem(
          'objGrootsteBreedteMM',
          this.trimTrailingDecimals(object.kunststof_grootstebreedte_mm),
        )}
        {this.renderDetailListItem(
          'objGrootsteHoogteMM',
          this.trimTrailingDecimals(object.kunststof_grootstehoogte_mm),
        )}
        {this.renderDetailListItem(
          'objGrootsteDikteMM',
          this.trimTrailingDecimals(object.kunststof_grootstedikte_mm),
        )}
        {this.renderDetailListItem(
          'diameterMM',
          this.trimTrailingDecimals(object.kunststof_grootstelengte_mm),
        )}
        {this.renderDetailListItem(
          'kstProductiecentrum',
          object.kunststof_productiecentrum,
        )}
        {this.renderDetailListItem('kstEigenaar', object.kunststof_eigenaar)}
        {this.renderDetailListItem('kstMerk', object.kunststof_merk)}
        {this.renderDetailListItem('kstType', object.kunststof_type)}
        {this.renderDetailListItem(
          'kstEenheidWaarde',
          object.kunststof_eenheid_waarde,
        )}
        {this.renderDetailListItem(
          'leeArcheologischObjectType',
          object.leer_archeologischobjecttype,
        )}
        {this.renderDetailListItem(
          'objDeelmaterialen',
          object.leer_deelmaterialen,
        )}
        {this.renderDetailListItem('leeVersiering', object.leer_versiering)}
        {this.renderDetailListItem('leeSoort', object.leer_leersoort)}

        {this.renderDetailListItem(
          'objGrootsteLengteMM',
          this.trimTrailingDecimals(object.leer_grootste_lengte_mm),
        )}
        {this.renderDetailListItem(
          'objGrootsteBreedteMM',
          this.trimTrailingDecimals(object.leer_grootste_breedte_mm),
        )}
        {this.renderDetailListItem(
          'objGrootsteHoogteMM',
          this.trimTrailingDecimals(object.leer_grootste_hoogte_mm),
        )}
        {this.renderDetailListItem(
          'mesAngerOfPlaatangel',
          object.messen_angel_of_plaatangel,
        )}
        {this.renderDetailListItem('mesHeftMat1', object.messen_heft_mat_1)}
        {this.renderDetailListItem('mesFunctie', object.messen_functie)}
        {this.renderDetailListItem(
          'mesLemmetLengte',
          this.trimTrailingDecimals(object.messen_lemmet_lengte),
        )}
        {this.renderDetailListItem(
          'mesHeftLengte',
          this.trimTrailingDecimals(object.messen_heft_lengte),
        )}
        {this.renderDetailListItem(
          'mesMinTotaleLengte',
          this.trimTrailingDecimals(object.messen_minimale_totale_lengte),
        )}
        {this.renderDetailListItem(
          'mesMinTotaleBreedte',
          this.trimTrailingDecimals(object.messen_minimale_totale_breedte),
        )}
        {this.renderDetailListItem(
          'objDeelmaterialen',
          object.metaal_deelmaterialen,
        )}
        {this.renderDetailListItem(
          'objGrootsteLengteMM',
          this.trimTrailingDecimals(object.metaal_grootstelengte_mm),
        )}
        {this.renderDetailListItem(
          'objGrootsteBreedteMM',
          this.trimTrailingDecimals(object.metaal_grootstebreedte_mm),
        )}
        {this.renderDetailListItem(
          'objGrootsteHoogteMM',
          this.trimTrailingDecimals(object.metaal_grootstehoogte_mm),
        )}
        {this.renderDetailListItem(
          'objGrootsteDikteMM',
          this.trimTrailingDecimals(object.metaal_grootstedikte_mm),
        )}
        {this.renderDetailListItem('diameterMM', object.metaal_diameter_mm)}
        {this.renderDetailListItem(
          'kstProductiecentrum',
          object.metaal_productiecentrum,
        )}
        {this.renderDetailListItem(
          'objDeelmaterialen',
          object.mix_deelmaterialen,
        )}
        {this.renderDetailListItem(
          'objGrootsteLengteMM',
          this.trimTrailingDecimals(object.mix_grootstelengte_mm),
        )}
        {this.renderDetailListItem(
          'objGrootsteBreedteMM',
          this.trimTrailingDecimals(object.mix_grootstebreedte_mm),
        )}
        {this.renderDetailListItem(
          'objGrootsteHoogteMM',
          this.trimTrailingDecimals(object.mix_grootstehoogte_mm),
        )}
        {this.renderDetailListItem(
          'objGrootsteDikteMM',
          this.trimTrailingDecimals(object.mix_grootstedikte_mm),
        )}
        {this.renderDetailListItem('diameterMM', object.mix_diameter_mm)}
        {this.renderDetailListItem('muntLandGeo', object.munt_land_geografish)}
        {this.renderDetailListItem(
          'muntStaat',
          object.munt_staat_politieke_eenheid,
        )}
        {this.renderDetailListItem(
          'muntAutoriteit',
          object.munt_autoriteit_politiek,
        )}
        {this.renderDetailListItem('muntOntwerper', object.munt_ontwerper)}
        {this.renderDetailListItem(
          'muntOnderwerp',
          object.munt_onderwerp_gelegenheid,
        )}
        {this.renderDetailListItem(
          'muntPlaats',
          object.munt_muntplaats_productieplaats,
        )}
        {this.renderDetailListItem(
          'diameterMM',
          object.munt_lengte_diameter_in_mm,
        )}
        {this.renderDetailListItem('fauBreedteMM', object.munt_breedte_in_mm)}
        {this.renderDetailListItem('stnSubsoort', object.natuursteen_subsoort)}
        {this.renderDetailListItem(
          'objGrootsteLengteMM',
          object.natuursteen_grootstelengte_mm,
        )}
        {this.renderDetailListItem(
          'objGrootsteBreedteMM',
          object.natuursteen_grootstebreedte_mm,
        )}
        {this.renderDetailListItem(
          'objGrootsteHoogteMM',
          object.natuursteen_grootstehoogte_mm,
        )}
        {this.renderDetailListItem(
          'objGrootsteDikteMM',
          object.natuursteen_grootstedikte_mm,
        )}
        {this.renderDetailListItem(
          'diameterMM',
          object.natuursteen_diameter_mm,
        )}
        {this.renderDetailListItem(
          'stnProductiesporen',
          object.natuursteen_productiesporen,
        )}
        {this.renderDetailListItem('plantSoort', object.plant_soort)}
        {this.renderDetailListItem(
          'objGrootsteLengteMM',
          object.plant_grootstelengte_mm,
        )}
        {this.renderDetailListItem(
          'objGrootsteBreedteMM',
          object.plant_grootstebreedte_mm,
        )}
        {this.renderDetailListItem(
          'objGrootsteHoogteMM',
          object.plant_grootstehoogte_mm,
        )}
        {this.renderDetailListItem(
          'objGrootsteDikteMM',
          object.plant_grootstedikte_mm,
        )}
        {this.renderDetailListItem('diameterMM', object.plant_diameter_mm)}
        {this.renderDetailListItem('pijpModel', object.rookpijpen_model)}
        {this.renderDetailListItem(
          'pijpZijmerkLinks',
          object.rookpijpen_zijmerk_links,
        )}
        {this.renderDetailListItem(
          'pijpZijmerkRechts',
          object.rookpijpen_zijmerk_rechts,
        )}
        {this.renderDetailListItem(
          'pijpBijmerkLinks',
          object.rookpijpen_bijmerk_links,
        )}
        {this.renderDetailListItem(
          'pijpBijmerkRechts',
          object.rookpijpen_bijmerk_rechts,
        )}
        {this.renderDetailListItem(
          'pijpMerk',
          object.rookpijpen_merk_of_hielmerk,
        )}
        {this.renderDetailListItem(
          'pijpOppBehandeling',
          object.rookpijpen_oppervlaktebehandeling_kop,
        )}
        {this.renderDetailListItem(
          'pijpKopopening',
          object.rookpijpen_kopopening,
        )}
        {this.renderDetailListItem('pijpRadering', object.rookpijpen_radering)}
        {this.renderDetailListItem(
          'pijpSteelbehandeling',
          object.rookpijpen_steelbehandeling,
        )}
        {this.renderDetailListItem(
          'pijpKwaliteit',
          object.rookpijpen_kwaliteit,
        )}
        {this.renderDetailListItem(
          'pijpProductiecentrum',
          object.rookpijpen_productiecentrum,
        )}
        {this.renderDetailListItem(
          'pijpPijpenmaker',
          object.rookpijpen_pijpenmaker,
        )}
        {this.renderDetailListItem('kscBaksel', object.sculpturen_baksel)}
        {this.renderDetailListItem('kscHoogteMM', object.sculpturen_hoogte_mm)}
        {this.renderDetailListItem(
          'kscBreedteMM',
          object.sculpturen_breedte_mm,
        )}
        {this.renderDetailListItem('kscDiepteMM', object.sculpturen_diepte_mm)}
        {this.renderDetailListItem(
          'kscHolOfMassief',
          object.sculpturen_hol_of_massief,
        )}
        {this.renderDetailListItem(
          'kscVoorstelling',
          object.sculpturen_voorstelling,
        )}
        {this.renderDetailListItem(
          'objDeelmaterialen',
          object.textiel_deelmaterialen,
        )}
        {this.renderDetailListItem(
          'objGrootsteLengteMM',
          object.textiel_grootstelengte_mm,
        )}
        {this.renderDetailListItem(
          'objGrootsteBreedteMM',
          object.textiel_grootstebreedte_mm,
        )}
        {this.renderDetailListItem(
          'htProductiewijze',
          object.textiel_productiewijze,
        )}
        {this.renderDetailListItem('txtBinding', object.textiel_binding)}
        {this.renderDetailListItem('txtBewerking', object.textiel_bewerking)}
        {this.renderDetailListItem(
          'objDeelmaterialen',
          object.touw_deelmaterialen,
        )}
        {this.renderDetailListItem(
          'objGrootsteLengteMM',
          object.touw_grootstelengte_mm,
        )}
        {this.renderDetailListItem(
          'objGrootsteBreedteMM',
          object.touw_grootstebreedte_mm,
        )}
        {this.renderDetailListItem('objDiameter', object.touw_diameter_mm)}
        {this.renderDetailListItem('twStrengen', object.touw_strengen)}
        {this.renderDetailListItem(
          'twProductiewijze',
          object.touw_productiewijze,
        )}
      </DetailList>
    );
  }
}

const uppercaseText = {
  textTransform: 'uppercase',
};

const defaultListProps = {
  listStyleType: 'none',
  margin: 0,
  padding: 0,
};

const DetailList = responsive.ul(
  {
    ...defaultListProps,
    display: 'grid',
    gridGap: 10,
    gridTemplateColumns: 'repeat(4, 1fr)',
    '@media all and (-ms-high-contrast:none)': {
      columnCount: '4',
      columnGap: 20,
    },
  },
  ({ browser }) =>
    browser.fits.smallMedium && {
      marginTop: 40,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      marginTop: 0,
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
);

const DetailListItem = responsive.li(
  {
    fontSize: 26,
    fontWeight: cssVariable.fontWeights.bold,
    marginBottom: 20,
    ...uppercaseText,
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      fontSize: 16,
    },
);
