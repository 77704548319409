const localizedStrings = {
  title: {
    nl:
      'Below the Surface - De archeologische vondsten Noord/Zuidlijn Amsterdam',
    en:
      'Below the Surface - The archeological finds of the North/Southline Amsterdam',
  },
  userDisplayTitle: {
    nl: 'Zelfgemaakte vitrine',
    en: 'Home-made display',
  },
  objectCount: { nl: ':count vondsten', en: ':count objects' },
  fromYear: { nl: 'Vanaf het jaar', en: 'From year' },
  toYear: { nl: 'Tot het jaar', en: 'Until year' },
  objectnumber: { nl: 'vondstnummer', en: 'find number' },
  material: { nl: 'materiaal', en: 'material' },
  classification: { nl: 'gebruik', en: 'use' },
  dimensions: {
    nl: 'afmeting in mm (:dimensions)',
    en: 'dimensions in mm (:dimensions)',
  },
  diameter: {
    nl: 'diameter in mm',
    en: 'diameter in mm',
  },
  site: { nl: 'locatie', en: 'location' },
  dating: { nl: 'datering', en: 'date' },
  loading: { nl: 'Laden...', en: 'Loading...' },
  error: {
    nl: 'Er is een fout opgetreden: :errorMessage',
    en: 'An error occurred: :errorMessage',
  },
  somethingWentWrong: {
    nl: 'Oeps, er ging iets gruwelijk mis.',
    en: 'Oops, something went terribly wrong.',
  },
  reloadPage: {
    nl: 'Herlaad de pagina.',
    en: 'Reload the page.',
  },
  notFound: {
    nl: 'Sorry, deze pagina bestaat niet.',
    en: 'The page you requested couldn’t be found.',
  },
  exploreAllObjects: {
    nl: 'Bekijk alle vondsten',
    en: 'Explore all objects',
  },
  gotoHomepage: {
    nl: 'Ga naar de homepage',
    en: 'Go back to the homepage',
  },
  searchPlaceholder: {
    nl: 'Zoek op trefwoord',
    en: 'Keyword search',
  },
  filterPeriod: { nl: 'Periode', en: 'Period' },
  filterCategory: { nl: 'Gebruik', en: 'Use' },
  filterMaterial: { nl: 'Materiaal', en: 'Material' },
  filterSite: { nl: 'Locatie', en: 'Location' },
  showResults: { nl: 'Toon resultaten', en: 'Show results' },
  showMoreResults: { nl: 'Toon meer resultaten', en: 'Show more results' },
  beforeChrist: { nl: 'v.C.', en: 'BC' },
  canvasDefaultTextBoxText: {
    nl: 'Dubbelklik om te bewerken',
    en: 'Double click to edit',
  },
  newContent: {
    nl: 'Nieuwe pagina geladen',
    en: 'New page loaded',
  },
  home: {
    nl: 'Home',
    en: 'Home',
  },
  displays: {
    nl: 'Vitrines',
    en: 'Displays',
  },
  objects: {
    nl: 'Vondsten',
    en: 'Objects',
  },
  stories: {
    nl: 'Vondstverhalen',
    en: 'Object stories',
  },
  rokin: {
    nl: 'Over de Rokin vitrines',
    en: 'About the Rokin displays',
  },
  about: {
    nl: 'De opgravingen',
    en: 'The excavations',
  },
  data: {
    nl: 'Publicaties en datasets',
    en: 'Publications and datasets',
  },
  disclaimer: {
    nl: 'Disclaimer / Onze afspraken',
    en: 'Disclaimer / Code of conduct',
  },
  colofon: {
    nl: 'Colofon / Contact',
    en: 'Colophon / Contact',
  },
  book: {
    nl: 'Het boek',
    en: 'The book',
  },
  documentaire: {
    nl: 'De documentaire',
    en: 'The documentary',
  },
  languageToggle: {
    nl: 'English',
    en: 'Nederlands',
  },
  showcase: {
    nl: 'Ontdek de vitrines op het metrostation Rokin',
    en: 'Explore the displays inside the Rokin metrostation',
  },
  publishExplainer: {
    nl:
      'Geef je vitrine een titel. Na publiceren verschijnt je vitrine in het overzicht!',
    en:
      'Add a title to your display. After publication your display will show up in the overview!',
  },
  titlePlaceholder: {
    nl: 'Titel',
    en: 'Title',
  },
  publishSubmitButtonCaption: {
    nl: 'Publiceer',
    en: 'Publish',
  },
  publishedTitle: {
    nl: 'Vitrine gepubliceerd!',
    en: 'Display published!',
  },
  shareWith: {
    nl: 'Deel je verzameling:',
    en: 'Share your collection:',
  },
  shareCollection: {
    nl: 'Deel verzameling',
    en: 'Share collection',
  },
  viewPublishedCanvas: {
    nl: 'Bekijk je verzameling',
    en: 'View your collection',
  },
  allObjects: {
    nl: 'Alle vondsten',
    en: 'All objects',
  },
  collectObject: {
    nl: 'Toevoegen aan je vitrine',
    en: 'Add to your display',
  },
  objectFunFact: {
    nl: 'Over deze vondst',
    en: 'About this object',
  },
  viewDisplayRokin: {
    nl: 'Bekijk op vitrine Rokin',
    en: 'View on display Rokin',
  },
  viewObjectLocation: {
    nl: 'Bekijk de vondstlocatie op het Rokin',
    en: 'View the location of this find on the Rokin',
  },
  viewObjectVitrine: {
    nl: 'Bekijk vondst in de vitrine',
    en: 'View object in the display',
  },
  readAllFunFacts: {
    nl: 'Lees over meer vondsten',
    en: 'Read about other objects',
  },
  replaceCanvasConfirm: {
    nl: 'Let op, je bestaande werk wordt overschreven met deze verzameling.',
    en: 'Beware, existing work will be replaced with this collection.',
  },
  remixCanvasButtonCaption: {
    nl: 'Bewerk deze vitrine',
    en: 'Remix this display',
  },
  imageLoadingFailed: {
    nl: 'Het laden van de vondst is mislukt, probeer het opnieuw',
    en: 'Failed to load the object, please try again',
  },
  confirmCanvasClear: {
    nl: 'Alle vondsten van je verzameling worden weggehaald.',
    en: 'All objects will be removed from your collection.',
  },
  textToolHoverText: {
    nl: 'Voeg tekst toe',
    en: 'Add text',
  },
  gridToolHoverText: {
    nl: 'Zet grid aan',
    en: 'Turn on grid',
  },
  pencilToolHoverText: {
    nl: 'Voeg lijntje toe',
    en: 'Add line',
  },
  clearOneToolHoverText: {
    nl: 'Verwijder vondst',
    en: 'Remove object',
  },
  draftCanvasTitle: {
    nl: 'Mijn verzameling',
    en: 'My Collection',
  },
  publishButtonCaption: {
    nl: 'Publiceer',
    en: 'Publish',
  },
  publishShareText: {
    nl: ':title: Bekijk mijn vitrine op #belowthesurface #spul',
    en: ':title: Check out my display on #belowthesurface #stuff',
  },
  collectedObjectsCount: {
    nl: 'Mijn verzamelde vondsten',
    en: 'My collected objects',
  },
  noCollectedObjectsText: {
    nl:
      'Je kan vondsten verzamelen in het vondstenoverzicht om te gebruiken voor je vitrine.',
    en:
      'By collecting objects on the object overview page you can use them in your collection.',
  },
  toObjectsLink: {
    nl: 'Naar vondstenoverzicht',
    en: 'To object overview',
  },
  precollectedObjectsTitle: {
    nl: 'Aanbevolen vondsten',
    en: 'Featured objects',
  },
  canvasIsLoadingMessage: {
    nl: 'Bezig met laden…',
    en: 'Loading…',
  },
  canvasHasUnsavedChangesMessage: {
    nl: 'Bezig met opslaan...',
    en: 'Saving...',
  },
  HeaderCallToActionHeading: {
    nl: 'Ontdek de vitrines in het metrostation',
    en: 'Explore the displays in the subwaystation',
  },
  HeaderCallToActionIntro: {
    nl:
      'Op het metrostation Rokin hebben de archeologen grote vitrines gemaakt.',
    en:
      'Large showcases have been constructed in subwaystation Rokin by our archaeologists.',
  },
  HeaderCallToActionButton: {
    nl: 'Ontdek alle details',
    en: 'Explore all details',
  },
  canvasOverviewHeaderMostPopulair: {
    nl: 'Uitgelichte vitrines',
    en: 'Featured displays',
  },
  canvasOverviewButtonMostPopulair: {
    nl: 'Maak zelf een vitrine',
    en: 'Create your own display',
  },
  canvasOverviewHeaderAll: {
    nl: 'Alle vitrines',
    en: 'All displays',
  },
  canvasOverviewPrevPage: {
    nl: 'Vorige',
    en: 'Previous',
  },
  canvasOverviewNextPage: {
    nl: 'Volgende',
    en: 'Next',
  },
  canvasOverviewButtonDIY: {
    nl: 'Aan de slag',
    en: 'Get started',
  },
  canvasOverviewHeaderDIY: {
    nl: 'Maak zelf een vitrine met je verzamelde vondsten',
    en: 'Create your own display with your collected finds',
  },
  canvasOverviewPagesTill: {
    nl: 't/m',
    en: 'till',
  },
  canvasOverviewPagesOf: {
    nl: 'van',
    en: 'of',
  },
  emptyCanvasContainerText: {
    nl:
      'Richt je eigen vitrine in door hier je verzamelde vondsten te plaatsen.',
    en: 'Create your own display by adding your collected objects.',
  },
  printCollectedObjects: {
    nl: 'Print je verzamelde vondsten',
    en: 'Print your collected objects',
  },
  downloaderPrintFormat: {
    nl: 'Print Preview',
    en: 'Print Preview',
  },
  downloaderPrintDesc: {
    nl: 'van je zoekresultaten (max. 100)',
    en: 'of your search results (max. 100)',
  },
  downloaderCsvFormat: {
    nl: 'CSV',
    en: 'CSV',
  },
  downloaderCsvDesc: {
    nl: 'van je zoekresultaten (max. 1000)',
    en: 'of your search results (max. 1000)',
  },
  downloaderCsvAllFormat: {
    nl: 'CSV',
    en: 'CSV',
  },
  downloaderCsvAllDesc: {
    nl: 'van alle vondsten',
    en: 'all objects',
  },
  downloaderTitle: {
    nl: 'Downloaden',
    en: 'Download',
  },
  currentPage: {
    nl: ', huidige pagina',
    en: ', current page',
  },
  facebook: {
    nl: 'op Facebook',
    en: 'on Facebook',
  },
  twitter: {
    nl: 'op Twitter',
    en: 'on Twitter',
  },
  dsCode: {
    nl: 'Deventer systeem code',
    en: 'Deventer system code',
  },
  bakselSoort: {
    nl: 'Bakselsoort',
    en: 'Bakselsoort',
  },
  soortVoorwerp: {
    nl: 'Soort voorwerp',
    en: 'Type of object',
  },
  objDiameterMM: {
    nl: '(gereconstrueerde) object diameter in mm',
    en: '(reconstructed) object diameter in mm',
  },
  objHoogteMM: {
    nl: '(gereconstrueerde) object hoogte in mm',
    en: '(reconstructed) object height in mm',
  },
  fragmBreedteMM: {
    nl: 'fragment breedte in mm',
    en: 'fragment width in mm',
  },
  fragmHoogteMM: {
    nl: 'fragment hoogte in mm',
    en: 'fragment height in mm',
  },
  fragmLengteMM: {
    nl: 'fragment lengte in mm',
    en: 'fragment length in mm',
  },
  fragmDikteMM: {
    nl: 'fragment dikte in mm',
    en: 'fragment thickness in mm',
  },
  cerOppBehandeling: {
    nl: 'Oppervlakte behandeling',
    en: 'Surface treatment',
  },
  cerDecoratieTechniekMF: {
    nl: 'Decoratietechniek',
    en: 'Decoration technique',
  },
  cerDecorgroepen: {
    nl: 'Decorgroepen',
    en: 'Image type',
  },
  cerMerk: {
    nl: 'Merk',
    en: 'Mark',
  },
  mtlTotaleLengteMM: {
    nl: 'Totale lengte in mm',
    en: 'Total length in mm',
  },
  mtlTypeLemmet: {
    nl: 'Type lemmet',
    en: 'Blade type',
  },
  mtlTypeKlingvanger: {
    nl: 'Type klingvanger',
    en: 'Balde catcher type',
  },
  mtlTypeHeft: {
    nl: 'Type heft',
    en: 'Grip type',
  },
  mtlMateriaalHeft: {
    nl: 'Materiaal van het heft',
    en: 'Grip material',
  },
  mtlTypeHeftbekroning: {
    nl: 'Type heftbekroning',
    en: 'Pommel cap type',
  },
  mtlProductiemerk: {
    nl: 'Productiemerk',
    en: 'Production mark',
  },
  bwmKleur: {
    nl: 'Kleur',
    en: 'Colour',
  },
  bwmGrootsteLengteMM: {
    nl: 'Grootste lengte in mm',
    en: 'Greatest length in mm',
  },
  bwmGrootsteBreedteMM: {
    nl: 'Grootste breedte in mm',
    en: 'Greatest width in mm',
  },
  bwmGrootsteDikteMM: {
    nl: 'Grootste dikte in mm',
    en: 'Greatest thickness in mm',
  },
  bwmProductiecentrum: {
    nl: 'Productiecentrum',
    en: 'Production centre',
  },
  bwmAfbeelding: {
    nl: 'Type afbeelding',
    en: 'Image type',
  },
  bwmOppervlakte: {
    nl: 'Oppervlaktebehandeling',
    en: 'Surface treatment',
  },
  plantSoort: {
    nl: 'Soort',
    en: 'Species',
  },
  fauSoort: {
    nl: 'Diersoort',
    en: 'Species',
  },
  fauElement: {
    nl: 'Element',
    en: 'Element',
  },
  fauLengteMM: {
    nl: 'Lengte in mm',
    en: 'Length in mm',
  },
  fauBreedteMM: {
    nl: 'Breedte in mm',
    en: 'Width in mm',
  },
  fauHoogteMM: {
    nl: 'Hoogte in mm',
    en: 'Heigth in mm',
  },
  glsKleur: {
    nl: 'Kleur',
    en: 'Colour',
  },
  glsHerkomst: {
    nl: 'Herkomst',
    en: 'Location of production',
  },
  glsDecoratie: {
    nl: 'Decoratie',
    en: 'Decoration',
  },
  objDeelmaterialen: {
    nl: 'Deelmaterialen',
    en: 'Secondary material',
  },
  htProductiewijze: {
    nl: 'Productiewijze',
    en: 'Production method',
  },
  objGrootsteLengteMM: {
    nl: 'Grootste lengte in mm',
    en: 'Largest length in mm',
  },
  objGrootsteBreedteMM: {
    nl: 'Grootste breedte in mm',
    en: 'Largest width in mm',
  },
  objGrootsteHoogteMM: {
    nl: 'Grootste hoogte in mm',
    en: 'Largest height in mm',
  },
  objGrootsteDikteMM: {
    nl: 'Grootste dikte in mm',
    en: 'Largest thickness in mm',
  },
  diameterMM: {
    nl: 'Diameter in mm',
    en: 'Diameter in mm',
  },
  kstProductiecentrum: {
    nl: 'Productiecentrum',
    en: 'Production centre',
  },
  kstEigenaar: {
    nl: 'Eigenaar',
    en: 'Owner',
  },
  kstMerk: {
    nl: 'Merk',
    en: 'Mark',
  },
  kstType: {
    nl: 'Type',
    en: 'Type',
  },
  kstEenheidWaarde: {
    nl: 'Eenheid/waarde',
    en: 'Unit/value',
  },
  leeArcheologischObjectType: {
    nl: 'Type',
    en: 'Type',
  },
  leeVersiering: {
    nl: 'Versieringstechniek',
    en: 'Decoration technique',
  },
  leeSoort: {
    nl: 'Leersoort',
    en: 'Leather type',
  },
  mesAngerOfPlaatangel: {
    nl: 'Angel of plaatangel',
    en: 'Whittle tang or scale tang',
  },
  mesHeftMat1: {
    nl: 'Heft materiaal',
    en: 'Hilt material',
  },
  mesFunctie: {
    nl: 'Functie',
    en: 'Functie',
  },
  mesLemmetLengte: {
    nl: 'Lemmet lengte in mm',
    en: 'Blade length in mm',
  },
  mesHeftLengte: {
    nl: 'Heft lengte in mm',
    en: 'Hilt length in mm',
  },
  mesMinTotaleLengte: {
    nl: 'Grootste lengte',
    en: 'Greatest length',
  },
  mesMinTotaleBreedte: {
    nl: 'Grootste breedte',
    en: 'Greatest width',
  },
  downloaderCanvasFormat: {
    nl: 'Vitrine',
    en: 'Display',
  },
  downloaderCanvasDesc: {
    nl: 'als afbeelding',
    en: 'as image',
  },
  shareBtn: {
    nl: 'Delen op ',
    en: 'Share on ',
  },
  iconTitleDownload: {
    nl: 'Downloaden',
    en: 'Download',
  },
  iconTitleEdit: {
    nl: 'Maak jouw eigen vitrine',
    en: 'Create your own display',
  },
  iconTitleClose: {
    nl: 'Sluiten',
    en: 'Close',
  },
  iconMenuOpen: {
    nl: 'Open menu',
    en: 'Open menu',
  },
  iconMenuClose: {
    nl: 'Sluit menu',
    en: 'Close menu',
  },
  iconScrollDown: {
    nl: 'Scroll naar beneden',
    en: 'Scroll down',
  },
  iconTitleCloseSearch: {
    nl: 'Sluit zoeken',
    en: 'Close search',
  },
  iconTitleOpenSearch: {
    nl: 'Open zoeken',
    en: 'Open search',
  },
  iconTitlePreviousItem: {
    nl: 'Vorige object',
    en: 'Previous object',
  },
  iconTitleNextItem: {
    nl: 'Volgende object',
    en: 'Next object',
  },
  iconTitlePdf: {
    nl: 'Pdf-bestand',
    en: 'Pdf-file',
  },
  iconTitleCsv: {
    nl: 'CSV-bestand',
    en: 'CSV-file',
  },
  iconTitleAllFiles: {
    nl: 'Alle bestanden',
    en: 'All files',
  },
  iconTitleClear: {
    nl: 'Wissen',
    en: 'Clear',
  },
  iconTitleSearch: {
    nl: 'Zoeken',
    en: 'Search',
  },
  iconTitlePrevious: {
    nl: 'Vorige',
    en: 'Previous',
  },
  iconTitleNext: {
    nl: 'Volgende',
    en: 'Next',
  },
  iconTitleAddToCollection: {
    nl: 'Voeg toe aan canvas',
    en: 'Add to canvas',
  },
  muntLandGeo: {
    nl: 'Land (geografisch)',
    en: 'Country (geographical)',
  },
  muntStaat: {
    nl: 'Politieke eenheid',
    en: 'Political entity',
  },
  muntAutoriteit: {
    nl: 'Autoriteit',
    en: 'Authority',
  },
  muntSoort: {
    nl: 'Muntwaarde / Muntsoort',
    en: 'Coin value / coin type',
  },
  muntWaarde: {
    nl: 'Waarde',
    en: 'Value',
  },
  muntOntwerper: {
    nl: 'Ontwerper',
    en: 'Designer',
  },
  muntOnderwerp: {
    nl: 'Onderwerp gelegenheid',
    en: 'Subject of occasion',
  },
  muntPlaats: {
    nl: 'Muntplaats of productieplaats',
    en: 'Location of production',
  },
  stnSubsoort: {
    nl: 'Subsoort',
    en: 'Sub type',
  },
  stnProductiesporen: {
    nl: 'Productiesporen',
    en: 'Production marks',
  },
  pijpModel: {
    nl: 'Model',
    en: 'Model',
  },
  pijpZijmerkLinks: {
    nl: 'Zijmerk links',
    en: 'Mark on side of bowl - left',
  },
  pijpZijmerkRechts: {
    nl: 'Zijmerk rechts',
    en: 'Mark on side of bowl - right',
  },
  pijpBijmerkLinks: {
    nl: 'Bijmerk links',
    en: 'Mark on base of heel - left',
  },
  pijpBijmerkRechts: {
    nl: 'Bijmerk rechts',
    en: 'Mark of mark on base of heel - right',
  },
  pijpMerk: {
    nl: 'Merk of hielmerk',
    en: 'Mark or mark on base of heel',
  },
  pijpOppBehandeling: {
    nl: 'Oppervlaktebehandeling kop',
    en: 'Surface treatment bowl',
  },
  pijpKopopening: {
    nl: 'Kopopening',
    en: 'Bowl opening',
  },
  pijpRadering: {
    nl: 'Radering',
    en: 'Milling',
  },
  pijpKwaliteit: {
    nl: 'Kwaliteit',
    en: 'Quality',
  },
  pijpProductiecentrum: {
    nl: 'Productiecentrum',
    en: 'Production centre',
  },
  pijpPijpenmaker: {
    nl: 'Pijpenmaker',
    en: 'Pipe maker',
  },
  pijpSteelbehandeling: {
    nl: 'Steelbehandeling',
    en: 'Stem treatment',
  },
  kscBaksel: {
    nl: 'Baksel',
    en: 'Fabric',
  },
  kscHoogteMM: {
    nl: 'Hoogte in mm',
    en: 'Height in mm',
  },
  kscBreedteMM: {
    nl: 'Breedte in mm',
    en: 'Height in mm',
  },
  kscDiepteMM: {
    nl: 'Diepte in mm',
    en: 'Depth in mm',
  },
  kscHolOfMassief: {
    nl: 'Hol of massief',
    en: 'Hollow or solid',
  },
  kscVoorstelling: {
    nl: 'Voorstelling',
    en: 'Image description',
  },
  txtBinding: {
    nl: 'Binding',
    en: 'Binding',
  },
  txtBewerking: {
    nl: 'Bewerking',
    en: 'Processing',
  },
  twStrengen: {
    nl: 'Aantal strengen',
    en: 'Number of strands',
  },
  twProductiewijze: {
    nl: 'Productiewijze',
    en: 'Production method',
  },
  objGewicht: {
    nl: 'Gewicht (gr)',
    en: 'Weight (grams)',
  },
  objDeel: {
    nl: 'Deel',
    en: 'Part',
  },
  objPut: {
    nl: 'Putnummer',
    en: 'Trench number',
  },
  objVak: {
    nl: 'Vaknummer',
    en: 'Section number',
  },
  objSpoor: {
    nl: 'Spoornummer',
    en: 'Feature number',
  },
  objVlakHoogte: {
    nl: 'Vlakhoogte (NAP)',
    en: 'Level height (NAP)',
  },
  objTrefwoorden: {
    nl: 'Trefwoorden',
    en: 'Keywords',
  },
  searchOpened: {
    nl: 'Zoeken geopend',
    en: 'Search opened',
  },
  searchClosed: {
    nl: 'Zoeken gesloten',
    en: 'Search closed',
  },
  mapAltLocation: {
    nl: 'kaartweergave lokatie van deze vondst',
    en: 'map representing the location of this object',
  },
  mapYear: {
    nl: 'jaar',
    en: 'year',
  },
  timelineLabel: {
    nl: 'Tijdlijn. Spring snel naar een specifiek jaartal.',
    en: 'Timeline. Quickly jump to a specific year.',
  },
  filterActive: {
    nl: 'Actief filter',
    en: 'Active filter',
  },
  iconClearFilter: {
    nl: 'Filter wissen',
    en: 'Clear filter',
  },
  accessibilityLabelCanvas: {
    nl:
      'Dit is een canvas waarop je vondsten kunt plaatsen en bewerken. Deze afbeelding kun je daarna publiceren als een nieuwe vitrine.',
    en:
      'This is a canvas where you can place objects and edit them. You can publish this image a new display.',
  },
  accessibilityLabelShowcase: {
    nl:
      'Deze pagina bevat een interactieve tour door de vitrines op het Rokin. De interactieve tour is een hele grote afbeelding van de vitrines waar heel diep op kan worden ingezoomd.',
    en:
      'This page contains an interactive tour through the showcases on the Rokin. The interactive tour is a very large image of the showcases that can be zoomed in very deeply.',
  },
  showcaseOpenObject: {
    nl: 'Bekijk vondst',
    en: 'Show object',
  },
};

export default function localize(key, lang, values = {}) {
  const localizationInfo = localizedStrings[key] || {};

  let string = localizationInfo[lang];
  if (typeof string !== 'string') {
    console.warn(
      `No localized string found for key "${key}'" in language "${lang}"`,
    );
    return null;
  }

  for (const [paramKey, paramValue] of Object.entries(values)) {
    string = string.replace(':' + paramKey, paramValue);
  }
  return string;
}
