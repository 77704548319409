import React, { PureComponent } from 'react';
import glamorous from 'glamorous';
import _ from 'lodash';

import Header from 'components/Header';

import Result from 'components/Result';
import responsive from 'components/hoc/responsive';

const COLUMN_TARGET_COUNT = 6;
const MIN_WIDTH = 110;

export default class PrintObjectCollection extends PureComponent {
  imageLoadedCount = 0;

  imageDidLoad = () => {
    const total = this.props.objectCollection.objects.length;
    if (++this.imageLoadedCount === total) {
      window.print();
    }
  };

  render() {
    const { vondstnummers, objectCollection: { objects } } = this.props;
    const sortedObjects = _.sortBy(objects, obj =>
      vondstnummers.indexOf(obj.vondstnummer),
    );

    return (
      <Container>
        <Header />
        <ContentWrapper id="content-wrapper" role="main">
          <Search>
            <Viewport>
              <Results>
                {_.map(sortedObjects, (obj, i) => (
                  <Result
                    key={i}
                    columns={COLUMN_TARGET_COUNT}
                    minWidth={MIN_WIDTH}
                    hit={obj}
                    imageDidLoad={this.imageDidLoad}
                    index={i}
                  />
                ))}
              </Results>
            </Viewport>
          </Search>
        </ContentWrapper>
      </Container>
    );
  }
}

const Container = glamorous.div();

const ContentWrapper = glamorous.div();

const Search = glamorous.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  paddingRight: 16,
});

const Viewport = responsive.section({
  position: 'relative',
});

const Results = glamorous.ul({
  width: '100%',
  listStyle: 'none',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignContent: 'flex-start',
  position: 'absolute',
});
