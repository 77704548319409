import { Component } from 'react';
import PropTypes from 'prop-types';

import { getCurrentParams } from 'lib/history';

export default class LanguageProvider extends Component {
  getChildContext() {
    const lang = getCurrentParams()['lang'] || 'nl';
    return { lang };
  }

  render() {
    return this.props.children;
  }
}

LanguageProvider.childContextTypes = {
  lang: PropTypes.string,
};
