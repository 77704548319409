export default {
  ARROW_RIGHT: [
    'M28.327 17h8.698l2.962 3-3.95 4H27.34l3.949-4-1.778-1.8 1.777 1.8-3.949 4H0v-7h28.327zm-.988 7h8.698L20.24 40l-4.35-4.404L27.34 24zm-7.1-24l16.785 17h-8.698L15.89 4.404 20.24 0z',
  ],
  ARROW_LEFT: [
    'M11.673 23H2.975L.013 20l3.95-4h8.697l-3.949 4 1.778 1.8L8.712 20l3.949-4H40v7H11.673zm.988-7H3.963L19.76 0l4.35 4.404L12.66 16zm7.1 24L2.976 23h8.698L24.11 35.596 19.76 40z',
  ],
  CLOSE: [
    'M15.038 19.987L0 4.95 4.95 0l15.037 15.038L35.025 0l4.95 4.95-15.038 15.037 15.038 15.038-4.95 4.95-15.038-15.038L4.95 39.975 0 35.025l15.038-15.038z',
  ],
  SEARCH: [
    'M16.388 25.777C11.211 25.777 7 21.565 7 16.388S11.211 7 16.388 7s9.39 4.211 9.39 9.388-4.213 9.39-9.39 9.39zm22.83 8.736l-9.14-9.139a16.278 16.278 0 0 0 2.7-8.986C32.777 7.351 25.424 0 16.387 0S0 7.351 0 16.388c0 9.036 7.351 16.39 16.388 16.39 3.19 0 6.16-.93 8.681-2.514l9.2 9.2 4.95-4.95z',
  ],
  ADD: [
    'M12.98 6.154H6.5v27.692h27v-7.692h6V40H.5V0h12.48v6.154zm15.6 4.572h9.188v6.24H28.58v9.188h-6.24v-9.188h-9.188v-6.24h9.188V1.538h6.24v9.188z',
  ],
  EDIT: [
    'M0 0h22v7H7v26h26V18h7v22H0V0zm11.018 23.324l-.03 5.7 5.7.029 16.664-17.031-5.373-5.375-16.961 16.677zM34.627 0l-4.703 4.703 5.374 5.373L40 5.373 34.627 0z',
  ],
  TRASH: [
    'M13.684 12h4.21v20h-4.21V12zm8.421 0h4.21v20h-4.21V12zM9.474 5V0h21.052v5H40v4H0V5h9.474z',
    'M9.474 9v27h21.052V9H9.474zm-4.21-4h29.473v35H5.263V5z',
  ],
  GRID: [
    'M10 30v10H0V30h10zm15 0v10H15V30h10zm15 0v10H30V30h10zM10 15v10H0V15h10zm15 0v10H15V15h10zm15 0v10H30V15h10zM10 0v10H0V0h10zm15 0v10H15V0h10zm15 0v10H30V0h10z',
  ],
  DOWNLOAD: [
    'M40 40H0v-7h40v7zM16.2 16.981V0h6.65v17.919h.001v8.266L20 29l-3.8-3.754v-8.265zm19.208-3.202L22.851 26.185v-8.267l8.399-8.297 4.158 4.158zm-30.803.015l4.16-4.159L16.2 16.98v8.267L4.605 13.794zm30.803-.015h.001v.001l-.001-.001z',
  ],
  FILE_PDF: [
    'M35.5 8h-8V0l8 8zm-17 12h-8v8h8v-8zm17-10v30h-31V0h21v10h10zm-15 8h-12v12h12V18zm11 3h-9v2h9v-2zm0-3h-9v2h9v-2z',
  ],
  FILE_ALL: [
    'M7 37h24v3H4V5h3v32zM27 9h9v26H9V0h18v9zm7 5H11v14h23V14zM18 26h-5v-2h5v2zm14 0H20v-2h12v2zm-14-4h-5v-2h5v2zm14 0H20v-2h12v2zm-14-4h-5v-2h5v2zm14 0H20v-2h12v2zM29 0v7h7l-7-7z',
  ],
  FILE_CSV: [
    'M35.5 8h-8V0l8 8zm-25 13h5v-2h-5v2zm0 8h5v-2h-5v2zm7 0h12v-2h-12v2zm-7-4h5v-2h-5v2zm7-4h12v-2h-12v2zm0 4h12v-2h-12v2zm18-15v30h-31V0h21v10h10zm-4 7h-23v14h23V17z',
  ],
  MENU: ['M0 16.5h40v7H0v-7zM0 33h40v7H0v-7zM0 0h40v7H0V0z'],
  DISPLAY: [
    'M0 40V21h40v19H0zm6-6.001h28V27H6v6.999z',
    'M5 0L0 21.083V27h40v-5.917L35 0H5zm4.74 6h20.52l3.492 15H6.247L9.74 6z',
  ],
  LINE: [
    'M12.727 30.454H40v3.637H12.727v-3.637zM24.985 5L29.1 9.115l-3.6 3.6-4.116-4.114 3.601-3.6zM.023 29.735L19.894 10.09l4.115 4.116L4.365 34.122 0 34.1l.023-4.365z',
  ],
  ALL_OBJECTS: ['M0 0h18v18H0zM22 0h18v18H22zM0 22h18v18H0zM22 22h18v18H22z'],
  FACEBOOK: [
    'M15.14 40V21.754H9v-7.11h6.14V9.398C15.14 3.314 18.857 0 24.286 0c2.6 0 4.835.193 5.487.28v6.36l-3.766.002c-2.952 0-3.524 1.403-3.524 3.461v4.54h7.042l-.917 7.111h-6.125V40H15.14z',
  ],
  TWITTER: [
    'M35.93 13.346c0-.355-.008-.71-.024-1.061A16.696 16.696 0 0 0 40 8.035a16.381 16.381 0 0 1-4.713 1.293 8.234 8.234 0 0 0 3.609-4.54 16.446 16.446 0 0 1-5.211 1.992 8.208 8.208 0 0 0-14.198 5.613 8.2 8.2 0 0 0 .213 1.872c-6.82-.343-12.869-3.61-16.916-8.575a8.178 8.178 0 0 0-1.111 4.125 8.2 8.2 0 0 0 3.651 6.831 8.146 8.146 0 0 1-3.716-1.027c-.002.035-.002.07-.002.105A8.21 8.21 0 0 0 8.19 23.77a8.23 8.23 0 0 1-3.706.14 8.216 8.216 0 0 0 7.666 5.7 16.468 16.468 0 0 1-10.192 3.513c-.662 0-1.316-.038-1.958-.113a23.234 23.234 0 0 0 12.58 3.686c15.095 0 23.35-12.506 23.35-23.35z',
  ],
};
