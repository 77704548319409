import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { isEqual } from 'lodash';
import Stickyfill from 'stickyfilljs';

import * as cssVariable from 'cssVariables';
import * as config from 'config';
import responsive from 'components/hoc/responsive';
import localize from 'components/LocalizedString/Localize';

const SCALE_HEIGHT = 6;
const SCALE_WIDTH = 24;
const SCALE_WIDTH_SMALL = 16;
const SCALE_WIDTH_CURRENT = 52;
const SCALE_WIDTH_SMALL_CURRENT = 24;
const SCALE_MARGIN = '1.2vh';
const SCALE_MARGIN_SMALL = '1vh';

export default class Timeline extends Component {
  static contextTypes = {
    lang: PropTypes.string,
  };

  periodElements = [];

  state = {
    activePeriod: 0,
  };

  constructor(props) {
    super(props);
    this.periods = this.fixHistogram(props.periods);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, true);

    Stickyfill.add(this.scaleElement);

    this.calculateCurrentPeriod();
  }

  shouldComponentUpdate(props, state) {
    return !isEqual(this.props, props) || !isEqual(this.state, state);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  componentWillReceiveProps(nextProps) {
    this.periods = this.fixHistogram(nextProps.periods);
  }

  scaleRef = element => {
    this.scaleElement = element;
  };

  periodRef = element => {
    this.periodElements.push(element);
  };

  onScroll = e =>
    requestAnimationFrame(() => {
      this.calculateCurrentPeriod();
    });

  onPeriodClick = index => {
    let objectCount = 0;

    if (this.periods[index].final) {
      const periodsEndPosition =
        this.props.total / this.props.columnCount * this.props.columnHeight -
        window.innerHeight +
        this.props.gridOffset;

      window.scrollTo(0, periodsEndPosition);

      return;
    }

    for (let i = 0; i < index; i++) {
      objectCount += this.periods[i].count;
    }

    const periodStartPosition = Math.round(
      objectCount / this.props.columnCount * this.props.columnHeight +
        this.props.gridOffset,
    );

    window.scrollTo(0, periodStartPosition);
  };

  calculateCurrentPeriod() {
    const scrollPosition = window.scrollY || window.pageYOffset;
    let objectCount = 0;
    let activePeriod = 0;

    this.periods.forEach((period, index) => {
      if (period.final) {
        const periodsEndPosition =
          this.props.total / this.props.columnCount * this.props.columnHeight -
          window.innerHeight;

        if (periodsEndPosition <= scrollPosition) {
          activePeriod = index;
        }

        return;
      }

      const periodStartPosition =
        Math.floor(
          objectCount / this.props.columnCount * this.props.columnHeight,
        ) + this.props.gridOffset;

      objectCount += period.count;

      if (periodStartPosition <= scrollPosition) {
        activePeriod = index;
      }
    });

    this.setState({
      activePeriod,
    });
  }

  fixHistogram(histogram) {
    if (histogram.length === 0) {
      return histogram;
    }

    const beforeThreshold = histogram.filter(
      period => period.key <= config.YEAR_THRESHOLD,
    );
    const beforeThresholdPeriod = {
      count: beforeThreshold.reduce(
        (total, period) => total + period.doc_count,
        0,
      ),
      year: config.YEAR_THRESHOLD,
    };
    const finalYearPeriod = {
      final: true,
      visible: true,
      year: histogram[histogram.length - 1].key,
    };
    const [latest, ...afterThreshold] = histogram
      .filter(period => period.key > config.YEAR_THRESHOLD)
      .map((period, index) => ({
        count: period.doc_count,
        year: period.key + config.PERIOD_TIMESPAN,
      }));

    const periods = [];

    if (latest && latest.count > 0) {
      const isToInTheFuture = latest.year > new Date().getFullYear();
      const withFixedToYear = isToInTheFuture
        ? { ...latest, year: config.NEWEST_OBJECT_YEAR }
        : latest;
      periods.push(withFixedToYear);
    }

    periods.push(...afterThreshold);

    if (beforeThresholdPeriod.count > 0) {
      periods.push(beforeThresholdPeriod);
    }

    periods.push(finalYearPeriod);

    return periods;
  }

  renderPeriod(period, index) {
    const isCurrent = index === this.state.activePeriod;

    return (
      <Period
        key={period.year}
        innerRef={this.periodRef}
        onClick={this.onPeriodClick.bind(this, index)}
      >
        <Year
          current={isCurrent}
          visible={period.visible || isCurrent}
          className="h2"
        >
          {period.year}
        </Year>
        <TimeIndicator current={isCurrent} />
      </Period>
    );
  }

  render() {
    return (
      <Wrapper>
        <Label className="visually-hidden">
          {localize('timelineLabel', this.context.lang)}
        </Label>
        <Scale innerRef={this.scaleRef}>
          {this.periods.map((period, index) =>
            this.renderPeriod(period, index),
          )}
        </Scale>
      </Wrapper>
    );
  }
}

const Wrapper = glamorous.nav({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  transform: 'translateZ(0)', // This pushes the element to the GPU, avoiding scroll bugs
});

const Label = glamorous.p();

const Scale = glamorous.div({
  position: 'sticky',
  top: 0,
  right: 0,
  height: `100vh`,
  paddingTop: 24,
  paddingBottom: 24,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'flex-end',
  flex: 1,
});

const Period = responsive.button(
  {
    position: 'relative',
    margin: 0,
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
  },
  ({ browser }) =>
    browser.greaterThan.medium && {
      ':hover h2': {
        opacity: 1,
      },
    },
);

const Year = responsive.span(
  {
    marginRight: 70,
    lineHeight: `${SCALE_HEIGHT}px`,
    fontFamily: cssVariable.fontFamilies.maisonMono,
    transition: 'all 0.2s ease-out',
    pointerEvents: 'none',
    position: 'absolute',
    top: SCALE_MARGIN,
    right: 0,
  },
  ({ current, visible, browser }) => ({
    fontSize: current
      ? browser.fits.extraSmall ? 32 : 52
      : browser.fits.extraSmall ? 16 : 26,
    opacity: visible ? 1 : 0,
  }),
  ({ browser }) =>
    browser.fits.extraSmall && {
      marginRight: 30,
    },
);

const TimeIndicator = responsive.div(
  {
    height: SCALE_HEIGHT,
    width: SCALE_WIDTH,
    background: 'black',
    marginTop: SCALE_MARGIN,
    marginBottom: SCALE_MARGIN,
    marginLeft: 20,
    transition: 'width 0.2s ease-out',
    ':nth-child(even) > &': {
      width: SCALE_WIDTH_SMALL,
    },
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      width: SCALE_WIDTH_SMALL,
      marginTop: SCALE_MARGIN_SMALL,
      marginBottom: SCALE_MARGIN_SMALL,
      ':nth-child(even) > &': {
        width: SCALE_WIDTH - SCALE_WIDTH_SMALL,
      },
    },
  ({ current, browser }) =>
    current && {
      width: browser.fits.extraSmall
        ? SCALE_WIDTH_SMALL_CURRENT
        : SCALE_WIDTH_CURRENT,
      ':nth-child(even) > &': {
        width: browser.fits.extraSmall
          ? SCALE_WIDTH_SMALL_CURRENT
          : SCALE_WIDTH_CURRENT,
      },
    },
);
