import React, { Component } from 'react';
import glamorous from 'glamorous';
import qs from 'querystringify';
import { Helmet } from 'react-helmet';

import responsive from 'components/hoc/responsive';
import Container from 'components/util/Container';
import IconButton from 'components/util/IconButton';
import CanvasCollectButton from 'components/CanvasCollectButton';
import Icon from 'components/Icon';
import ObjectNavigator from 'components/ObjectNavigator';
import ObjectDetailListContainer from 'components/ObjectDetail/ObjectDetailListContainer';
import ObjectSpecialDetailListContainer from 'components/ObjectDetail/ObjectSpecialDetailListContainer';
import ObjectDetailDate from 'components/ObjectDetail/ObjectDetailDate';
import LocationMap from 'components/ObjectDetail/LocationMap';
import Header from 'components/Header';
import localize from 'components/LocalizedString/Localize';
import LocalizedString from 'components/LocalizedString';
import Showcase from 'components/Showcase';
import LogoFooter from 'components/LogoFooter';
import { Label } from 'components/util/Texts';
import * as cssVariable from 'cssVariables';
import { routeUtils, getCurrentQueryParams } from 'lib/history';
import ImageContainer from './ImageContainer';
import PropTypes from 'prop-types';

const GRID_GAP = 10;

export default class ObjectDetail extends Component {
  state = { currentImage: 0, isMapExpanded: false };
  static contextTypes = { lang: PropTypes.string };

  setCurrentImage = index => {
    const min = 0;
    const max = this.props.objectData.afbeeldingen.length - 1;
    const currentImage = Math.min(max, Math.max(min, index));
    this.setState({ currentImage });
  };

  componentDidMount() {
    if (!this.props.objectData) {
      this.props.history.replace(routeUtils.notFound());
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.objectData &&
      nextProps.objectData &&
      this.props.objectData.vondstnummer !== nextProps.objectData.vondstnummer
    ) {
      this.setState({ currentImage: 0 });
    }
  }

  formatDateRange(begin, end) {
    if (end && begin !== end) {
      return `${begin}\u2014${end}`;
    }
    return begin;
  }

  expandMap = () => {
    this.setState({ isMapExpanded: true });
    document.body.style.overflow = 'hidden';
  };

  collapseMap = () => {
    this.setState({ isMapExpanded: false });
    document.body.style.overflow = 'visible';
  };

  redirectBack = () => {
    const { index, ...queryString } = qs.parse(window.location.search);
    const url = `${routeUtils.objectOverview()}${qs.stringify(
      queryString,
      true,
    )}`;
    this.props.history.replace(url);
  };

  render() {
    const { objectData, query } = this.props;
    const { currentImage, isMapExpanded } = this.state;

    if (!objectData) {
      return null;
    }

    const dateRange = this.formatDateRange(
      objectData.begin_dat,
      objectData.eind_dat,
    );

    const queryParams = getCurrentQueryParams();

    return (
      <Wrapper>
        <Helmet>
          <title>
            {objectData.object} ({dateRange}) - {objectData.vondstnummer} |{' '}
            {localize('title', this.context.lang)}
          </title>
        </Helmet>
        <Header />
        <ContentWrapper id="content-wrapper" role="main">
          <ImageView>
            <ImageContainer
              vondstnummer={objectData.vondstnummer}
              afbeeldingen={objectData.afbeeldingen}
              currentImage={currentImage}
              sqip={objectData.sqip}
              setCurrentImage={this.setCurrentImage}
              label={objectData.object}
            />
            <ObjectDetailDate
              topDate={objectData.eind_dat}
              bottomDate={objectData.begin_dat}
            />
          </ImageView>
          <Container>
            <Grid top={20}>
              <MobileColumn column={1}>
                <CanvasCollectButton
                  text="collectObject"
                  vondstnummers={[objectData.vondstnummer]}
                  addToCanvas
                />
              </MobileColumn>
              <MobileColumn column={2}>
                <ButtonWrapper>
                  <IconButton
                    onClick={this.redirectBack}
                    icon="allObjects"
                    buttonText="allObjects"
                  />
                  {queryParams.index && (
                    <ObjectNavigator
                      query={query}
                      vondstnummer={objectData.vondstnummer}
                      index={parseInt(queryParams.index, 10)}
                    />
                  )}
                </ButtonWrapper>
              </MobileColumn>
              <Column singleColumn marginOnDesktop>
                <Label>
                  <span className="visually-hidden">
                    {localize('objectnumber', this.context.lang)}
                  </span>
                  {objectData.vondstnummer}
                </Label>
                <Title>{objectData.object}</Title>
              </Column>
              <Column singleColumn>
                <ObjectDetailListContainer
                  object={objectData}
                  dateRange={dateRange}
                />
              </Column>
              <Column singleColumn marginOnMobile>
                <ObjectSpecialDetailListContainer object={objectData} />
              </Column>
            </Grid>
            <MoreInfoGrid>
              {objectData.verhaal && (
                <Column singleColumn>
                  <Subtitle>
                    <LocalizedString string="objectFunFact" />
                  </Subtitle>
                  <StoryTextContainer>
                    <Text>{objectData.verhaal}</Text>
                  </StoryTextContainer>
                  <Link
                    href={routeUtils.storyItem({
                      vondstnummer: objectData.vondstnummer,
                    })}
                    className="icon-hover-right"
                  >
                    <LocalizedString string="readAllFunFacts" />
                    <Icon
                      iconName="arrowRight"
                      color={cssVariable.colorBlack}
                    />
                  </Link>
                </Column>
              )}
              {objectData.locatiekaartje && (
                <Column>
                  <MapContainer isMapExpanded={isMapExpanded}>
                    <LocationMap
                      object={objectData}
                      onCollapse={this.collapseMap}
                      onExpand={this.expandMap}
                      expanded={isMapExpanded}
                    />
                    <Button
                      onClick={this.expandMap}
                      className="icon-hover-right"
                    >
                      <LocalizedString string="viewObjectLocation" />
                      <Icon
                        iconName="arrowRight"
                        color={cssVariable.colorBlack}
                      />
                    </Button>
                  </MapContainer>
                </Column>
              )}
              {objectData.vitrine && (
                <Column>
                  <ShowcaseContainer>
                    <ShowcaseAspectContainer
                      width={1445.24409}
                      height={842.25827}
                    >
                      <Showcase
                        micrioId="aIvVO"
                        vondstnummer={objectData.vondstnummer}
                        style={ShowcaseStyle}
                        disableTour
                        disableControl
                        hideHeader
                        isIncluded
                      />
                    </ShowcaseAspectContainer>
                    <Link
                      href={routeUtils.showcaseItem()}
                      className="icon-hover-right"
                    >
                      <LocalizedString string="viewObjectVitrine" />
                      <Icon
                        iconName="arrowRight"
                        color={cssVariable.colorBlack}
                      />
                    </Link>
                  </ShowcaseContainer>
                </Column>
              )}
            </MoreInfoGrid>
          </Container>
        </ContentWrapper>
        <LogoFooter />
      </Wrapper>
    );
  }
}

const Wrapper = glamorous.section({
  marginBottom: 50,
});

const ContentWrapper = glamorous.div();

const Title = responsive.h1(
  {
    ...cssVariable.title,
    lineHeight: '1.2',
    margin: 0,
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      fontSize: 30,
      marginBottom: 20,
    },
);

const ShowcaseContainer = responsive.div({
  '@media all and (-ms-high-contrast:none)': {
    maxWidth: '40%',
  },
});

const ShowcaseStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

const ShowcaseAspectContainer = responsive.div(
  {},
  ({ width, height }) => ({
    position: 'relative',
    width: '100%',
    paddingBottom: `${height / width * 100}%`,
  }),
  ({ browser }) => browser.fits.small && { maxWidth: '100%' },
);

const MapContainer = responsive.div(
  {
    '@media all and (-ms-high-contrast:none)': {
      maxWidth: '40%',
    },
  },
  ({ isMapExpanded }) => ({
    marginRight: 10,
  }),
  ({ browser }) =>
    browser.fits.small && {
      maxWidth: '100%',
      marginRight: 0,
    },
);

const Subtitle = responsive.h2(
  {
    ...cssVariable.title,
    margin: 0,
    fontSize: 26,
    marginBottom: 15,
  },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      fontSize: 16,
    },
);

const StoryTextContainer = responsive.div({
  paddingRight: 10,
  maxWidth: 500,
});

const linkButtonStyle = {
  display: 'block',
  margin: '15px 0',
  fontWeight: cssVariable.fontWeights.bold,
  fontSize: 18,
  textAlign: 'left',
  padding: 0,
  background: 'none',
  '> svg': {
    marginLeft: 5,
  },
};

const Button = responsive.button(linkButtonStyle);

const Link = responsive.a(linkButtonStyle);

const Text = responsive.p({
  fontSize: 18,
  fontFamily: cssVariable.fontFamilies.maisonNeue,
  margin: 0,
  marginBottom: 10,
});

const ImageView = glamorous.div({
  position: 'relative',
  zIndex: 0,
});

const ButtonWrapper = glamorous.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

const Grid = responsive.div(
  {
    display: 'grid',
    gridGap: GRID_GAP,
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnCount: '2',
  },
  ({ top }) =>
    top && {
      marginTop: top,
    },
);

const Column = responsive.div(
  {
    marginTop: 40 - GRID_GAP,
  },
  ({ browser }) =>
    browser.fits.smallMedium && {
      gridColumn: '1 / 3',
      columnSpan: 'all',
      marginTop: 0,
    },
  ({ singleColumn }) =>
    singleColumn && {
      gridColumn: '1 / 3',
      columnSpan: 'all',
    },
  ({ browser, marginOnMobile }) =>
    marginOnMobile &&
    browser.fits.extraSmall && {
      marginTop: 40 - GRID_GAP,
    },
);

const MobileColumn = responsive.div(
  {
    '> button': {
      marginBottom: 1,
      marginRight: 1,
    },
  },
  ({ browser, column }) =>
    browser.fits.smallMedium &&
    column && {
      gridColumn: column,
      gridRow: 2,
    },
);

const MoreInfoGrid = responsive.div(
  {
    display: 'grid',
    gridGap: GRID_GAP,
    gridTemplateColumns: 'repeat(4, 1fr)',
    marginTop: 40,
  },
  ({ browser }) =>
    browser.fits.smallMedium && {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
);
