import React, { Component } from 'react';
import glamorous from 'glamorous';

import Icon from 'components/Icon';
import * as cssVariable from 'cssVariables';
import { routeUtils } from 'lib/history';
import { generateDownloadExportUrl } from 'api/search';
import LocalizedString from 'components/LocalizedString';

const BASE_STATICS_URL = process.env.REACT_APP_BASE_STATICS_URL;

export default class ResultDownloader extends Component {
  isPrintEnabled() {
    return this.props.hitsTotal > 0 && this.props.hitsTotal <= 100;
  }

  printEnabledCheck = e => {
    if (!this.isPrintEnabled()) {
      e.preventDefault();
    }
  };

  isCSVEnabled() {
    return this.props.hitsTotal > 0 && this.props.hitsTotal <= 1000;
  }

  downloadCsv = () => {
    if (!this.isCSVEnabled()) {
      return;
    }
    window.location.href = generateDownloadExportUrl(this.props.searchFilters);
  };

  downloadCanvasImage = () => {
    const vitrineId = window.location.pathname.replace(/^.+\//, '');
    window.open(
      `${BASE_STATICS_URL}/canvas/original/production-${vitrineId}.jpg`,
      'download',
    );
  };

  render() {
    return (
      <Container isOpen={this.props.isOpen}>
        <Title>
          <LocalizedString string="downloaderTitle" />
        </Title>
        {this.props.isObjectOverview && this.renderObjectOverviewList()}
        {this.props.isSetDetail && this.renderSetDetailList()}
      </Container>
    );
  }

  renderObjectOverviewList() {
    return (
      <List>
        <ListItem aria-hidden={!this.isPrintEnabled()}>
          <DownloadLink
            enabled={this.isPrintEnabled()}
            onClick={this.printEnabledCheck}
            href={`${routeUtils.printObjectOverview()}${
              window.location.search
            }`}
          >
            <Icon iconName="file_pdf" iconSize={40} title="iconTitlePdf" />
            <FormatInfo>
              <Format>
                <LocalizedString string="downloaderPrintFormat" />
              </Format>
              <Desc>
                <LocalizedString string="downloaderPrintDesc" />
              </Desc>
            </FormatInfo>
          </DownloadLink>
        </ListItem>
        <ListItem aria-hidden={!this.isCSVEnabled()}>
          <DownloadButton
            enabled={this.isCSVEnabled()}
            onClick={this.downloadCsv}
          >
            <Icon iconName="file_csv" iconSize={40} title="iconTitleCsv" />
            <FormatInfo>
              <Format>
                <LocalizedString string="downloaderCsvFormat" />
              </Format>
              <Desc>
                <LocalizedString string="downloaderCsvDesc" />
              </Desc>
            </FormatInfo>
          </DownloadButton>
        </ListItem>
        <ListItem>
          <DownloadLink
            enabled
            href={routeUtils.contentPage({ slug: 'publicaties-en-datasets' })}
          >
            <Icon iconName="file_all" iconSize={40} title="iconTitleAllFiles" />
            <FormatInfo>
              <Format>
                <LocalizedString string="downloaderCsvAllFormat" />
              </Format>
              <Desc>
                <LocalizedString string="downloaderCsvAllDesc" />
              </Desc>
            </FormatInfo>
          </DownloadLink>
        </ListItem>
      </List>
    );
  }

  renderSetDetailList() {
    return (
      <List>
        <ListItem>
          <DownloadButton onClick={this.downloadCanvasImage} enabled>
            <Icon iconName="file_csv" iconSize={40} title="iconTitleCsv" />
            <FormatInfo>
              <Format>
                <LocalizedString string="downloaderCanvasFormat" />
              </Format>
              <Desc>
                <LocalizedString string="downloaderCanvasDesc" />
              </Desc>
            </FormatInfo>
          </DownloadButton>
        </ListItem>
      </List>
    );
  }
}

const Container = glamorous.div(
  {
    backgroundColor: 'black',
    padding: 30,
    width: 345,
    maxWidth: '90vw',
    zIndex: 3,
    position: 'relative',
    display: 'none',
    transition: 'opacity 100ms ease-in-out',
  },
  ({ isOpen }) => ({
    display: isOpen && 'block',
    pointerEvents: isOpen ? 'auto' : 'none',
  }),
);

const Title = glamorous.h6({
  color: 'white',
  textTransform: 'uppercase',
  fontSize: 26,
  margin: 0,
  padding: 0,
  fontFamily: cssVariable.fontFamilies.maisonMono,
  fontWeight: cssVariable.fontWeights.bold,
});

const List = glamorous.ul({
  listStyleType: 'none',
  margin: 0,
  padding: 0,
});

const ListItem = glamorous.li({
  marginTop: 25,
});

const DownloadButton = glamorous.button(
  {
    padding: 0,
    margin: 0,
    backgroundColor: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'white',
    textAlign: 'left',
  },
  ({ enabled }) =>
    !enabled && {
      cursor: 'default',
      opacity: 0.2,
      pointerEvents: 'none',
    },
);

const DownloadLink = glamorous.a(
  {
    padding: 0,
    margin: 0,
    backgroundColor: 'inherit',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'white',
    textAlign: 'left',
  },
  ({ enabled }) =>
    !enabled && {
      cursor: 'default',
      opacity: 0.2,
      pointerEvents: 'none',
    },
);

const FormatInfo = glamorous.div({ flexGrow: 42, marginLeft: 20 });

const Format = glamorous.p({
  margin: 0,
  padding: 0,
  lineHeight: 1.11,
  fontSize: 18,
  fontFamily: cssVariable.fontFamilies.maisonNeue,
  fontWeight: cssVariable.fontWeights.bold,
});

const Desc = glamorous.p({
  margin: 0,
  padding: 0,
  lineHeight: 1.33,
  fontSize: 15,
  fontFamily: cssVariable.fontFamilies.maisonNeue,
});
