import promiseContainer from 'components/hoc/promiseContainer';
import { fetchContentPage } from 'api/content';
import withBrowserState from 'components/hoc/withBrowserState';

import BookPage from './BookPage';

function mapPropsToPromises() {
  return {
    content: fetchContentPage('stuff', 'bookPage'),
  };
}

export default promiseContainer(mapPropsToPromises)(withBrowserState(BookPage));
