export const chapters = [
  {
    page: 0,
    number: '',
    title: '',
  },
  {
    page: 9,
    number: '',
    title: 'Foreword',
  },
  {
    page: 11,
    number: '',
    title: 'Amstel, Mirror of the City',
  },
  {
    page: 31,
    number: '',
    title: 'Catalogue Guide',
  },
  {
    page: 33,
    number: 0,
    title: 'Natural & Pre-Urban Environment',
  },
  {
    page: 47,
    number: 1,
    title: 'Buildings & Structures',
  },
  {
    page: 105,
    number: 2,
    title: 'Interiors & Accessories',
  },
  {
    page: 155,
    number: 3,
    title: 'Distribution & Transport',
  },
  {
    page: 189,
    number: 4,
    title: 'Craft & Industry',
  },
  {
    page: 261,
    number: 5,
    title: 'Food Processing & Consumption',
  },
  {
    page: 363,
    number: 6,
    title: 'Science & Technology',
  },
  {
    page: 381,
    number: 7,
    title: 'Arms & Armour',
  },
  {
    page: 411,
    number: 8,
    title: 'Communication & Exchange',
  },
  {
    page: 477,
    number: 9,
    title: 'Games & Recreation',
  },
  {
    page: 507,
    number: 10,
    title: 'Personal Artefacts & Clothing',
  },
  {
    number: '',
    page: 581,
    title: 'Appendix',
  },
  {
    number: '',
    page: 591,
    title: 'Index',
  },
];
