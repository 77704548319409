import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import Icon from 'components/Icon';
import localize from 'components/LocalizedString/Localize';

import * as cssVariable from 'cssVariables';

export default class SearchBar extends PureComponent {
  state = { query: '', focus: false };

  handleSearch = e => {
    e.preventDefault();
    this.searchInput.blur();
    this.props.searchObjects(this.state.query);
  };

  setSearch = e => {
    this.setState({ query: e.target.value });
  };

  onFocus = () => {
    this.setState({ focus: true });
  };

  onBlur = () => {
    this.setState({ focus: false });
  };

  clearInput = () => {
    this.setState({ query: '' });
    this.props.searchObjects('');
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.open && prevProps.open !== this.props.open) {
      this.searchForm.focus();
    }
  }

  componentDidMount() {
    const { searchObjects, searchFilters } = this.props;

    if (searchFilters.q) {
      this.setState({ query: searchFilters.q });
      searchObjects(searchFilters.q);
    }
  }

  render() {
    const { focus, query } = this.state;
    const iconColor = focus ? cssVariable.colorBlack : undefined;

    return (
      <Form
        onSubmit={this.handleSearch}
        tabIndex="0"
        innerRef={form => {
          this.searchForm = form;
        }}
      >
        <LeftButton
          onClick={this.clearInput}
          type="button"
          className="icon-hover-shrink"
        >
          <Icon
            iconName={focus || query ? 'close' : 'search'}
            color={iconColor}
            title={focus || query ? 'iconTitleClear' : 'iconTitleSearch'}
          />
        </LeftButton>
        <SearchLabel htmlFor="search-filters-input" className="visually-hidden">
          {localize('searchPlaceholder', this.context.lang)}
        </SearchLabel>
        <SearchInput
          placeholder={localize('searchPlaceholder', this.context.lang)}
          type="text"
          value={query}
          innerRef={input => {
            this.searchInput = input;
          }}
          onChange={this.setSearch}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          id="search-filters-input"
        />
        <RightButton type="submit" className="icon-hover-right">
          <Icon
            iconName="arrowRight"
            color={iconColor}
            title="iconTitleSearch"
          />
        </RightButton>
      </Form>
    );
  }
}

SearchBar.contextTypes = {
  lang: PropTypes.string,
};

const button = {
  height: 60,
  maxWidth: 60,
  flex: [0, 0, 60],
  paddingLeft: 14,
  paddingRight: 14,
  position: 'absolute',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'none',
  borderRadius: 0,
};

const Form = glamorous.form({
  width: '100%',
  height: 60,
  position: 'relative',
});

const SearchLabel = glamorous.label();

const SearchInput = glamorous.input({
  height: 60,
  width: '100%',
  padding: 14,
  paddingLeft: 50,
  flex: 1,
  backgroundColor: cssVariable.colorDarkGray,
  color: cssVariable.colorGray,
  '&:focus': {
    backgroundColor: cssVariable.colorWhite,
    color: cssVariable.colorBlack,
  },
});

const LeftButton = glamorous.button({
  ...button,
  left: 0,
});

const RightButton = glamorous.button({
  ...button,
  right: 0,
});
