const initialState = {
  from: undefined,
  to: undefined,
};

export default function histogramPreview(state = initialState, action) {
  switch (action.type) {
    case 'SET_HISTOGRAM_PREVIEW':
      return {
        ...state,
        from: action.from,
        to: action.to,
      };
    case 'SET_SEARCH_FILTERS':
      return initialState;
    default:
      return state;
  }
}
