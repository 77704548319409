import React, { Component } from 'react';
import glamorous from 'glamorous';
import responsive from 'components/hoc/responsive';
import LocalizedString from 'components/LocalizedString';

import ObjectDetailListItem, {
  Link,
  ListText,
  ResponsiveIcon,
} from 'components/ObjectDetail/ObjectDetailListItem';

import history, { routeUtils } from 'lib/history';
import * as cssVariable from 'cssVariables';
import { Label } from 'components/util/Texts';
export default class ObjectDetailList extends Component {
  setFilter(filter) {
    history.push(routeUtils.objectOverview());
    this.props.setFilters(filter);
  }

  setClassificatieFilters(levels, classifications) {
    const filters = Object.assign(
      {},
      ...classifications.slice(0, levels + 1).map((classification, index) => {
        const level = index + 1;
        return { ['niveau' + level]: classification };
      }),
    );

    this.setFilter(filters);
  }

  renderDetailListItem(label, value, key, labelValue) {
    if (!value) {
      return null;
    }

    return (
      <ObjectDetailListItem
        onClick={() => this.setFilter({ [key]: value })}
        disabled={!key}
        label={label}
        labelValue={labelValue}
        value={value}
        link={key}
      />
    );
  }

  renderClassificatieItem(value, index, classifications) {
    if (!value) {
      return null;
    }

    return (
      <ListItem key={value}>
        {index === 0 && (
          <Label marginBottom={6}>
            <LocalizedString string="classification" />
          </Label>
        )}
        <Link
          onClick={() => this.setClassificatieFilters(index, classifications)}
          className="icon-hover-right"
        >
          <ListText>{value}</ListText>
          <ResponsiveIcon
            iconName="arrowRight"
            color={cssVariable.colorBlack}
          />
        </Link>
      </ListItem>
    );
  }

  renderDateItem(label, value, object) {
    if (!value) {
      return null;
    }

    const begin = object.begin_dat;
    const end = object.end_dat;
    const date = end ? { from: begin, to: end } : { from: begin, to: begin };

    return (
      <ObjectDetailListItem
        onClick={() => this.setFilter(date)}
        label={label}
        value={value}
        link={false}
        disabled={true} //TODO koppelen, wanneer enkele date supported is als filter
      />
    );
  }

  renderClassificatie(object) {
    if (!object.niveau1) {
      return null;
    }

    const classifications = [
      object.niveau1,
      object.niveau2,
      object.niveau3,
      object.niveau4,
    ];

    return (
      <List>
        {classifications.map((classification, index) =>
          this.renderClassificatieItem(classification, index, classifications),
        )}
      </List>
    );
  }

  getMuntWaardeSoort(object) {
    if (object.munt_eenheid_waarde) {
      return object.munt_eenheid_waarde + ' ' + object.munt_muntsoort;
    }
    return object.munt_muntsoort;
  }

  render() {
    const { object, dateRange } = this.props;

    return (
      <Container>
        <Column>{this.renderClassificatie(object)}</Column>
        <Column>
          <DetailList>
            {this.renderDetailListItem('objDeel', object.objectdeel)}
            {this.renderDateItem('dating', dateRange, object)}
            {this.renderDetailListItem(
              'site',
              object.generated_project,
              'generated_project',
            )}
            {this.renderDetailListItem(
              'material',
              object.subcategorie,
              'subcategorie',
            )}
            {object.munt_muntsoort &&
              this.renderDetailListItem(
                'muntSoort',
                `${this.getMuntWaardeSoort(object)}`,
              )}
          </DetailList>
        </Column>
      </Container>
    );
  }
}

const defaultListProps = {
  listStyleType: 'none',
  margin: 0,
  padding: 0,
};

const Container = glamorous.div({
  display: 'grid',
  gridGap: 10,
  gridTemplateColumns: 'repeat(2, 1fr)',
});

const Column = responsive.div(
  ({ browser }) =>
    browser.fits.smallMedium && {
      gridColumn: '1 / 3',
    },
);

const List = glamorous.ul({
  ...defaultListProps,
  marginBottom: 30,
});

const ListItem = responsive.li(
  {
    fontSize: 26,
    lineHeight: '1.08',
    fontWeight: cssVariable.fontWeights.bold,
    textTransform: 'uppercase',
    marginTop: 8,
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      fontSize: 16,
    },
);

const DetailList = glamorous.ul({
  ...defaultListProps,
  display: 'grid',
  gridGap: 10,
  gridRowGap: 40,
  gridTemplateColumns: 'repeat(2, 1fr)',
  columnCount: 2,
  columnGap: 10,
  '@media all and (-ms-high-contrast:none)': {
    columnCount: '4',
    columnGap: 20,
  },
});
