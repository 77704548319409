import React, { PureComponent } from 'react';
import objectFitImages from 'object-fit-images';
import glamorous from 'glamorous';

const TRANSITION_DURATION = 200;
const BASE_STATICS_URL = process.env.REACT_APP_BASE_STATICS_URL;

export default class SmoothImage extends PureComponent {
  componentDidMount() {
    // This fixes issues for IE11
    // Polyfill needs to be initialized after components are mounted
    objectFitImages(this.imageElement);
    objectFitImages(this.placeholderElement);
  }

  placeholderRef = element => {
    this.placeholderElement = element;
  };

  imageRef = element => {
    this.imageElement = element;
  };

  imageDidLoad = () => {
    this.placeholderElement.style.opacity = 0;
    this.imageElement.style.opacity = 1;

    if (this.props.imageDidLoad) {
      this.props.imageDidLoad();
    }
  };

  render() {
    const { imageSize, image, sqip, label } = this.props;
    const size = imageSize || 'original';

    const filename =
      size === 'original' ? image : image.replace('.jpg', '.png');

    return (
      <ImageWrapper>
        <Image
          src={`${BASE_STATICS_URL}/vondst/${size}/${filename}`}
          onLoad={this.imageDidLoad}
          innerRef={this.imageRef}
          alt={label ? label : ''}
        />
        <Placeholder
          src={`data:image/svg+xml;base64,${sqip}`}
          innerRef={this.placeholderRef}
          alt=""
          aria-hidden="true"
        />
      </ImageWrapper>
    );
  }
}

const ImageWrapper = glamorous.figure({
  width: '100%',
  height: '100%',
  minWidth: '100%',
  minHeight: '100%',
  position: 'relative',
  margin: 0,
  padding: 0,
});

const Placeholder = glamorous.img({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  fontFamily: '"object-fit: contain"', // Dirty IE11 polyfill fix
  transition: `opacity ${TRANSITION_DURATION}ms ease-out ${TRANSITION_DURATION}ms`,
  opacity: 1,
  pointerEvents: 'none',
});

const Image = glamorous.img({
  objectFit: 'contain',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  fontFamily: '"object-fit: contain"', // Dirty IE11 polyfill fix
  transition: `opacity ${TRANSITION_DURATION}ms ease-out`,
  opacity: 0,
});
