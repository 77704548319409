import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Announcements extends Component {
  static contextTypes = {
    lang: PropTypes.string,
  };

  render() {
    return (
      <div
        className="visually-hidden"
        aria-live={this.props.level}
        aria-atomic="true"
        lang={this.context.lang}
      >
        {this.props.message}
      </div>
    );
  }
}
