import React, { Component } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import Header from 'components/Header';
import Container from 'components/util/Container';
import LocalizedString from 'components/LocalizedString';
import Icon from 'components/Icon';
import * as cssVariable from 'cssVariables';
import responsive from 'components/hoc/responsive';
import history, { routeUtils } from 'lib/history';

export default class NotFound extends Component {
  static contextTypes = { lang: PropTypes.string };

  render() {
    return (
      <Wrapper>
        <Header />
        <Container id="content-wrapper" role="main">
          <Title>
            <LocalizedString string="notFound" />
          </Title>
          <div>
            <BlackButton
              onClick={() => history.push(routeUtils.objectOverview())}
              className="icon-hover-right"
            >
              <span>
                <LocalizedString string="exploreAllObjects" />
              </span>
              <Icon iconName="arrowRight" />
            </BlackButton>
            <WhiteButton
              onClick={() => history.push(routeUtils.homepage())}
              className="icon-hover-right"
            >
              <span>
                <LocalizedString string="gotoHomepage" />
              </span>
              <Icon iconName="arrowRight" color="black" />
            </WhiteButton>
          </div>
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = glamorous.section();

const Title = responsive.h1(
  {
    ...cssVariable.title,
    lineHeight: 1.15,
    fontFamily: cssVariable.fontFamilies.maisonNeue,
    maxWidth: 660,
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      fontSize: 42,
    },
);

const ButtonStyle = [
  ({ background, color }) => ({
    background,
    color,
    border: 'none',
    fontWeight: cssVariable.fontWeights.bold,
    pointerEvents: 'all',
    height: 50,
    fontSize: 18,
    padding: '0 24px',
    marginRight: 24,
    '> span': {
      display: 'inline-block',
      paddingRight: 10,
    },
    '> svg': {
      height: 18,
      verticalAlign: 'middle',
    },
  }),
  ({ browser }) =>
    browser.fits.extraSmall && {
      padding: '0 12px',
    },
];

const BlackButton = responsive.button(...ButtonStyle, {
  background: 'black',
  color: 'white',
});

const WhiteButton = responsive.button(...ButtonStyle, {
  background: 'white',
  color: 'black',
});
