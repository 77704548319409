import React, { PureComponent } from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import { Label } from 'components/util/Texts';
import Icon from 'components/Icon';

import * as cssVariable from 'cssVariables';
import responsive from 'components/hoc/responsive';
import AspectContainer from 'components/util/AspectContainer';
import localize from 'components/LocalizedString/Localize';

const OLD_MAP = '1832';
const NEW_MAP = '2008';
const BASE_STATICS_URL = process.env.REACT_APP_BASE_STATICS_URL;

export default class LocationMap extends PureComponent {
  static contextTypes = { lang: PropTypes.string };

  state = {
    currentMap: OLD_MAP,
  };

  componentDidMount() {
    window.addEventListener('keyup', this.listenToEsc);
  }

  componentWillUnmount() {
    // HACK, in de parent wordt body.style.overflow = 'visible' gezet
    // als je op back clickt terwijl map nog open staat gaat ie stuk
    this.props.onCollapse();
    window.removeEventListener('keyup', this.listenToEsc);
  }

  listenToEsc = e => {
    if (e.key === 'Escape' && this.props.expanded) {
      this.props.onCollapse();
    }
  };

  showOldMap = () => {
    this.setState({ currentMap: OLD_MAP });
  };

  showNewMap = () => {
    this.setState({ currentMap: NEW_MAP });
  };

  collapseMap = () => {
    this.props.onCollapse();
  };

  handleClick = () => {
    if (!this.props.expanded) {
      this.props.onExpand();
    }
  };

  render() {
    const { object, expanded } = this.props;
    const { currentMap } = this.state;

    return (
      <Container isExpanded={expanded} onClick={this.handleClick}>
        {expanded && (
          <MapToggle>
            <Label>{localize('mapYear', this.context.lang)}</Label>
            <Button selected={currentMap === OLD_MAP} onClick={this.showOldMap}>
              {OLD_MAP}
            </Button>
            <Button selected={currentMap === NEW_MAP} onClick={this.showNewMap}>
              {NEW_MAP}
            </Button>
          </MapToggle>
        )}
        {expanded && (
          <CloseButton onClick={this.collapseMap}>
            <Icon
              iconName="close"
              color={cssVariable.colorBlack}
              iconSize={cssVariable.iconSizes.large}
              mobileSize={cssVariable.iconSizes.medium}
            />
          </CloseButton>
        )}
        {expanded ? (
          this.renderImages()
        ) : (
          <AspectContainer width={1445.24409} height={842.25827}>
            {this.renderImages()}
          </AspectContainer>
        )}
        {expanded &&
          object.verspreiding_bijschrift && (
            <DescriptionWrapper>
              <DescriptionContainer>
                <Description>{object.verspreiding_bijschrift}</Description>
              </DescriptionContainer>
            </DescriptionWrapper>
          )}
        {expanded && (
          <LegendContainer>
            <Label>fragm/m2</Label>
            <Legend
              src={`${BASE_STATICS_URL}/legenda-${this.context.lang}/${
                object.legenda
              }`}
              alt=""
              role="presentation"
            />
          </LegendContainer>
        )}
      </Container>
    );
  }
  renderImages() {
    const { object, expanded } = this.props;
    const { currentMap } = this.state;

    return (
      <ImageContainer expanded={expanded}>
        <Image
          src={`${BASE_STATICS_URL}/achtergrondkaartjes/achtergrond_${currentMap}.svg`}
          alt=""
          role="presentation"
        />
        <Image
          src={`${BASE_STATICS_URL}/verspreidingskaartjes/${
            object.verspreidingskaartje
          }`}
          alt=""
          role="presentation"
        />
        <Image
          src={`${BASE_STATICS_URL}/achtergrondkaartjes/bouwputten.svg`}
          alt=""
          role="presentation"
        />
        <Image
          src={`${BASE_STATICS_URL}/locatiekaartjes/${object.locatiekaartje}`}
          alt={localize('mapAltLocation', this.context.lang)}
        />
      </ImageContainer>
    );
  }
}

const Container = responsive.div(
  {
    backgroundColor: cssVariable.colorWhite,
  },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      margin: '60px, 0',
    },
  ({ isExpanded }) =>
    isExpanded
      ? {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 10,
        }
      : {
          position: 'relative',
          width: '100%',
          zIndex: 2,
          cursor: 'pointer',
        },
);

const CloseButton = responsive.button({
  background: 'none',
  position: 'absolute',
  right: '5%',
  top: '5%',
  zIndex: 2,
});

const DescriptionWrapper = responsive.div(
  {
    position: 'absolute',
    left: '5%',
    bottom: 0,
    height: 100,
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      display: 'none',
    },
);

const DescriptionContainer = responsive.div(
  {
    position: 'relative',
    height: 100,
    display: 'flex',
    alignItems: 'center',
    width: '50%',
  },
  ({ browser }) =>
    browser.fits.large && {
      width: '70%',
    },
  ({ browser }) =>
    browser.fits.smallMedium && {
      width: '100%',
    },
);

const Description = glamorous.p({
  fontSize: 15,
  margin: 0,
});

const ImageContainer = responsive.div(
  ({ expanded }) =>
    expanded && {
      '> img': {
        height: 'calc(100% - 100px)',
      },
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      '> img': {
        height: '100%',
      },
    },
);

const Button = responsive.button(
  {
    padding: '10px 20px',
    fontSize: 18,
    fontFamily: cssVariable.fontFamilies.maisonMono,
    fontWeight: cssVariable.fontWeights.bold,
    backgroundColor: cssVariable.colorWhite,
    cursor: 'pointer',
  },
  ({ selected }) =>
    selected && {
      backgroundColor: cssVariable.colorBlack,
      color: cssVariable.colorWhite,
    },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      padding: '5px 10px',
      fontSize: 12,
    },
);

const MapToggle = glamorous.div({
  position: 'absolute',
  top: '5%',
  left: '5%',
  zIndex: 2,
  '> span': {
    marginBottom: 15,
  },
});

const Image = responsive.img(
  {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    clipPath: 'inset(50px, 50px)',
    objectFit: 'cover',
    objectPosition: '50% 50%',
  },
  ({ browser }) =>
    browser.greaterThan.extraExtraLarge && {
      objectPosition: '0 50%',
    },
);

const LegendContainer = responsive.div(
  {
    position: 'absolute',
    right: '5%',
    bottom: 120,
    '> span': {
      marginLeft: 20,
      marginBottom: 15,
    },
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      bottom: '5%',
    },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      transform: 'scale(0.5) translate(50%, 50%)',
    },
);

const Legend = responsive.img(
  {
    width: 292,
    height: 78,
  },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      width: '100%',
    },
);
