import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './BookPage.css';
import responsive from '../hoc/responsive';
import * as cssVariable from '../../cssVariables';
import Icon from '../Icon';
import CommonMark from 'commonmark';
import ReactRenderer from 'commonmark-react-renderer';

export default class BookIntro extends PureComponent {
  static contextTypes = {
    lang: PropTypes.string,
  };

  state = {
    isIntroOpen: false,
  };

  onIntroToggle = () => {
    this.setState(prevState => ({ isIntroOpen: !prevState.isIntroOpen }));
  };

  render() {
    const { heading, text, buttonText } = this.props;

    const parser = new CommonMark.Parser();
    const renderer = new ReactRenderer();

    const parsedText = parser.parse(text);
    const renderedText = renderer.render(parsedText);

    return (
      <Container isVisible={this.props.isVisible}>
        {!!buttonText && (
          <Link onClick={this.onIntroToggle} className="icon-hover-right">
            {buttonText}
            <Icon iconName="arrowRight" color="black" />
          </Link>
        )}
        <Wrapper isMobileVisible={this.state.isIntroOpen}>
          <Intro>
            <CloseButton
              onClick={this.onIntroToggle}
              className="icon-hover-shrink"
            >
              <Icon iconName="close" iconSize={cssVariable.iconSizes.medium} />
            </CloseButton>
            {!!heading && <IntroHeading>{heading}</IntroHeading>}
            {!!text && <IntroText>{renderedText}</IntroText>}
          </Intro>
        </Wrapper>
      </Container>
    );
  }
}

const Container = responsive.div(
  {
    transition: 'opacity 0.2s ease-out',
  },
  ({ isVisible }) => ({
    opacity: isVisible ? 1 : 0,
  }),
);

const Wrapper = responsive.div(
  ({ browser }) =>
    browser.fits.small && {
      transition: 'opacity 0.2s ease-out',
      opacity: 0,
      pointerEvents: 'none',
    },
  ({ browser, isMobileVisible }) =>
    browser.fits.small &&
    isMobileVisible && {
      opacity: 1,
      pointerEvents: 'initial',
    },
);

const Intro = responsive.div(
  {
    position: 'absolute',
    left: '70px',
    bottom: 60,
    top: 0,
    width: '40%',
    overflowY: 'auto',
  },
  ({ browser }) =>
    browser.fits.medium && {
      left: '27px',
    },
  ({ browser }) =>
    browser.fits.small && {
      fontSize: 30,
      marginBottom: 20,
      background: 'black',
      color: 'white',
      position: 'relative',
      left: 0,
      padding: '24px',
      margin: '17px 0 96px',
      width: 'auto',
    },
);

const IntroHeading = responsive.h1(
  {
    fontFamily: cssVariable.fontFamilies.maisonMono,
    textTransform: 'uppercase',
    fontSize: '52px',
    fontWeight: 700,
    lineHeight: 1.2,
    marginBottom: '16px',
  },
  ({ browser }) =>
    browser.fits.small && {
      fontSize: 30,
      marginBottom: 20,
    },
);

const IntroText = responsive.div(
  {
    fontFamily: cssVariable.fontFamilies.maison,
    fontSize: '18px',
    fontWeight: 400,
  },
  ({ browser }) =>
    browser.fits.small && {
      fontSize: 16,
      marginBottom: 20,
    },
);

const Link = responsive.a(
  {
    padding: '16px 0',
    marginRight: 8,
    fontSize: 18,
    fontWeight: cssVariable.fontWeights.bold,
    display: 'none',
    color: 'currentColor',
    position: 'absolute',
    bottom: '120px',
    left: '17px',
    '> svg': {
      height: 18,
      marginLeft: 10,
      verticalAlign: 'middle',
      fill: 'currentColor',
    },
    '> svg > path': {
      fill: 'currentColor',
    },
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      display: 'block',
    },
);

const BUTTON_PADDING = 15;
const BUTTON_DIMENS = 50;

const button = {
  backgroundColor: cssVariable.colorBlack,
  height: BUTTON_DIMENS,
};

const iconCenteredButton = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: BUTTON_DIMENS,
};

const CloseButton = responsive.button(
  {
    ...button,
    ...iconCenteredButton,
    paddingLeft: BUTTON_PADDING,
    paddingRight: BUTTON_PADDING,
    marginRight: 1,
    display: 'none',
    lineHeight: 0,
    path: {
      fill: 'black',
    },
  },
  ({ browser }) =>
    browser.fits.small && {
      display: 'block',
      top: '24px',
      right: '24px',
      position: 'absolute',
      margin: '-17px',
    },
);
