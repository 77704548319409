import React, { Component } from 'react';
import glamorous from 'glamorous';
import isEqualShallow from 'is-equal-shallow';

import Result from 'components/Result';
import responsive from 'components/hoc/responsive';

const COLUMN_TARGET_COUNT = 6;
const MIN_WIDTH = 90;

export default class SearchResults extends Component {
  resizeObserver = null;

  rowCount = this.props.total / COLUMN_TARGET_COUNT;
  columnCount = COLUMN_TARGET_COUNT;

  viewportSize = window.innerHeight;
  viewportOffscreenThreshold = 0;

  visibilityRange = { leading: 0, trailing: COLUMN_TARGET_COUNT };

  state = { visibilityRange: this.visibilityRange };

  constructor(props) {
    super(props);
    this.state = { hits: props.hits };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, true);

    this.columnCount = this.calculateComponentRow();
    this.columnHeight = this.calculateComponentSize();

    this.resizeObserver = new window.ResizeObserver(this.onResize);
    this.resizeObserver.observe(this.resizeObserverElement);

    if (this.props.onGridChange instanceof Function) {
      this.props.onGridChange(
        this.columnCount,
        this.columnHeight,
        this.calculateViewportOffsets().top,
      );
    }

    this.viewportOffscreenThreshold = this.calculateViewportOffscreenThreshold();

    this.visibilityRange = this.calculateVisibleComponents();

    this.repositionResults();
    this.resultsPadding();
    this.repositionSentinel();

    this.setState({
      visibilityRange: this.visibilityRange,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.hits !== this.props.hits) {
      this.visibilityRange = this.calculateVisibleComponents(nextProps);

      this.repositionScroll(nextProps);
      this.repositionSentinel(nextProps);
      this.repositionResults();

      this.setState({
        hits: nextProps.hits,
        visibilityRange: this.visibilityRange,
      });
    }
  }

  shouldComponentUpdate(props, state) {
    return (
      !isEqualShallow(this.props, props) || !isEqualShallow(this.state, state)
    );
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true);
  }

  resultsRef = element => {
    this.resultsElement = element;
  };

  viewportRef = element => {
    this.viewportElement = element;
  };

  resizeObserverRef = element => {
    this.resizeObserverElement = element;
  };

  sentinelRef = element => {
    this.sentinelElement = element;
  };

  visibleHits = () => {
    const { leading, trailing } = this.visibilityRange;
    const hits = [];
    for (let i = leading; i <= trailing; i++) {
      const hit = this.state.hits[i];
      hits.push(hit);
    }
    return hits;
  };

  onResize = e => {
    if (!this.resizeObserverElement) {
      return;
    }

    this.columnCount = this.calculateComponentRow();
    this.columnHeight = this.calculateComponentSize();

    if (this.props.onGridChange instanceof Function) {
      this.props.onGridChange(
        this.columnCount,
        this.columnHeight,
        this.calculateViewportOffsets().top,
      );
    }

    this.viewportSize = this.calculateViewportSize();
    this.viewportOffscreenThreshold = this.calculateViewportOffscreenThreshold();

    this.visibilityRange = this.calculateVisibleComponents();

    this.repositionResults();
    this.resultsPadding();
    this.repositionSentinel();

    this.checkForMissingHits();

    this.setState({
      visibilityRange: this.visibilityRange,
    });
  };

  onScroll = e => {
    if (!this.resizeObserverElement) {
      return;
    }

    this.visibilityRange = this.calculateVisibleComponents();

    this.repositionResults();

    this.checkForMissingHits();

    this.setState({
      visibilityRange: this.visibilityRange,
    });
  };

  calculateComponentRow() {
    const viewportWidth = this.calculateViewportOffsets().width;

    if (viewportWidth < 700) {
      return 3;
    }
    if (viewportWidth < 1024) {
      return 4;
    }
    if (viewportWidth < 1280) {
      return 5;
    }

    return 6;
  }

  calculateViewportOffsets() {
    const boundingBox = this.viewportElement.getBoundingClientRect();

    return {
      width: boundingBox.width,
      height: boundingBox.height,
      top: boundingBox.top + (window.scrollY || window.pageYOffset),
      left: boundingBox.left + (window.scrollX || window.pageXOffset),
      bottom: boundingBox.bottom - boundingBox.top - boundingBox.height,
      right: boundingBox.right - boundingBox.left - boundingBox.width,
      offset: boundingBox.top,
    };
  }

  calculateComponentSize() {
    return this.resizeObserverElement.getBoundingClientRect().height;
  }

  calculateViewportSize() {
    return window.innerHeight;
  }

  resultsPadding() {
    return (this.resultsElement.style.paddingBottom = `${this.columnHeight}px`);
  }

  calculateViewportOffscreenThreshold() {
    return this.columnCount * Math.round(this.props.threshold);
  }

  calculateVisibleComponents(props = this.props) {
    const viewportOffset = this.calculateViewportOffsets();

    return {
      leading: Math.max(
        Math.floor(-viewportOffset.offset / this.columnHeight) *
          this.columnCount -
          this.viewportOffscreenThreshold,
        0,
      ),
      trailing: Math.min(
        Math.ceil(
          (-viewportOffset.offset + this.viewportSize) / this.columnHeight,
        ) *
          this.columnCount +
          this.viewportOffscreenThreshold,
        props.total - 1,
      ),
    };
  }

  async checkForMissingHits() {
    const { handleMissingHits, total } = this.props;
    const { isLoadingMore, hits } = this.state;
    const { leading, trailing } = this.visibilityRange;

    if (isLoadingMore) {
      return;
    }

    let missingHitIndices = [];
    for (let i = leading; i <= Math.min(trailing, total - 1); i++) {
      if (!hits[i]) {
        missingHitIndices.push(i);
      }
    }

    if (missingHitIndices.length > 0 && handleMissingHits instanceof Function) {
      this.setState({ isLoadingMore: true });
      const newHits = await handleMissingHits(missingHitIndices);
      this.setState((prevState, props) => {
        return {
          isLoadingMore: false,
          hits: Object.assign({}, prevState.hits, newHits),
        };
      });

      // Could be that the viewport is moved and
      this.checkForMissingHits();
    }
  }

  repositionScroll(props = this.props) {
    const viewportOffset = this.calculateViewportOffsets();
    const height =
      props.total / this.columnCount * this.columnHeight -
      window.innerHeight +
      viewportOffset.top;

    window.scrollTo(0, Math.min(window.scrollY, height));
  }

  repositionSentinel(props = this.props) {
    const height = props.total / this.columnCount * this.columnHeight;

    this.sentinelElement.style.paddingTop = `${height}px`;
  }

  repositionResults() {
    const offset =
      this.visibilityRange.leading / this.columnCount * this.columnHeight;

    this.resultsElement.style.paddingTop = `${offset}px`;
  }

  renderResults() {
    const { leading, trailing } = this.visibilityRange;
    let results = [];
    for (let i = leading; i <= trailing; i++) {
      const hit = this.state.hits[i];

      // We need i for placeholder
      const key = (hit && hit.vondstnummer) || i;

      results.push(
        <Result
          key={key}
          columns={this.columnCount}
          minWidth={MIN_WIDTH}
          hit={hit}
          index={i}
        />,
      );
    }
    return results;
  }

  render() {
    return (
      <Viewport innerRef={this.viewportRef}>
        <Results innerRef={this.resultsRef}>
          <ResizeObserver
            innerRef={this.resizeObserverRef}
            columns={this.columnCount}
          />
          {this.renderResults()}
        </Results>
        <Sentinel innerRef={this.sentinelRef} />
      </Viewport>
    );
  }
}

const Viewport = responsive.section({
  position: 'relative',
});

const Results = glamorous.ul({
  width: '100%',
  listStyle: 'none',
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignContent: 'flex-start',
  position: 'absolute',
});

const ResizeObserver = glamorous.li(
  {
    minWidth: MIN_WIDTH,
    position: 'absolute',
    '&::before': {
      content: ' ',
      display: 'block',
      width: '100%',
      paddingTop: '100%',
    },
  },
  ({ columns }) => ({
    width: `${100 / columns}%`,
  }),
);

const Sentinel = glamorous.div({
  width: 0,
  height: 0,
});
