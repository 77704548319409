import React, { PureComponent } from 'react';
import glamorous from 'glamorous';

import { Fade } from 'components/util/Animations';

import LoaderSvg from './loader.svg';

export default class Loader extends PureComponent {
  render() {
    return (
      <Wrapper>
        <LoaderAnimation src={LoaderSvg} />
      </Wrapper>
    );
  }
}

const Wrapper = glamorous.div({
  background: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 4,
});

const LoaderAnimation = glamorous.img({
  height: 186,
  animation: `${Fade} 0.2s forwards ease-out`,
});
