import { connect } from 'react-redux';

import ResultDownloader from './ResultDownloader';

function mapStateToProps(state) {
  return {
    searchFilters: state.searchFilters,
    hitsTotal: state.search.result ? state.search.result.hits_total : 0,
  };
}

export default connect(mapStateToProps)(ResultDownloader);
