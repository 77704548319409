import { connect } from 'react-redux';

import { searchWithCurrentFilters } from 'actions/search';

import ObjectOverview from './ObjectOverview';

function mapStateToProps(state) {
  return {
    search: state.search,
    searchFilters: state.searchFilters,
    browser: state.browser,
  };
}

const mapDispatchToProps = {
  loadData: searchWithCurrentFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectOverview);
