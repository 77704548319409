export const colorBlack = '#000000';
export const colorWhite = '#ffffff';
export const colorDarkGray = '#1f1f1f';
export const colorGray = '#888888';
export const colorLightGray = '#F3F1ED';

export const fontFamilies = {
  maison: 'Maison',
  maisonNeue: 'Maison Neue',
  maisonMono: 'Maison Mono',
  monospace: 'monospace',
};

export const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
};

export const title = {
  fontSize: 52,
  fontWeight: fontWeights.bold,
  textTransform: 'uppercase',
};

export const gridMargins = {
  desktop: 69,
  tablet: 27,
  mobile: 17,
};

export const iconSizes = {
  large: 40,
  mediumLarge: 26,
  medium: 20,
  small: 16,
  extraSmall: 14,
};
