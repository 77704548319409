import promiseContainer from 'components/hoc/promiseContainer';
import { fetchShowcase, fetchShowcaseTour } from 'api/showcase';
import { getCurrentParams } from 'lib/history';
import withBrowserState from '../hoc/withBrowserState';

import Showcase from './Showcase';

function mapPropsToPromises(props) {
  const lang = getCurrentParams()['lang'] || 'nl';
  return {
    ...props,
    micrioId: 'aIvVO',
    objects: fetchShowcase(lang),
    tour: fetchShowcaseTour(),
  };
}

export default promiseContainer(mapPropsToPromises)(withBrowserState(Showcase));
