import React, { Component } from 'react';
import responsive from 'components/hoc/responsive';

import ResultDownloader from 'components/ResultDownloader';
import Icon from 'components/Icon';

import * as cssVariable from 'cssVariables';
import history, { routeUtils } from 'lib/history';
import glamorous from 'glamorous';

export default class Header extends Component {
  state = { isDownloaderOpen: false };

  componentDidMount() {
    window.addEventListener('click', this.catchClick);
    window.addEventListener('touchstart', this.catchClick);
    window.addEventListener('keyup', this.listenToEsc);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.catchClick);
    window.removeEventListener('touchstart', this.catchClick);
    window.removeEventListener('keyup', this.listenToEsc);
  }

  listenToEsc = e => {
    if (e.key === 'Escape') {
      if (this.state.isDownloaderOpen) {
        this.setState({ isDownloaderOpen: false });
      } else if (this.props.isMenuOpen) {
        this.closeMenu();
      }
    }
  };

  acquireContainer = ref => {
    this.downloaderElement = ref;
  };

  catchClick = e => {
    if (!this.state.isDownloaderOpen) {
      return;
    }

    if (!isDescendantOrDownloader(e.target, this.downloaderElement)) {
      this.setState({ isDownloaderOpen: false });
    }

    function isDescendantOrDownloader(node, downloaderElement) {
      if (node === downloaderElement) {
        return true;
      } else if (node.parentNode) {
        return isDescendantOrDownloader(node.parentNode, downloaderElement);
      } else {
        return false;
      }
    }
  };

  navigateCollection = () => {
    history.push(routeUtils.draftSet());
    this.closeMenu();
  };

  toggleDownloader = () => {
    this.setState(state => ({
      isDownloaderOpen: !state.isDownloaderOpen,
    }));
  };

  openMenu = () => {
    this.props.openMenu();
  };

  closeMenu = () => {
    this.props.closeMenu();
  };

  render() {
    const { theme, isMenuOpen, buttonId } = this.props;
    const iconColor =
      theme === 'light' ? cssVariable.colorWhite : cssVariable.colorBlack;

    const isObjectOverview = routeUtils.isObjectOverview();
    const isSetDetail = routeUtils.isSetDetail();
    const showDownloadButton = (isObjectOverview || isSetDetail) && !isMenuOpen;

    return (
      <NavButtonsWrapper>
        {showDownloadButton && (
          <NavButtonContainer innerRef={this.acquireContainer}>
            <NavButton
              onClick={this.toggleDownloader}
              className="icon-hover-shrink"
              aria-haspopup={true}
              aria-expanded={this.state.isDownloaderOpen}
            >
              <Icon
                iconName="download"
                iconSize={cssVariable.iconSizes.large}
                color={iconColor}
                mobileSize={cssVariable.iconSizes.medium}
                title="iconTitleDownload"
              />
            </NavButton>
            <ArrowUp show={this.state.isDownloaderOpen} />
            <ResultDownloaderContainer
              aria-hidden={!this.state.isDownloaderOpen}
            >
              <ResultDownloader
                isOpen={this.state.isDownloaderOpen}
                isObjectOverview={isObjectOverview}
                isSetDetail={isSetDetail}
              />
            </ResultDownloaderContainer>
          </NavButtonContainer>
        )}
        <NavButtonContainer>
          <NavButton
            onClick={this.navigateCollection}
            className="icon-hover-shrink"
          >
            <Icon
              iconName="edit"
              color={iconColor}
              iconSize={cssVariable.iconSizes.large}
              mobileSize={cssVariable.iconSizes.medium}
              title="iconTitleEdit"
            />
          </NavButton>
        </NavButtonContainer>
        <NavButtonContainer>
          <NavButton
            onClick={isMenuOpen ? this.closeMenu : this.openMenu}
            id={buttonId}
            className="icon-hover-shrink"
            aria-haspopup={true}
            aria-expanded={isMenuOpen}
          >
            <Icon
              iconName={isMenuOpen ? 'close' : 'menu'}
              color={iconColor}
              iconSize={cssVariable.iconSizes.large}
              mobileSize={cssVariable.iconSizes.medium}
              title={isMenuOpen ? 'iconMenuClose' : 'iconMenuOpen'}
            />
          </NavButton>
        </NavButtonContainer>
      </NavButtonsWrapper>
    );
  }
}

const NavButtonsWrapper = glamorous.div();

const NavButtonContainer = responsive.div(
  {
    marginLeft: 20,
    display: 'inline-block',
  },
  ({ browser }) =>
    browser.fits.extremelySmall && {
      marginLeft: 10,
    },
);

const NavButton = responsive.button(
  {
    background: 'none',
    width: cssVariable.iconSizes.large,
    height: cssVariable.iconSizes.large,
    margin: 0,
    padding: 0,
    pointerEvents: 'auto',
  },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      width: cssVariable.iconSizes.medium,
      height: cssVariable.iconSizes.medium,
    },
);

const ArrowUp = responsive.div(
  {
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderBottom: '10px solid black',
    transform: 'translateX(50%)',
  },
  ({ show }) => ({ visibility: show ? 'auto' : 'hidden' }),
  ({ browser }) =>
    browser.fits.extraSmall && {
      borderLeftWidth: 5,
      borderRightWidth: 5,
      borderBottomWidth: 5,
    },
);

const ResultDownloaderContainer = glamorous.div({
  position: 'absolute',
  right: 0,
});
