import React, { PureComponent } from 'react';
import glamorous from 'glamorous';
import responsive from 'components/hoc/responsive';

import Icon from 'components/Icon';
import LocalizedString from 'components/LocalizedString';
import { Label } from 'components/util/Texts';

import * as cssVariable from 'cssVariables';
import withBrowserState from '../hoc/withBrowserState';

export default class ObjectDetailListItem extends PureComponent {
  render() {
    const { label, labelValue, disabled, onClick, value, link } = this.props;
    return (
      <DetailListItem>
        <Label>
          <LocalizedString string={label} values={{ [label]: labelValue }} />
        </Label>
        <Link
          disabled={disabled}
          onClick={() => onClick()}
          className="icon-hover-right"
        >
          <ListText>{value}</ListText>
          {link && (
            <ResponsiveIcon
              iconName="arrowRight"
              color={cssVariable.colorBlack}
            />
          )}
        </Link>
      </DetailListItem>
    );
  }
}

export const ResponsiveIcon = withBrowserState(({ browser, ...props }) => {
  return (
    <Icon
      {...props}
      iconSize={
        browser.fits.extraSmall
          ? cssVariable.iconSizes.extraSmall
          : cssVariable.iconSizes.medium
      }
    />
  );
});

export const DetailListItem = responsive.li(
  {
    fontSize: 26,
    fontWeight: cssVariable.fontWeights.bold,
    textTransform: 'uppercase',
    breakInside: 'avoid',
    ':first-child': {
      marginTop: 0,
    },
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      fontSize: 16,
    },
);

export const Link = glamorous.button(
  {
    padding: 0,
    textAlign: 'left',
    textTransform: 'uppercase',
    background: 'none',
  },
  ({ disabled }) =>
    disabled && {
      cursor: 'default',
    },
);

export const ListText = responsive.span({
  marginRight: 10,
});
