import React, { Component } from 'react';
import objectFitImages from 'object-fit-images';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { createCanvas } from 'api/canvas';
import history, { routeUtils } from 'lib/history';
import * as cssVariable from 'cssVariables';
import responsive from 'components/hoc/responsive';

import LocalizedString from 'components/LocalizedString';
import localize from 'components/LocalizedString/Localize';
import Icon from 'components/Icon';

export default class CanvasPublishedPage extends Component {
  static contextTypes = { lang: PropTypes.string };

  state = {
    title: undefined,
  };

  acquireSubmitButtonRef = el => {
    this.submitButtonRef = el;
  };

  componentDidMount() {
    // This fixes issues for IE11
    // Polyfill needs to be initialized after components are mounted
    objectFitImages();
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      title: newProps.title,
    });
  }

  publish = async e => {
    e.preventDefault();
    this.submitButtonRef.disabled = true;

    // TODO: Show loading state?: https://trello.com/c/QgdLLFcs

    const { canvasData, clearCanvas } = this.props;

    const { id } = await createCanvas({
      ...canvasData,
      ...this.state,
      json: JSON.stringify(canvasData.json),
    });

    clearCanvas();

    this.submitButtonRef.disabled = false;

    history.replace(routeUtils.publishedSet({ id }));
  };

  back = e => {
    history.goBack();
  };

  changed = field => e => {
    this.setState({ [field]: e.target.value });
  };

  render() {
    const { title, canvasData } = this.props;
    return (
      <Wrapper>
        <Close onClick={this.back} className="icon-hover-shrink">
          <Icon
            iconName="close"
            iconSize={cssVariable.iconSizes.large}
            title="iconTitleClose"
          />
        </Close>
        <Content role="main">
          <CanvasContainer>
            <Canvas src={canvasData.dataUrl} />
          </CanvasContainer>
          <Form onSubmit={this.publish}>
            <InputWrapper>
              <Description>
                <LocalizedString string="publishExplainer" />
              </Description>
              <Input
                placeholder={localize('titlePlaceholder', this.context.lang)}
                type="text"
                defaultValue={title}
                onChange={this.changed('title')}
              />
            </InputWrapper>
            <Button
              innerRef={this.acquireSubmitButtonRef}
              type="submit"
              className="icon-hover-right"
            >
              <LocalizedString string="publishSubmitButtonCaption" />
              <Icon iconName="arrowRight" color="black" />
            </Button>
          </Form>
        </Content>
      </Wrapper>
    );
  }
}

const Wrapper = responsive.section(
  {
    background: 'black',
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'flex-start',
    padding: cssVariable.gridMargins.desktop,
  },
  ({ browser }) =>
    browser.fits.medium && {
      padding: cssVariable.gridMargins.tablet,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: cssVariable.gridMargins.mobile,
    },
);

const Close = responsive.button(
  {
    background: 'transparent',
    position: 'absolute',
    top: 60,
    left: cssVariable.gridMargins.desktop,
    padding: 0,
    color: 'white',
    '> svg': {
      verticalAlign: 'middle',
    },
  },
  ({ browser }) =>
    browser.fits.medium && {
      top: cssVariable.gridMargins.tablet,
      left: cssVariable.gridMargins.tablet,
      '> svg': {
        width: 21,
      },
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      position: 'relative',
      top: 'auto',
      left: 'auto',
    },
);

const Content = responsive.div(
  {
    width: '100%',
    display: 'flex',
    marginTop: 90,
  },
  ({ browser }) =>
    browser.fits.medium && {
      marginTop: 70,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      flexDirection: 'column-reverse',
      flexWrap: 'wrap',
      marginTop: 0,
    },
);

const Form = responsive.form(
  {
    width: '40%',
    paddingLeft: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  ({ browser }) =>
    browser.fits.medium && {
      width: '50%',
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      width: '100%',
      marginTop: 24,
      padding: 0,
    },
);

const InputWrapper = glamorous.div();

const Description = glamorous.p({
  color: 'white',
  margin: 0,
  marginBottom: 24,
});

const Input = glamorous.input({
  height: 60,
  width: '100%',
  padding: 14,
  flex: 1,
  marginBottom: 24,
  backgroundColor: cssVariable.colorDarkGray,
  color: cssVariable.colorGray,
  '&:focus': {
    backgroundColor: cssVariable.colorWhite,
    color: cssVariable.colorBlack,
  },
});

const CanvasContainer = responsive.div(
  {
    width: '60%',
  },
  ({ browser }) =>
    browser.fits.medium && {
      width: '50%',
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      width: '100%',
      marginTop: 40,
    },
);

const Canvas = responsive.img({
  width: '100%',
  height: '100%',
  backgroundColor: cssVariable.colorLightGray,
  objectFit: 'contain',
  fontFamily: '"object-fit: contain"', // Dirty IE11 polyfill fix
});

const Button = glamorous.button({
  border: 'none',
  padding: '16px 24px',
  background: 'white',
  color: 'black',
  fontSize: 18,
  fontWeight: cssVariable.fontWeights.bold,
  '> svg': {
    marginLeft: 8,
    verticalAlign: 'middle',
  },
});
