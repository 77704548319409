import promiseContainer from 'components/hoc/promiseContainer';
import { fetchContentPage } from 'api/content';
import withBrowserState from 'components/hoc/withBrowserState';

import HomePage from './HomePage';

function mapPropsToPromises() {
  return {
    content: fetchContentPage('homepage', 'homePage'),
  };
}

export default promiseContainer(mapPropsToPromises)(withBrowserState(HomePage));
