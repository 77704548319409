import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import glamorous from 'glamorous';

import { getStore } from 'store';

import * as cssVariable from 'cssVariables';
import { getCurrentParams } from 'lib/history';
import responsive from 'components/hoc/responsive';
import Icon from 'components/Icon';
import Header from 'components/Header';
import history, { routeUtils } from 'lib/history';

import LocalizedString from 'components/LocalizedString';
import PageTitleHeading from 'components/util/PageTitleHeading';

import MarkerSVG from './marker.svg';
import MarkerBlackSVG from './marker-black.svg';

import './Showcase.css';
import MicrioLoader from '../util/MicrioLoader';
import localize from '../LocalizedString/Localize';

const ROKIN_HEIGHT = 9354;
const ROKIN_WIDTH = 4454;
const ROKIN_OFFSET_LEFT = 0.5;
const ROKIN_OFFSET_TOP = 670;
const ROKIN_OFFSET_BOTTOM = ROKIN_HEIGHT - 670;

const MARKER_CANVAS_MIN = 4;
const MARKER_SIZE = 24;
const MARKER_IMAGE_SIZE = 16;

export default class Showcase extends PureComponent {
  tourStarted = false;

  state = {
    responsiveClass: 'micrio-container',
  };

  async componentDidMount() {
    await MicrioLoader.load();

    this.markerImage = await this.createMarkerImage();
    this.markerImageBlack = await this.createMarkerImageBlack();

    this.micrio = this.initiateMicrio();
    this.markers = this.initiateMarkers();

    this.initializeMicrioObjects();
    this.moveToItemInHash();
    this.autoplayTour();
    this.watchCanvasResize();
  }

  componentWillUnmount() {
    this.micrio.container.removeEventListener('load', this.autoplayTour, true);
    this.micrio.container.removeEventListener(
      'load',
      this.moveToItemInHash,
      true,
    );
    this.micrio.container.removeEventListener(
      'preset',
      this.initializeMicrioObjects,
      true,
    );

    this.micrio = null;
    this.markers = null;

    this.unwatchCanvasResize();
  }

  micrioContainerRef = element => {
    this.micrioContainerElement = element;
  };

  generateMarker = (object, mapping) => {
    const marker = this.markers.addItem({
      id: object.vondstnummer,
      x: mapping.left + parseFloat(object.position.x) / 8,
      y:
        mapping.top +
        (14.4 - (parseFloat(object.position.y) + 2.4)) / 14.4 * mapping.height,
      data: {
        staticPopup: true, // Staticly positioned popup
        doJump: true, // Make a camera zoom out during transition to marker
        notEmpty: true, // Because HTML content is inserted onOpen
      },
    });

    marker.drawCanvas = this.drawMarker;
    marker.onopen = () => {
      this.renderPopup(object, marker.popup._html);
      this.setItemHash(marker);
    };
    marker.onbeforeclose = () => {
      ReactDOM.unmountComponentAtNode(marker.popup._html);
    };
    marker.onclose = this.setItemHash;
    marker.onflownto = this.showMarkerPopup;

    return marker;
  };

  placeMarkers = () => {
    const mapping = {
      height: (ROKIN_OFFSET_BOTTOM - ROKIN_OFFSET_TOP) / ROKIN_HEIGHT,
      top: ROKIN_OFFSET_TOP / ROKIN_HEIGHT,
      left: ROKIN_OFFSET_LEFT / ROKIN_WIDTH,
    };

    if (this.props.objects) {
      this.props.objects.forEach(object =>
        this.generateMarker(object, mapping),
      );
      this.markers.place();
      this.markerTour = this.initiateMarkerTour();
    }
  };

  resolveStartView = () => {
    const vondstnummer = getCurrentParams()['vondstnummer'];

    const marker = vondstnummer && this.markers.getItemById(vondstnummer);
    if (marker) {
      this.micrio.startView = marker.view;
      return;
    }

    if (
      this.props.inCanvasOverview &&
      this.micrio.meta &&
      this.micrio.meta.tours[0]
    ) {
      this.micrio.startView = this.micrio.meta.tours[0].timeline[0].rect;
      return;
    }

    return;
  };

  moveToItemInHash = () => {
    const vondstnummer = getCurrentParams()['vondstnummer'];

    if (!vondstnummer) {
      return;
    }

    if (!this.micrio.inited) {
      this.micrio.container.addEventListener(
        'load',
        this.moveToItemInHash,
        true,
      );

      return;
    }

    const marker = this.markers.getItemById(vondstnummer);

    if (marker && !this.props.isIncluded) {
      marker.open();
    }
  };

  showMarkerPopup = marker => {
    marker.show(true);
  };

  setItemHash = (marker, event) => {
    if (event instanceof Event) {
      history.push(routeUtils.showcase());
      return;
    }

    if (marker && marker.opened) {
      history.push(routeUtils.showcaseItem({ vondstnummer: marker.id }));
    }
  };

  initiateMicrio = () => {
    return new global.Micrio({
      id: this.props.micrioId,
      markerType: 'canvas',
      minimap: true,
      container: this.micrioContainerElement,
      hookDrag: !this.props.disableControl,
      hookScroll: !this.props.disableControl,
      lang: getCurrentParams()['lang'],
      noLogo: true,
      controlType: 'none',
    });
  };

  initializeMicrioObjects = () => {
    if (!this.micrio.camera) {
      this.micrio.container.addEventListener(
        'preset',
        this.initializeMicrioObjects,
        true,
      );

      return;
    }

    this.placeMarkers();
    this.resolveResponsiveClass();
    this.resolveStartView();
  };

  initiateMarkers = () => {
    const markers = new global.Micrio.Markers(
      this.micrio,
      this.props.objects,
      true,
    );

    markers.canvasScales = true;
    markers.canvasRadius = MARKER_SIZE;
    markers.canvasMinSize = MARKER_CANVAS_MIN;
    markers.canvasFillStyle = 'black';

    return markers;
  };

  initiateMarkerTour = () => {
    const markerTour = new global.Micrio.MarkerTour(this.markers, {
      steps: this.props.tour,
      controls: false,
    });

    markerTour.onstop = this.stopTour;

    //marketTour.onstart =

    return markerTour;
  };

  createMarkerImage = () => {
    return new Promise((resolve, reject) => {
      const markerImage = new Image();
      markerImage.onload = () => resolve(markerImage);
      markerImage.src = MarkerSVG;
    });
  };

  createMarkerImageBlack = () => {
    return new Promise((resolve, reject) => {
      const markerImageBlack = new Image();
      markerImageBlack.onload = () => resolve(markerImageBlack);
      markerImageBlack.src = MarkerBlackSVG;
    });
  };

  drawMarker = (ctx, x, y, scale, opened) => {
    if (!this.micrio) {
      return;
    }

    const size = MARKER_SIZE * 2 * scale;
    const imageSize = MARKER_IMAGE_SIZE * 2 * scale;

    const viewport = {
      minWidth: -size,
      maxWidth: this.micrioContainerElement.clientWidth + size,
      minHeight: -size,
      maxHeight: this.micrioContainerElement.clientHeight + size,
    };

    if (
      x < viewport.minWidth ||
      x > viewport.maxWidth ||
      y < viewport.minHeight ||
      y > viewport.maxHeight
    ) {
      return;
    }

    // If is open, invert colors
    ctx.fillStyle = opened ? 'white' : 'black';

    ctx.fillRect(x - size / 2, y - size / 2, size, size);
    ctx.drawImage(
      opened ? this.markerImageBlack : this.markerImage,
      x - imageSize / 2,
      y - imageSize / 2,
      imageSize,
      imageSize,
    );
  };

  autoplayTour = () => {
    if (!this.micrio.inited) {
      this.micrio.container.addEventListener('load', this.autoplayTour, true);

      return;
    }

    if (this.props.autoplayTour) {
      this.startTour();
      this.runTour();
    }
  };

  runTour = () => {
    if (this.micrio && this.micrio.tours && this.micrio.tours.length > 0) {
      this.micrio.tours[0].start();
    }

    setTimeout(() => {
      if (!this.nextTourStep) {
        return;
      }

      this.runTour();
      this.nextTourStep();
    }, this.props.autoplaySpeed || 20000);
  };

  startTour = () => {
    if (!this.markerTour) return;
    this.tourStarted = true;

    // Hotfix: don't do weird jump to first tour step
    this.markerTour.steps[0].jumpTo = false;

    this.markerTour.start();
  };

  stopTour = () => {
    if (!this.markerTour) return;
    this.tourStarted = false;
  };

  nextTourStep = () => {
    if (!this.markerTour) return;
    this.markerTour.next();
  };

  previousTourStep = () => {
    if (!this.markerTour) return;
    this.markerTour.prev();
  };

  openObject = vondstnummer => {
    history.push(routeUtils.objectDetail({ vondstnummer }));
  };

  watchCanvasResize = () => {
    window.addEventListener('resize', this.resolveResponsiveClass, true);
  };

  unwatchCanvasResize = () => {
    window.removeEventListener('resize', this.resolveResponsiveClass, true);
  };

  resolveResponsiveClass = () => {
    if (!this.props.browser) {
      return;
    }

    if (this.props.browser.fits.extraSmall) {
      this.setState({
        responsiveClass: 'micrio-container extra-small',
      });
      return;
    }

    this.setState({
      responsiveClass: 'micrio-container',
    });
  };

  renderPopup = (data, parent) => {
    let labelOpenObject = localize('showcaseOpenObject', this.micrio.lang);

    return ReactDOM.render(
      <Provider store={getStore()}>
        <MicrioMarkerPopup>
          <MicrioMarkerObject>{data.vondstnummer}</MicrioMarkerObject>
          {data.object && (
            <MicrioMarkerTitle>
              {data.object} {data.objectdeel && ` - ${data.objectdeel}`}
            </MicrioMarkerTitle>
          )}
          {data.verhaal && (
            <MicrioMarkerDescription>{data.verhaal}</MicrioMarkerDescription>
          )}
          <MicrioMarkerLink
            onClick={this.openObject.bind(this, data.vondstnummer)}
            className="icon-hover-right"
          >
            {labelOpenObject}
            <Icon iconName="arrowRight" />
          </MicrioMarkerLink>
        </MicrioMarkerPopup>
      </Provider>,
      parent,
    );
  };

  renderTourControls = () => {
    if (this.props.disableTour || !this.props.tour) {
      return;
    }

    return !this.tourStarted ? (
      <MicrioStartTour onClick={this.startTour}>Start tour</MicrioStartTour>
    ) : (
      <MicrioTourButtons>
        <MicrioTourStep
          onClick={this.previousTourStep}
          className="icon-hover-left"
          disabled={this.markerTour.getCurrentStep() === 0}
        >
          <Icon iconName="arrowLeft" title="iconTitlePrevious" />
        </MicrioTourStep>
        <MicrioTourStep
          onClick={this.nextTourStep}
          className="icon-hover-right"
          disabled={
            this.markerTour.getCurrentStep() ===
            this.markerTour.steps.length - 1
          }
        >
          <Icon iconName="arrowRight" title="iconTitleNext" />
        </MicrioTourStep>
      </MicrioTourButtons>
    );
  };

  render() {
    return (
      <Wrapper css={this.props.style}>
        {!this.props.hideHeader && (
          <Header
            hideLogo={true}
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1,
            }}
            role="banner"
          />
        )}
        <PageTitleHeading>
          <LocalizedString string="showcase" />
        </PageTitleHeading>
        <p className="visually-hidden">
          <LocalizedString string="accessibilityLabelShowcase" />
        </p>
        <MicrioContainer
          innerRef={this.micrioContainerRef}
          className={this.state.responsiveClass}
        />
        {this.renderTourControls()}
      </Wrapper>
    );
  }
}

const Wrapper = glamorous.section(
  {
    width: '100vw',
    height: '100vh',
  },
  ({ style }) =>
    style && {
      ...style,
    },
);

const MicrioStartTour = responsive.button(
  {
    position: 'fixed',
    border: 'none',
    color: 'white',
    fontWeight: cssVariable.fontWeights.bold,
    pointerEvents: 'all',
    height: 50,
    fontSize: 18,
    padding: '0 24px',
    backgroundColor: 'black',
    bottom: cssVariable.gridMargins.desktop,
    right: cssVariable.gridMargins.desktop,
    'touch-action': 'none',
    '-ms-touch-action': 'none',
    '-ms-content-zooming': 'none',
  },
  ({ browser }) =>
    browser.fits.medium && {
      bottom: cssVariable.gridMargins.tablet,
      right: cssVariable.gridMargins.tablet,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      bottom: cssVariable.gridMargins.mobile,
      right: cssVariable.gridMargins.mobile,
    },
);

const MicrioTourButtons = responsive.div(
  {
    position: 'fixed',
    bottom: cssVariable.gridMargins.desktop,
    right: cssVariable.gridMargins.desktop,
    'touch-action': 'none',
    '-ms-touch-action': 'none',
    '-ms-content-zooming': 'none',
  },
  ({ browser }) =>
    browser.fits.medium && {
      bottom: cssVariable.gridMargins.tablet,
      right: cssVariable.gridMargins.tablet,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      bottom: cssVariable.gridMargins.mobile,
      right: cssVariable.gridMargins.mobile,
    },
);

const MicrioTourStep = glamorous.button({
  width: 50,
  height: 50,
  marginLeft: 1,
  border: 'none',
  color: 'white',
  fontWeight: cssVariable.fontWeights.bold,
  pointerEvents: 'all',
  fontSize: 20,
  lineHeight: '20px',
  backgroundColor: 'black',
  'touch-action': 'none',
  '-ms-touch-action': 'none',
  '-ms-content-zooming': 'none',
  '> svg': {
    verticalAlign: 'middle',
  },
  ':disabled': {
    opacity: '0.3',
  },
});

const MicrioContainer = glamorous.section({
  width: '100%',
  height: '100%',
});

const MicrioMarkerPopup = glamorous.div();

const MicrioMarkerObject = glamorous.h4({
  fontFamily: cssVariable.fontFamilies.maisonMono,
  fontWeight: cssVariable.fontWeights.regular,
  fontSize: 14,
  margin: 0,
});

const MicrioMarkerTitle = glamorous.h2({
  textTransform: 'uppercase',
  marginBottom: 24,
});

const MicrioMarkerDescription = responsive.p(
  {
    marginBottom: 24,
    maxHeight: '50vh',
    overflow: 'auto',
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      maxHeight: '40vh',
    },
);

const MicrioMarkerLink = glamorous.button({
  backgroundColor: 'black',
  border: 'none',
  color: 'white',
  fontWeight: cssVariable.fontWeights.bold,
  pointerEvents: 'all',
  height: 50,
  fontSize: 18,
  padding: '0 24px',
  'touch-action': 'none',
  '-ms-touch-action': 'none',
  '-ms-content-zooming': 'none',
  '> svg': {
    height: 18,
    marginLeft: 16,
    verticalAlign: 'middle',
  },
});
