import _ from 'lodash';
import qs from 'querystringify';

import { postJSON, getJSON } from 'api/_helpers';

export async function createCanvas(canvasObject) {
  const response = await postJSON('/canvas', canvasObject);
  return await response.json();
}

export async function fetchCanvas(id, edit) {
  const filteredParams = _.pickBy({ id, edit }, _.identity);
  return await getJSON(`/canvas?${qs.stringify(filteredParams)}`);
}

const canvasCache = new Map();

export async function featuredCanvas() {
  if (!canvasCache.has('featured')) {
    const featured = await getJSON(`/canvas/featured`);
    canvasCache.set('featured', featured);
  }
  return canvasCache.get('featured');
}

export async function mostRecentCanvas(nextPageToken, limit) {
  const params = {};
  if (nextPageToken) {
    params.nextPageToken = nextPageToken;
  }
  if (limit) {
    params.limit = limit;
  }

  const search = qs.stringify(params, true);
  if (!canvasCache.has(search)) {
    const mostRecent = await getJSON(`/canvas/list${search}`);
    canvasCache.set(search, mostRecent);
  }
  return canvasCache.get(search);
}
