const initialState = {
  from: undefined,
  to: undefined,
};

export default function searchFilters(state = initialState, action) {
  switch (action.type) {
    case 'SET_SEARCH_FILTERS':
      return action.filters;
    default:
      return state;
  }
}
