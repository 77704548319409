export default class WindowOpener {
  static openSocialShare = e => {
    e.preventDefault();

    const width = 460;
    const height = 500;

    const screenTop = window.screenTop || window.screenY;
    const screenLeft = window.screenLeft || window.screenX;
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth;

    const left = windowWidth / 2 - width / 2 + screenLeft;
    const top = windowHeight / 2 - height / 2 + screenTop;

    window.open(
      e.currentTarget.href,
      e.currentTarget.title,
      `width=${width},height=${height},top=${top},left=${left}`,
    );
  };
}
