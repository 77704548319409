import { createStore, applyMiddleware, compose } from 'redux';
import { responsiveStoreEnhancer } from 'redux-responsive';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import * as reducers from './reducers';
import middleware from './middleware';

const persistConfig = {
  key: 'bts',
  storage,
  whitelist: ['canvasEditor'],
  debug: true,
};

const rootReducer = persistCombineReducers(persistConfig, reducers);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(responsiveStoreEnhancer, applyMiddleware(...middleware)),
);

export const persistor = persistStore(store);

export function getStore() {
  return store;
}

export function getPersistor() {
  return persistor;
}
