import React, { Component } from 'react';

import * as cssVariable from 'cssVariables';
import responsive from 'components/hoc/responsive';
import LogoLight from './logo-light.svg';
import LogoDark from './logo-dark.svg';

export default class LogoFooter extends Component {
  render() {
    return (
      <Logo
        alt="Gemeente Amsterdam, logo"
        inSearchPage={this.props.inSearchPage}
      />
    );
  }
}

const Logo = responsive.div(
  {
    background: `transparent url(${LogoDark}) no-repeat center center`,
    backgroundSize: '100%',
    width: 150,
    height: 75,
    margin: cssVariable.gridMargins.desktop,
  },
  ({ inSearchPage }) =>
    inSearchPage && {
      backgroundImage: `url(${LogoLight})`,
      margin: 'auto 12px 0 12px',
    },
  ({ browser }) =>
    browser.fits.medium && {
      margin: cssVariable.gridMargins.tablet,
      width: 100,
      height: 50,
    },
  ({ browser, inSearchPage }) =>
    browser.fits.medium &&
    inSearchPage && {
      margin: 'auto 10px 0 10px',
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      margin: cssVariable.gridMargins.mobile,
    },
  ({ browser, inSearchPage }) =>
    browser.fits.extraSmall &&
    inSearchPage && {
      margin: 'auto 10px 0 10px',
    },
  ({ browser, inSearchPage }) =>
    browser.fits.extraExtraSmall &&
    inSearchPage && {
      display: 'none',
    },
);
