import React, { PureComponent } from 'react';

import ICONS from 'components/Icon/icons';
import * as cssVariable from 'cssVariables';
import responsive from 'components/hoc/responsive';
import LocalizedString from 'components/LocalizedString';

import uniqueId from 'lodash/uniqueId';

export default class Icon extends PureComponent {
  getIconSource(iconName) {
    switch (iconName) {
      case 'arrowRight':
        return ICONS.ARROW_RIGHT;
      case 'arrowLeft':
        return ICONS.ARROW_LEFT;
      case 'close':
        return ICONS.CLOSE;
      case 'search':
        return ICONS.SEARCH;
      case 'add':
        return ICONS.ADD;
      case 'edit':
        return ICONS.EDIT;
      case 'grid':
        return ICONS.GRID;
      case 'trash':
        return ICONS.TRASH;
      case 'download':
        return ICONS.DOWNLOAD;
      case 'file_pdf':
        return ICONS.FILE_PDF;
      case 'file_all':
        return ICONS.FILE_ALL;
      case 'file_csv':
        return ICONS.FILE_CSV;
      case 'menu':
        return ICONS.MENU;
      case 'display':
        return ICONS.DISPLAY;
      case 'line':
        return ICONS.LINE;
      case 'allObjects':
        return ICONS.ALL_OBJECTS;
      case 'facebook':
        return ICONS.FACEBOOK;
      case 'twitter':
        return ICONS.TWITTER;
      default:
        return ICONS.CLOSE;
    }
  }

  componentWillMount() {
    const id = uniqueId('svg-icon-');
    this.setState({ id: id });
  }

  render() {
    const { iconSize, mobileSize, iconName, color, title } = this.props;

    const icon = this.getIconSource(iconName);
    const size = iconSize || cssVariable.iconSizes.small;
    const mobSize = mobileSize || cssVariable.iconSizes.small;
    const fill = color || cssVariable.colorWhite;
    const id = this.state.id;
    const role = title ? 'img' : 'presentation';

    return (
      <Svg
        size={size}
        mobileSize={mobSize}
        viewBox="0 0 40 40"
        role={role}
        aria-labelledby={title ? id : false}
        aria-hidden={!title}
      >
        {title && (
          <title id={id}>
            <LocalizedString string={title} />
          </title>
        )}
        {icon.map((path, idx) => <path key={idx} fill={fill} d={path} />)}
      </Svg>
    );
  }
}

const Svg = responsive.svg(
  {
    flex: '1 0 auto',
    display: 'inline',
  },
  ({ size }) =>
    size && {
      width: size,
      height: size,
    },
  ({ browser, mobileSize }) =>
    browser.fits.extraExtraSmall && {
      width: mobileSize,
      height: mobileSize,
    },
);
