import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import App from 'components/App';
import registerServiceWorker from './registerServiceWorker';

import './polyfills';

ReactDOM.render(<App />, document.getElementById('root'));
ReactGA.initialize('UA-116890572-1', {
  gaOptions: {
    anonymizeIp: true,
  },
});

registerServiceWorker();
