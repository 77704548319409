import { connect } from 'react-redux';

import { setQuery } from 'actions/search';
import SearchBar from './SearchBar';

function mapStateToProps(state) {
  const { searchFilters } = state;
  return { searchFilters };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    searchObjects(query) {
      const action = setQuery(query);
      return dispatch(action);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
