import _ from 'lodash';
import { createResponsiveStateReducer } from 'redux-responsive';

export default createResponsiveStateReducer(
  {
    extremelySmall: 320,
    extraExtraSmall: 480,
    extraSmall: 700,
    small: 768,
    smallMedium: 900,
    medium: 1024,
    large: 1280,
    extraLarge: 1440,
    extraExtraLarge: 1680,
    extremelyLarge: 1920,
  },
  {
    extraFields: ({ lessThan, is }) => ({
      // Create an easy to understand "fits" method
      fits: _.transform(
        lessThan,
        (result, value, mediaType) => {
          result[mediaType] = value || is[mediaType];
        },
        {},
      ),
    }),
  },
);
