export default [
  'NZR2.00522MTL041',
  'NZD1.00602MTL001',
  'NZD1.00247MTL002',
  'NZR2.00216CER001',
  'NZR2.00083CER001',
  'NZD1.00093KST004',
  'NZR2.00657GLS034',
  'NZR2.00512MTL084',
  'NZD1.00077FAU004-01',
  'NZD1.00368MTL001',
  'NZD1.00070HT009',
  'NZR2.00729BWM006',
  'NZR2.00022CER001',
  'NZD1.00323KST001',
  'NZD1.00643MTL002',
  'NZD1.00613GLS003',
  'NZD1.00631CER015',
  'NZR2.00573MTL007',
  'NZV1.00013MTL004',
  'NZR2.00176MTL002',
  'NZR2.00130CPY008-01',
  'NZD1.00416MTL007',
  'NZD1.00391CER147',
  'NZD1.00365MTL016',
  'NZD1.00442FAU026',
  'NZD1.00068KST014',
  'NZD1.00540MIX001',
  'NZR2.00599MTL645',
  'NZD1.00594MTL003',
  'NZD1.00059KST008',
  'NZR2.00523MTL204',
  'NZD1.00096MTL014',
  'NZD1.00072LEE022',
  'NZS1.00003MTL003',
];
