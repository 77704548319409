import { connect } from 'react-redux';

import withPersistGate from 'components/hoc/withPersistGate';
import CanvasEditor from './CanvasEditor';

function mapStateToProps(state, ownProps) {
  const { canvasEditor } = state;
  return { data: canvasEditor };
}

function mapDispatchToProps(dispatch) {
  return {
    pinDimensions: params => dispatch({ type: 'CE_PIN_DIMENSIONS', ...params }),
    save: params => dispatch({ type: 'CE_SAVE', ...params }),
    clear: () => dispatch({ type: 'CE_CLEAR' }),
    addedToCanvas: vondstnummer =>
      dispatch({ type: 'CE_ADDED_AUTOMATICALLY', vondstnummer }),
  };
}

const CanvasEditorWithPersistGate = withPersistGate(CanvasEditor);

export default connect(mapStateToProps, mapDispatchToProps)(
  CanvasEditorWithPersistGate,
);
