import qs from 'querystringify';

import promiseContainer from 'components/hoc/promiseContainer';
import { fetchSearch } from 'api/search';

import PrintObjectOverview from './PrintObjectOverview';

function mapPropsToPromises({ history }) {
  const queryStringParams = qs.parse(history.location.search);
  return {
    search: fetchSearch(queryStringParams, 0, 100),
  };
}

export default promiseContainer(mapPropsToPromises)(PrintObjectOverview);
