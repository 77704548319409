import qs from 'querystringify';

import promiseContainer from 'components/hoc/promiseContainer';
import { fetchSearch } from 'api/search';

import ObjectNavigator from './ObjectNavigator';

function mapPropsToPromises({ query, ...props }) {
  const parsedQuery = qs.parse(query);
  const offset = Math.max(parsedQuery.index - 1, 0);
  return {
    ...props,
    query: parsedQuery,
    objects: fetchSearch(parsedQuery, offset, 3),
  };
}

export default promiseContainer(mapPropsToPromises)(ObjectNavigator);
