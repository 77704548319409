import { connect } from 'react-redux';

import CanvasCollectButton from './CanvasCollectButton';

function mapDispatchToProps(dispatch) {
  return {
    collect: params =>
      dispatch({ type: 'CE_COLLECT_VONDSTNUMMERS', ...params }),
  };
}

export default connect(null, mapDispatchToProps)(CanvasCollectButton);
