import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import SmoothImage from 'components/util/SmoothImage';
import Header from 'components/Header';
import Icon from 'components/Icon';
import Container from 'components/util/Container';
import { Label } from 'components/util/Texts';
import LocalizedString from 'components/LocalizedString';
import PageTitleHeading from 'components/util/PageTitleHeading';

import responsive from 'components/hoc/responsive';
import { routeUtils, getCurrentParams } from 'lib/history';
import * as cssVariable from 'cssVariables';
import glamorous from 'glamorous';

export default class StoryPage extends Component {
  state = {
    storyAmount: 10,
  };

  isLastPage = () => {
    return this.state.storyAmount > this.props.storyData.length;
  };

  increaseStoryAmount = () => {
    if (!this.isLastPage()) {
      this.setState({ storyAmount: this.state.storyAmount + 10 });
    }
  };

  selectStories() {
    const { storyData } = this.props;
    const storyId = getCurrentParams().vondstnummer;

    if (storyId) {
      const story = storyData.find(story => story.vondstnummer === storyId);
      const remainingStories = storyData.filter(
        story => story.vondstnummer !== storyId,
      );
      remainingStories.unshift(story);
      return remainingStories.slice(0, this.state.storyAmount);
    } else {
      return storyData.slice(0, this.state.storyAmount);
    }
  }

  renderStories() {
    const storySelection = this.selectStories();

    return storySelection.map(item => (
      <StoryItem key={item.vondstnummer} id={item.vondstnummer}>
        <ImageBox>
          <SmoothImage
            image={item.afbeeldingen[0]}
            imageSize={600}
            sqip={item.sqip}
            label={item.object}
          />
        </ImageBox>
        <TextContainer>
          <Label>{item.vondstnummer}</Label>
          <NavLink
            to={routeUtils.objectDetail({
              vondstnummer: item.vondstnummer,
              lang: this.context.lang,
            })}
            className="icon-hover-right"
          >
            <LinkText>{item.object}</LinkText>
            <Icon
              iconName="arrowRight"
              iconSize={cssVariable.iconSizes.large}
              mobileSize={cssVariable.iconSizes.medium}
              color={cssVariable.colorBlack}
            />
          </NavLink>
          <Text>{item.verhaal}</Text>
        </TextContainer>
      </StoryItem>
    ));
  }

  render() {
    return (
      <div>
        <Header />
        <ContentWrapper id="content-wrapper" role="main">
          <PageTitleHeading>
            <LocalizedString string="stories" />
          </PageTitleHeading>
          <Container>
            <GridContainer>{this.renderStories()}</GridContainer>
            {!this.isLastPage() && (
              <CenterContainer>
                <Button onClick={this.increaseStoryAmount}>
                  <LocalizedString string="showMoreResults" />
                </Button>
              </CenterContainer>
            )}
          </Container>
        </ContentWrapper>
      </div>
    );
  }
}

StoryPage.contextTypes = {
  lang: PropTypes.string,
};

const ContentWrapper = responsive.div();

const GridContainer = responsive.div(
  {
    maxWidth: 1080,
    margin: '160px auto 100px auto',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: 120,
    '> :nth-child(even)': {
      '> :first-child': {
        order: 2,
        justifySelf: 'end',
      },
      '> :last-child': {
        order: 1,
      },
    },
  },
  ({ browser }) =>
    browser.fits.small && {
      gridGap: 60,
      margin: '40px auto',
      '> :nth-child(even)': {
        '> :first-child': {
          order: 1,
          justifySelf: 'begin',
        },
        '> :last-child': {
          order: 2,
        },
      },
    },
);

const StoryItem = responsive.article(
  {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  ({ browser }) =>
    browser.fits.small && {
      gridTemplateColumns: '1fr',
    },
);

const CenterContainer = responsive.div(
  {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 100,
  },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      marginBottom: 40,
    },
);

const Button = glamorous.button({
  backgroundColor: cssVariable.colorBlack,
  color: cssVariable.colorWhite,
  padding: '10px 25px',
  fontWeight: cssVariable.fontWeights.bold,
});

const ImageBox = responsive.div(
  {
    position: 'relative',
    width: '100%',
    maxWidth: 400,
    height: 400,
    pointerEvents: 'auto',
  },
  ({ browser }) =>
    browser.fits.small && {
      justifySelf: 'center',
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      maxWidth: 220,
      height: 220,
    },
);

const TextContainer = responsive.div(
  {
    alignSelf: 'center',
  },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      marginTop: 30,
    },
);

const Text = responsive.p(
  {
    fontSize: 18,
    margin: 0,
    marginTop: 32,
    fontFamily: cssVariable.fontFamilies.maisonNeue,
  },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      fontSize: 15,
      marginTop: 24,
    },
);

const LinkText = responsive.h2(
  {
    ...cssVariable.title,
    display: 'inline',
    lineHeight: '1.15',
    marginRight: 12,
  },
  ({ browser }) =>
    browser.fits.extraExtraSmall && {
      fontSize: 30,
    },
);
