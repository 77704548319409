import _ from 'lodash';

const initialState = {
  dimensions: null,
  json: null,
  svg: null,
  dataUrl: null,
  usedVondstnummers: [],
  collectedVondstnummers: [],
  addToCanvas: [],
  isEmpty: true,
};

const MAX_COLLECTED_VONDSTNUMMERS = 250;

export default function canvasEditor(state = initialState, action) {
  switch (action.type) {
    case 'CE_PIN_DIMENSIONS':
      return {
        ...state,
        dimensions: {
          width: action.width,
          height: action.height,
        },
      };
    case 'CE_SAVE':
      return {
        ...state,
        json: action.json,
        svg: action.svg,
        dataUrl: action.dataUrl,
        usedVondstnummers: action.usedVondstnummers,
        isEmpty: action.isEmpty,
      };
    case 'CE_LOAD':
      return {
        ...state,
        dimensions: { width: action.canvasWidth, height: action.canvasHeight },
        json: JSON.parse(action.json),
        svg: action.svg,
        dataUrl: null,
        usedVondstnummers: action.usedVondstnummers,
        collectedVondstnummers: _.chain(action.usedVondstnummers)
          .concat(state.collectedVondstnummers)
          .uniq()
          .slice(0, MAX_COLLECTED_VONDSTNUMMERS)
          .value(),
      };
    case 'CE_CLEAR':
      return {
        ...initialState,
        collectedVondstnummers: state.collectedVondstnummers,
      };
    case 'CE_COLLECT_VONDSTNUMMERS':
      return {
        ...state,
        collectedVondstnummers: _.chain(action.vondstnummers)
          .concat(state.collectedVondstnummers)
          .uniq()
          .slice(0, MAX_COLLECTED_VONDSTNUMMERS)
          .value(),
        addToCanvas: _.chain(state.addToCanvas)
          .concat(action.addToCanvas ? action.vondstnummers : [])
          .uniq()
          .value(),
      };
    case 'CE_ADDED_AUTOMATICALLY':
      return {
        ...state,
        addToCanvas: state.addToCanvas.filter(
          vondstnummer => vondstnummer !== action.vondstnummer,
        ),
      };
    default:
      return state;
  }
}
