import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Container from 'components/util/Container';
import NavigationControl from 'components/Header/NavigationControl';
import LocalizedString from 'components/LocalizedString';
import Icon from 'components/Icon';

import * as cssVariable from 'cssVariables';
import {
  isCurrentPathSelected,
  getCurrentParams,
  routeUtils,
} from 'lib/history';
import responsive from 'components/hoc/responsive';
import getNavItems from './items';
import LogoLight from './logo-light.svg';
import LogoDark from './logo-dark.svg';

export const MENU_CONTAINER_ANIM_DURATION = 300;

export default class Menu extends Component {
  closeMenu = () => {
    this.props.closeMenu();
  };

  openMenu = () => {
    this.props.openMenu();
  };

  render() {
    const { isMenuOpen } = this.props;
    const currentLang = getCurrentParams()['lang'];

    return (
      <Wrapper isMenuOpen={isMenuOpen} aria-hidden={!isMenuOpen} role="menu">
        <MenuContainer isMenuOpen={isMenuOpen}>
          <Container
            style={{
              display: isMenuOpen ? 'flex' : 'none',
              ...ContainerStyling,
            }}
          >
            <ControlContainer>
              <NavigationControl
                theme="light"
                isMenuOpen={isMenuOpen}
                openMenu={this.openMenu}
                closeMenu={this.closeMenu}
                buttonId="insideMenuToggle"
              />
            </ControlContainer>
            <Nav>
              <MainItems>
                {getNavItems().main.map((item, key) => {
                  return (
                    <NavItem
                      key={key}
                      selected={isCurrentPathSelected(item.path)}
                    >
                      <ItemLink
                        to={item.path}
                        onClick={this.closeMenu}
                        aria-describedby={
                          isCurrentPathSelected(item.path)
                            ? 'current-text'
                            : false
                        }
                      >
                        {isCurrentPathSelected(item.path) && (
                          <Icon
                            iconName="arrowRight"
                            color={cssVariable.colorWhite}
                            iconSize={cssVariable.iconSizes.large}
                            mobileSize={cssVariable.iconSizes.medium}
                          />
                        )}
                        <LocalizedString string={item.name} />
                      </ItemLink>
                    </NavItem>
                  );
                })}
              </MainItems>
              <SubItems>
                {getNavItems().sub.map((item, key) => {
                  return (
                    <NavItem
                      key={key}
                      selected={isCurrentPathSelected(item.path)}
                    >
                      <ItemLink
                        to={item.path}
                        onClick={this.closeMenu}
                        aria-describedby={
                          isCurrentPathSelected(item.path)
                            ? 'current-text'
                            : false
                        }
                      >
                        {isCurrentPathSelected(item.path) && (
                          <Icon
                            iconName="arrowRight"
                            color={cssVariable.colorWhite}
                            iconSize={cssVariable.iconSizes.extraSmall}
                            mobileSize={cssVariable.iconSizes.extraSmall}
                          />
                        )}
                        <LocalizedString string={item.name} />
                      </ItemLink>
                    </NavItem>
                  );
                })}
              </SubItems>
            </Nav>

            {/* 
              'current-text' provides a sollution for screenreaders to indicate which page is currently selected. 
              It's value is applied to aria-descibedby in menu-items. This div is hidden for everyone .
            */}
            <div id="current-text" style={{ display: 'none' }}>
              <LocalizedString string="currentPage" />
            </div>

            <LanguageToggle>
              <ToggleLink
                href={routeUtils.langSwitch({ lang: 'nl' })}
                selected={currentLang === 'nl' || currentLang === undefined}
                aria-hidden={currentLang === 'nl'}
              >
                Nederlands
              </ToggleLink>
              {' / '}
              <ToggleLink
                href={routeUtils.langSwitch({ lang: 'en' })}
                selected={currentLang === 'en'}
                aria-hidden={currentLang === 'en'}
              >
                English
              </ToggleLink>
            </LanguageToggle>
            <Logo alt="Gemeente Amsterdam, logo" id="logo-020" />
          </Container>
        </MenuContainer>
      </Wrapper>
    );
  }
}

const Wrapper = responsive.div(
  {
    position: 'fixed',
    top: 0,
    right: 0,
    height: '100vh',
    zIndex: 5,
    width: '100vw',
    backgroundColor: 'transparent',
    transform: 'translateX(100%)',
    transition: `transform 0.3s ${MENU_CONTAINER_ANIM_DURATION}ms ease-out`,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    '&::after': {
      content: `''`,
      display: 'block',
      position: 'absolute',
      zIndex: -1,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: cssVariable.colorWhite,
      opacity: 0,
      transition: 'opacity 0.3s ease-out',
    },
  },
  ({ browser }) =>
    browser.fits.medium && {
      position: 'absolute',
      height: 'auto',
      transition: 'none',
    },
  ({ isMenuOpen }) =>
    isMenuOpen && {
      transform: 'translateX(0%)',
      transition: 'transform 0s 0s ease-out',
      '&::after': {
        opacity: 1,
      },
    },
);

const MenuContainer = responsive.div(
  {
    height: '100%',
    width: '50vw',
    maxWidth: 700,
    overflowX: 'hidden',
    alignSelf: 'flex-start',
    color: cssVariable.colorWhite,
    backgroundColor: cssVariable.colorBlack,
    transition: `all ${MENU_CONTAINER_ANIM_DURATION}ms ease-out`,
    transform: 'translateX(100%)',
    '& #logo-020': {
      opacity: 0,
      transition: 'opacity 0.3s 0.3s ease-out',
    },
  },
  ({ browser }) =>
    browser.fits.medium && {
      width: '100vw',
      maxWidth: 1024,
      height: 'auto',
      opacity: 0,
      transition: 'opacity 0.3s linear',
    },
  ({ isMenuOpen }) =>
    isMenuOpen && {
      transform: 'none',
      '& #logo-020': {
        opacity: 1,
      },
    },
  ({ browser, isMenuOpen }) =>
    browser.fits.medium &&
    isMenuOpen && {
      opacity: 1,
      '& #logo-020': {
        opacity: 1,
        transition: 'none',
      },
    },
);

const ContainerStyling = {
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  minHeight: '100vh',
};

const ControlContainer = responsive.div(
  {
    display: 'flex',
    marginTop: 40,
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      marginTop: 20,
    },
);

const Nav = responsive.div(
  {
    marginTop: 65,
    display: 'block',
  },
  ({ browser }) =>
    browser.fits.medium && {
      marginTop: 20,
    },
);

const MainItems = responsive.ul(
  {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '> li a': {
      ...cssVariable.title,
      color: cssVariable.colorWhite,
      lineHeight: 1.2,
    },
    '> li a svg': {
      marginRight: 12,
    },
    '> li + li': {
      marginTop: 20,
    },
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      '> li a': {
        fontSize: 30,
      },
      '> li a svg': {
        marginRight: 9,
      },
    },
);

const SubItems = responsive.ul(
  {
    listStyle: 'none',
    margin: '50px 0 0 0',
    padding: 0,
    '> li a': {
      fontSize: 18,
      fontWeight: 'bold',
      color: cssVariable.colorWhite,
      lineHeight: 1.4,
    },
    '> li a svg': {
      marginRight: 6,
    },
    '> li + li': {
      marginTop: 10,
    },
  },
  ({ browser }) =>
    browser.fits.extraSmall && {
      marginTop: 30,
    },
);
const NavItem = responsive.li(
  {
    transition: 'transform 0.2s linear',
    transform: 'translateX(0%)',
    ':hover': {
      transform: 'translateX(1.2%)',
    },
  },
  ({ selected }) =>
    selected && {
      transition: 'none',
      transform: 'none',
      ':hover': {
        transform: 'none',
        cursor: 'initial',
      },
    },
);

const ItemLink = responsive(NavLink, {
  rootEl: 'a',
  forwardProps: ['to', 'exact'],
})({
  color: cssVariable.colorWhite,
  display: 'block',
});

const LanguageToggle = responsive.div(
  {
    background: 'none',
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 'auto',
    marginBottom: cssVariable.gridMargins.desktop,
    padding: '60px 0 5px 0',
    display: 'block',
  },
  ({ browser }) =>
    browser.fits.medium && {
      marginBottom: 50,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      marginBottom: 40,
      paddingTop: 50,
    },
);

const ToggleLink = responsive.a(
  {
    color: cssVariable.colorWhite,
    transition: 'transform 0.2s linear',
    transform: 'scale(1)',
    display: 'inline-block',
    '&:hover': {
      transform: 'scale(0.95)',
    },
  },
  ({ selected }) =>
    selected && {
      opacity: 0.34,
      '&:hover': {
        transform: 'scale(1)',
      },
    },
);

const Logo = responsive.div(
  {
    background: `transparent url(${LogoDark}) no-repeat center center`,
    backgroundSize: '100%',
    position: 'fixed',
    left: cssVariable.gridMargins.desktop,
    bottom: 0,
    width: 150,
    height: 75,
    marginBottom: cssVariable.gridMargins.desktop,
  },
  ({ browser }) =>
    browser.fits.medium && {
      backgroundImage: `url(${LogoLight})`,
      position: 'static',
      marginBottom: cssVariable.gridMargins.tablet,
      marginTop: 'auto',
      width: 100,
      height: 80,
    },
  ({ browser }) =>
    browser.fits.extraSmall && {
      marginBottom: cssVariable.gridMargins.mobile,
    },
);
