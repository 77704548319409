const initialState = {
  isOpen: false,
};

export default function menu(state = initialState, action) {
  switch (action.type) {
    case 'MENU_OPEN_STATE':
      return {
        ...state,
        isOpen: action.isOpen,
      };
    default:
      return state;
  }
}
