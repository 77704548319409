import qs from 'querystringify';

import _ from 'lodash';
import * as config from 'config';
import { getJSON } from 'api/_helpers';
import { getCurrentParams } from 'lib/history';

const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;

export async function fetchSearch(
  params,
  offset = 0,
  limit = config.SEARCH_RESULT_SIZE,
) {
  const filteredParams = _.pickBy(params, _.identity);
  filteredParams.offset = offset;
  filteredParams.limit = limit;
  filteredParams.generated_taal = getCurrentParams()['lang'] || 'nl';

  const data = await getJSON(`/search?${qs.stringify(filteredParams)}`);
  const fixedHits = fixHits(data.hits, offset);
  const fixedHistogram = fixHistogram(data.histogram);
  return {
    ...data,
    hits: fixedHits,
    histogram: fixedHistogram,
  };
}

export function generateDownloadExportUrl(params) {
  const filteredParams = _.pickBy(params, _.identity);
  filteredParams.generated_taal = getCurrentParams()['lang'] || 'nl';
  return `${BACKEND_ENDPOINT}/search/export?${qs.stringify(filteredParams)}`;
}

function fixHits(hits, offset) {
  return hits.reduce((acc, hit, index) => {
    acc[index + offset] = hit;
    return acc;
  }, {});
}

function fixHistogram(histogram) {
  if (histogram.length === 0) {
    return histogram;
  }

  const before1350 = histogram.filter(
    period => period.key <= config.YEAR_THRESHOLD,
  );
  const before1350period = {
    key: config.YEAR_THRESHOLD,
    doc_count: before1350.reduce(
      (total, period) => total + period.doc_count,
      0,
    ),
    from: histogram[histogram.length - 1].key,
    to: config.YEAR_THRESHOLD,
  }; // alles < 1350
  const [latest, ...later1350] = histogram
    .filter(period => period.key > config.YEAR_THRESHOLD)
    .map(period => ({
      ...period,
      from: period.key,
      to: period.key + config.PERIOD_TIMESPAN,
    }));

  const periods = [];

  if (latest && latest.doc_count > 0) {
    const isToInTheFuture = latest.to > new Date().getFullYear();
    const withFixedToYear = isToInTheFuture
      ? { ...latest, to: config.NEWEST_OBJECT_YEAR }
      : latest;
    periods.push(withFixedToYear);
  }

  periods.push(...later1350);

  if (before1350period.doc_count > 0) {
    periods.push(before1350period);
  }

  return periods;
}

export function fetchObject(params) {
  const filteredParams = _.pickBy(params, _.identity);
  filteredParams.generated_taal = getCurrentParams()['lang'] || 'nl';
  return getJSON(`/object?${qs.stringify(filteredParams)}`);
}

export function fetchObjects(params) {
  const filteredParams = _.pickBy(params, _.identity);
  filteredParams.generated_taal = getCurrentParams()['lang'] || 'nl';
  return getJSON(`/objects?${qs.stringify(filteredParams)}`);
}
