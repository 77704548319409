import glamorous from 'glamorous';

import * as cssVariable from 'cssVariables';

export const Label = glamorous.span(
  {
    fontSize: 11,
    fontWeight: cssVariable.fontWeights.light,
    display: 'block',
    fontFamily: cssVariable.fontFamilies.maisonMono,
    textTransform: 'uppercase',
  },
  ({ marginBottom }) =>
    marginBottom && {
      marginBottom,
    },
);
